import React, { useState, useEffect } from 'react';
import { CSVLink } from 'react-csv';
import { useSelector, useDispatch } from 'react-redux';
import { subDays } from 'date-fns';
import { getNotificationCenterAnalytics } from '../../redux/actions/analytics';
import CustomButton from '../CustomButton';
import { formatDate } from './helper';
import DateSelector from '../DateSelector';
import Spinner from '../Spinner';
import { getAllData } from '../../redux/actions/api';

const NotificationCenterAnalytics = () => {
  const dispatch = useDispatch();

  const notificationCenterLoading = useSelector(
    (state) => state.analytics.notification_center_analytics_loading
  );
  const notificationCenter = useSelector((state) => state.analytics.notification_center_analytics);
  const [startDate, setStartDate] = useState(subDays(new Date(), 30));
  const [endDate, setEndDate] = useState(new Date());

  useEffect(() => {
    dispatch(getAllData());
  }, [dispatch]);

  const search = () => {
    dispatch(getNotificationCenterAnalytics(formatDate(startDate), formatDate(endDate)));
  };

  const headers = [
    { label: 'Company', key: 'companyname' },
    { label: 'Product', key: 'product' },
    { label: 'Notification', key: 'title' },
    { label: 'URL', key: 'url' },
    { label: 'Views', key: 'appeared' },
    { label: 'Link clicks', key: 'pressed' },
    { label: 'CTR', key: 'ctr' },
    { label: 'Unique', key: 'unique' },
  ];

  return (
    <div className="w-full flex flex-col items-center">
      <DateSelector
        startDate={startDate}
        endDate={endDate}
        startDateChanged={(date) => setStartDate(date)}
        endDateChanged={(date) => setEndDate(date)}
      />

      <div>
        {notificationCenterLoading && <Spinner />}
        {!notificationCenterLoading && (
          <div>
            <div className="mb-8 flex items-center justify-center w-full">
              <CustomButton type="button" className="text-white" onClick={search}>
                Search
              </CustomButton>

              {notificationCenter.length !== 0 ? (
                <CSVLink
                  className="bg-orange text-white p-2 rounded"
                  filename={`NotificationCenter-${formatDate(startDate)}--${formatDate(
                    endDate
                  )}.csv`}
                  data={notificationCenter.notifications}
                  headers={headers}
                >
                  Download this table
                </CSVLink>
              ) : null}
            </div>

            {notificationCenter.length !== 0 && (
              <table>
                <thead>
                  <tr>
                    <th>Company</th>
                    <th>Product</th>
                    <th>Notification</th>
                    <th>URL</th>
                    <th>Views</th>
                    <th>Link clicks</th>
                    <th>CTR</th>
                    <th>Unique</th>
                  </tr>
                </thead>
                <tbody>
                  {notificationCenter.notifications.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td>{item.companyname.join(', ')}</td>
                        <td>{item.product.join(', ')}</td>
                        <td>{item.title}</td>
                        <td
                          style={{
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis',
                            overflow: 'hidden',
                          }}
                        >
                          {item.url}
                        </td>
                        <td>{item.appeared}</td>
                        <td>{item.pressed}</td>
                        <td>{item.ctr}%</td>
                        <td>{item.unique}</td>
                      </tr>
                    );
                  })}
                  <tr className="font-bold">
                    <td>Total for Notification Center</td>
                    <td />
                    <td />
                    <td />
                    <td>{notificationCenter.totalAppeared}</td>
                    <td>{notificationCenter.total}</td>
                    <td />
                    <td>{notificationCenter.uniq}</td>
                  </tr>
                </tbody>
              </table>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default NotificationCenterAnalytics;
