import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { logout } from '../../../redux/actions/state';
import { Link, useNavigate } from 'react-router-dom';
import { Squeeze as Hamburger } from 'hamburger-react';

import './style.css';
import { UpdateAppData } from '../SidebarMenu';

const MobileNav = ({ active }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [openMenu, setOpenMenu] = useState(false);

  const logoutNow = () => {
    dispatch(logout());
    navigate('/login');
  };

  return (
    <div className="mb-12">
      <div className="fixed z-50 w-full h-12 top-0 left-0 right-0 bg-orange">
        <div className="flex flex-row justify-between items-center mx-12">
          <p className="flex items-center gap-2">
            {process.env.REACT_APP_LANGUAGE === 'de' ? (
              <span className={'text-5xl'}>🇩🇪</span>
            ) : null}
            Menu
          </p>
          <Hamburger toggled={openMenu} toggle={() => setOpenMenu(!openMenu)} />
        </div>
      </div>
      {openMenu && (
        <div className="bg-orange fixed top-0 left-0 right-0 mt-12 z-50 p-4">
          <MenuItem
            title="Content"
            active={
              active === 'home' ||
              active === 'deals' ||
              active === 'tools' ||
              active === 'todo' ||
              active === 'knowledge' ||
              active === 'notification' ||
              active === 'notification-center' ||
              active === 'popup' ||
              active === 'settings'
            }
            heading
          />
          <div className="ml-8 my-1px">
            <MenuItem to="/home" title="Home" active={active === 'home'} />
            <MenuItem to="/deals" title="Deals" active={active === 'deals'} />
            <MenuItem to="/tools" title="Tools" active={active === 'tools'} />
            <MenuItem to="/todo" title="Todo" active={active === 'todo'} />
            <MenuItem to="/knowledge" title="Knowledge" active={active === 'knowledge'} />
            <MenuItem
              to="/notification"
              title="Push notifications"
              active={active === 'notification'}
            />
            <MenuItem
              to="/notification-center"
              title="Notification center"
              active={active === 'notification-center'}
            />
            <MenuItem to="/popup" title="Popup" active={active === 'popup'} />
            <MenuItem to="/settings" title="Settings" active={active === 'settings'} />
          </div>
          <MenuItem
            title="Analytics"
            active={
              active === 'useranalytics' ||
              active === 'contentanalytics' ||
              active === 'companyanalytics'
            }
            heading
          />
          <div className="ml-8 my-1px">
            <MenuItem to="/useranalytics" title="Users" active={active === 'useranalytics'} />
            <MenuItem
              to="/contentanalytics"
              title="App content"
              active={active === 'contentanalytics'}
            />
            <MenuItem
              to="/companyanalytics"
              title="Company"
              active={active === 'companyanalytics'}
            />
          </div>
          <MenuItem to="/forms" title="Forms" active={active === 'forms'} />
          <MenuItem to="/help" title="Help" active={active === 'help'} />
          <MenuItem to="/appversion" title="AppVersion" active={active === 'appversion'} />
          <MenuItem to="/userdelete" title="Delete user" active={active === 'userdelete'} />
          <MenuItem title="Logout" active={active === 'b'} onClick={() => logoutNow()} logout />

          <UpdateAppData />
        </div>
      )}
    </div>
  );
};

const MenuItem = ({ to, title, active, onClick, heading, logout }) => {
  return (
    <li
      className={`relative z-10 flex flex-row h-7 mx-2 my-1px rounded ${
        active && 'border-2 border-white'
      }`}
    >
      {heading && <div className="text-base text-white ml-2 my-auto ">{title}</div>}
      {to && (
        <Link to={to} title={title} className="text-base text-white ml-2 my-auto">
          {title}
        </Link>
      )}
      {logout && (
        <div
          type="button"
          title={title}
          className="text-base text-white ml-2 my-auto focus:underline cursor:pointer"
          onClick={() => onClick()}
        >
          {title}
        </div>
      )}
    </li>
  );
};

export default MobileNav;
