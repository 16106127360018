import React, { Component } from 'react';
import Loader from 'react-loader-spinner';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';

export default class Spinner extends Component {
  render() {
    return (
      <div className="my-8">
        <Loader type="Oval" color="#e88d1e" height={50} width={50} />
      </div>
    );
  }
}
