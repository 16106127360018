import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import TextField from '../../components/TextField';
import { getSettings, updateSettings } from '../../redux/actions/settings';
import Layout from '../../components/layout/Layout';
import CustomButton from '../../components/CustomButton';
import HTMLEditor from '../../components/HTMLEditor';
import MarkdownEditor from '../../components/MarkdownEditor';
import Checkbox from '../../components/Checkbox';

const Settings = () => {
  const dispatch = useDispatch();
  const settings = useSelector((state) => state.settings.settings);
  const [aboutHtmlContent, setAboutHtmlContent] = useState('');
  const [aboutMarkdownContent, setAboutMarkdownContent] = useState('');
  const [privacyTextHtmlContent, setPrivacyTextHtmlContent] = useState('');
  const [privacyTextMarkdownContent, setPrivacyTextMarkdownContent] = useState('');
  const [dataprivacyHtmlContent, setDataprivacyHtmlContent] = useState('');
  const [dataprivacyMarkdownContent, setDataprivacyMarkdownContent] = useState('');
  const [newPregnancyHtmlContent, setNewPregnancyHtmlContent] = useState('');
  const [supportGroupsHtmlContent, setSupportGroupsHtmlContent] = useState('');
  const [facebookAppEvents, setFacebookAppEvents] = useState('');
  const [fetusHtmlContent, setFetusHtmlContent] = useState('');
  const [fetusMarkdownContent, setFetusMarkdownContent] = useState('');
  const [pregnancyHtmlContent, setPregnancyHtmlContent] = useState('');
  const [pregnancyMarkdownContent, setPregnancyMarkdownContent] = useState('');
  const [sourcesHtmlContent, setSourcesHtmlContent] = useState('');
  const [sourcesMarkdownContent, setSourcesMarkdownContent] = useState('');
  const [trimesterHtmlContent, setTrimesterHtmlContent] = useState('');
  const [trimesterMarkdownContent, setTrimesterMarkdownContent] = useState('');

  useEffect(() => {
    dispatch(getSettings());
  }, [dispatch]);

  useEffect(() => {
    if (settings) {
      setAboutHtmlContent(settings.aboutTextHtml);
      setPrivacyTextHtmlContent(settings.privacyTextHtml);
      setDataprivacyHtmlContent(settings.dataprivacyHtml);
      setNewPregnancyHtmlContent(settings.newPregnancyHtml);
      setSupportGroupsHtmlContent(settings.supportGroupsHtml);
      setAboutMarkdownContent(settings.aboutText);
      setPrivacyTextMarkdownContent(settings.privacyText);
      setDataprivacyMarkdownContent(settings.dataprivacy);
      setFacebookAppEvents(settings.integrations?.facebookAppEvents);
      setFetusHtmlContent(settings.support?.fetusHtml);
      setFetusMarkdownContent(settings.support?.fetus);
      setPregnancyHtmlContent(settings.support?.pregnancyHtml);
      setPregnancyMarkdownContent(settings.support?.pregnancy);
      setSourcesHtmlContent(settings.support?.sourcesHtml);
      setSourcesMarkdownContent(settings.support?.sources);
      setTrimesterHtmlContent(settings.support?.trimesterHtml);
      setTrimesterMarkdownContent(settings.support?.trimester);
    }
  }, [settings]);

  const save = () => {
    dispatch(
      updateSettings({
        aboutText: aboutMarkdownContent,
        aboutTextHtml: aboutHtmlContent,
        privacyText: privacyTextMarkdownContent,
        privacyTextHtml: privacyTextHtmlContent,
        dataprivacy: dataprivacyMarkdownContent,
        dataprivacyHtml: dataprivacyHtmlContent,
        newPregnancyHtml: newPregnancyHtmlContent,
        supportGroupsHtml: supportGroupsHtmlContent,
        fetus: fetusMarkdownContent,
        fetusHtml: fetusHtmlContent,
        pregnancy: pregnancyMarkdownContent,
        pregnancyHtml: pregnancyHtmlContent,
        sources: sourcesMarkdownContent,
        sourcesHtml: sourcesHtmlContent,
        trimester: trimesterMarkdownContent,
        trimesterHtml: trimesterHtmlContent,
        facebookAppEvents,
      })
    );
  };

  return (
    <Layout active="settings">
      <div className="mx-24">
        <div className="font-semibold text-lg text-center mt-10">About</div>
        <HTMLEditor
          title="Html"
          dontshowImage
          defaultValue={aboutHtmlContent}
          setContents={aboutHtmlContent}
          onChange={(content) => {
            setAboutHtmlContent(content);
          }}
        />
        <MarkdownEditor
          title="Markdown"
          value={aboutMarkdownContent}
          onChange={({ text }) => {
            setAboutMarkdownContent(text);
          }}
        />

        <div className="font-semibold text-lg text-center mt-10">Terms and condition</div>
        <HTMLEditor
          title="Html"
          dontshowImage
          defaultValue={privacyTextHtmlContent}
          setContents={privacyTextHtmlContent}
          onChange={(content) => {
            setPrivacyTextHtmlContent(content);
          }}
        />
        <MarkdownEditor
          title="Markdown"
          value={privacyTextMarkdownContent}
          onChange={({ text }) => {
            setPrivacyTextMarkdownContent(text);
          }}
        />

        <div className="font-semibold text-lg text-center mt-10">Data Privacy Policy</div>
        <HTMLEditor
          title="Html"
          dontshowImage
          defaultValue={dataprivacyHtmlContent}
          setContents={dataprivacyHtmlContent}
          onChange={(content) => {
            setDataprivacyHtmlContent(content);
          }}
        />
        <MarkdownEditor
          title="Markdown"
          value={dataprivacyMarkdownContent}
          onChange={({ text }) => {
            setDataprivacyMarkdownContent(text);
          }}
        />

        <div className="font-semibold text-lg text-center mt-10">New Pregnancy</div>
        <HTMLEditor
          title="Html"
          dontshowImage
          defaultValue={newPregnancyHtmlContent}
          setContents={newPregnancyHtmlContent}
          onChange={(content) => {
            setNewPregnancyHtmlContent(content);
          }}
        />

        <div className="font-semibold text-lg text-center mt-10">Support Groups</div>
        <TextField
          type="text"
          label="Deeplink"
          name="deeplinkSupportGroups"
          value={'preghello://home/support/supportGroups'}
          disabled
        />
        <HTMLEditor
          title="Html"
          dontshowImage
          defaultValue={supportGroupsHtmlContent}
          setContents={supportGroupsHtmlContent}
          onChange={(content) => {
            setSupportGroupsHtmlContent(content);
          }}
        />

        <div className="font-semibold text-lg text-center mt-10">Fetus</div>
        <TextField
          type="text"
          label="Deeplink"
          name="deeplinkFetus"
          value={'preghello://home/support/fetus'}
          disabled
        />
        <HTMLEditor
          title="Html"
          dontshowImage
          defaultValue={fetusHtmlContent}
          setContents={fetusHtmlContent}
          onChange={(content) => {
            setFetusHtmlContent(content);
          }}
        />
        <MarkdownEditor
          title="Markdown"
          value={fetusMarkdownContent}
          onChange={({ text }) => {
            setFetusMarkdownContent(text);
          }}
        />

        <div className="font-semibold text-lg text-center mt-10">Pregnancy</div>
        <TextField
          type="text"
          label="Deeplink"
          name="deeplinkPregnancy"
          value={'preghello://home/support/pregnancy'}
          disabled
        />
        <HTMLEditor
          title="Html"
          dontshowImage
          defaultValue={pregnancyHtmlContent}
          setContents={pregnancyHtmlContent}
          onChange={(content) => {
            setPregnancyHtmlContent(content);
          }}
        />
        <MarkdownEditor
          title="Markdown"
          value={pregnancyMarkdownContent}
          onChange={({ text }) => {
            setPregnancyMarkdownContent(text);
          }}
        />

        <div className="font-semibold text-lg text-center mt-10">Sources</div>
        <TextField
          type="text"
          label="Deeplink"
          name="deeplinkSources"
          value={'preghello://home/support/sources'}
          disabled
        />
        <HTMLEditor
          title="Html"
          dontshowImage
          defaultValue={sourcesHtmlContent}
          setContents={sourcesHtmlContent}
          onChange={(content) => {
            setSourcesHtmlContent(content);
          }}
        />
        <MarkdownEditor
          title="Markdown"
          value={sourcesMarkdownContent}
          onChange={({ text }) => {
            setSourcesMarkdownContent(text);
          }}
        />

        <div className="font-semibold text-lg text-center mt-10">Trimester</div>
        <TextField
          type="text"
          label="Deeplink"
          name="deeplinkTrimester"
          value={'preghello://home/support/trimester'}
          disabled
        />
        <HTMLEditor
          title="Html"
          dontshowImage
          defaultValue={trimesterHtmlContent}
          setContents={trimesterHtmlContent}
          onChange={(content) => {
            setTrimesterHtmlContent(content);
          }}
        />
        <MarkdownEditor
          title="Markdown"
          value={trimesterMarkdownContent}
          onChange={({ text }) => {
            setTrimesterMarkdownContent(text);
          }}
        />
        <Checkbox
          bool
          name="FacebookAppEvents"
          selected={facebookAppEvents}
          getValue={() => {
            setFacebookAppEvents(!facebookAppEvents);
          }}
        />
        <div className="flex justify-center my-5">
          <CustomButton onClick={save}>Save</CustomButton>
        </div>
      </div>
    </Layout>
  );
};

export default Settings;
