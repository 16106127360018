import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Checkbox from '../../components/Checkbox';
import Dropdown from '../../components/Dropdown';
import Layout from '../../components/layout/Layout';
import NewButton from '../../components/NewButton';
import MenuButton from '../../components/MenuButton';
import TextField from '../../components/TextField';
import ImageUp from '../../components/ImageUp';
import CustomButton from '../../components/CustomButton';
import {
  listInAppNotifications,
  create,
  update,
  remove,
} from '../../redux/actions/notificationCenter';
import { sortByVisibilityAndOrder } from '../../utils/sort';
import { isBetweenPublishDateRange, validateStartEndDates } from '../../utils/date';

const defaultInAppNotification = {
  _id: '',
  userRegisteredAfterDate: '',
  userRegisteredBeforeDate: '',
  headline: '',
  text: '',
  image: '',
  popupHeadline: '',
  popupText: '',
  ctaText: '',
  link: '',
  impressionUrl: '',
  companyname: '',
  product: '',
  order: '',
  visible: true,
  start_date: '',
  end_date: '',
};

const NotificationCenter = () => {
  const dispatch = useDispatch();

  const notifications = useSelector((state) => state.notificationCenter.notifications);
  const activeNotifications = notifications
    .filter((notification) => notification.visible && isBetweenPublishDateRange(notification))
    .sort(sortByVisibilityAndOrder());
  const inactiveNotifications = notifications
    .filter((notification) => !notification.visible || !isBetweenPublishDateRange(notification))
    .sort(sortByVisibilityAndOrder());

  const [addNew, setAddNew] = useState(true);
  const [activeNotification, setActiveNotification] = useState({ ...defaultInAppNotification });
  const companyList = useSelector((state) => state.analytics.companylist);

  useEffect(() => {
    dispatch(listInAppNotifications());
  }, [dispatch]);

  const handleNotificationSelect = (notification) => {
    setAddNew(false);
    setActiveNotification({ ...defaultInAppNotification, ...notification });
  };

  const handleFieldUpdate = (field, value) => {
    activeNotification[field] = value;
    setActiveNotification({ ...activeNotification });
  };

  const handleCreateButtonClick = () => {
    setAddNew(true);
    setActiveNotification({ ...defaultInAppNotification });
  };

  const handleSubmit = async () => {
    const dateValidationResult = validateStartEndDates(activeNotification);

    if (!dateValidationResult.valid) return alert(dateValidationResult.error);

    if (!addNew) {
      await dispatch(update(activeNotification));
      dispatch(listInAppNotifications());

      return;
    }

    await dispatch(create(activeNotification));
    dispatch(listInAppNotifications());

    setAddNew(false);
    setActiveNotification({});
  };

  const handleDelete = async () => {
    await dispatch(remove(activeNotification._id));
    dispatch(listInAppNotifications());

    setActiveNotification({});
  };

  return (
    <Layout active="notification-center">
      <div className="w-full">
        <div className="mx-12 flex flex-wrap pt-12">
          <NewButton onClick={handleCreateButtonClick} title="Add new" />
          {activeNotifications.length > 0 &&
            activeNotifications.map((notification) => (
              <MenuButton
                key={notification._id}
                onClick={() => handleNotificationSelect(notification)}
                active={activeNotification._id === notification._id}
                visible={true}
                title={`${notification.headline} - ${notification.order}`}
              />
            ))}
        </div>

        {inactiveNotifications.length > 0 && (
          <div className="mx-12 flex flex-wrap">
            <details>
              <summary className={'cursor-pointer'}>Inactive notifications</summary>

              {inactiveNotifications.map((notification) => {
                return (
                  <MenuButton
                    key={notification._id}
                    onClick={() => handleNotificationSelect(notification)}
                    active={activeNotification._id === notification._id}
                    visible={false}
                    title={`${notification.headline} - ${notification.order}`}
                  />
                );
              })}
            </details>
          </div>
        )}

        {!!Object.keys(activeNotification).length && (
          <div className="mx-12 mt-12">
            <TextField type="text" label="Id" name="id" value={activeNotification._id} disabled />
            <TextField label="Company name">
              <Dropdown
                value={activeNotification.companyname}
                options={[{ value: '', label: 'None' }, ...companyList]}
                onChange={(value) => {
                  handleFieldUpdate('companyname', value.value);
                }}
                className={'w-2/3'}
              />
            </TextField>
            <TextField
              type="text"
              label="Product"
              name="product"
              value={activeNotification.product}
              onChange={(event) => {
                handleFieldUpdate('product', event.target.value);
              }}
            />
            <TextField
              type="text"
              label="Start Date*"
              name="start_date"
              placeholder="2023-01-01"
              required
              value={activeNotification.start_date}
              onChange={(event) => handleFieldUpdate('start_date', event.target.value)}
            />
            <TextField
              type="text"
              label="End Date*"
              name="end_date"
              placeholder="2023-01-02"
              required
              value={activeNotification.end_date}
              onChange={(event) => handleFieldUpdate('end_date', event.target.value)}
            />
            <TextField
              type="text"
              label="Impression URL (should start with 'https://')"
              name="impressionUrl"
              placeholder=""
              value={activeNotification.impressionUrl}
              onChange={(event) => handleFieldUpdate('impressionUrl', event.target.value)}
            />
            <TextField
              type="text"
              label="User Registered After Date (Inclusive)"
              name="text"
              value={activeNotification.userRegisteredAfterDate}
              onChange={(event) => handleFieldUpdate('userRegisteredAfterDate', event.target.value)}
              placeholder={'Date - 2023-01-01'}
            />
            <TextField
              type="text"
              label="User Registered Before Date (Inclusive)"
              name="text"
              value={activeNotification.userRegisteredBeforeDate}
              onChange={(event) =>
                handleFieldUpdate('userRegisteredBeforeDate', event.target.value)
              }
              placeholder={'Date - 2023-01-10'}
            />
            <TextField
              type="text"
              label="List Item Headline (max 25 characters)"
              name="headline"
              value={activeNotification.headline}
              onChange={(event) => handleFieldUpdate('headline', event.target.value)}
            />
            <TextField
              type="text"
              label="List Item Text (max 50 characters)"
              name="text"
              value={activeNotification.text}
              onChange={(event) => handleFieldUpdate('text', event.target.value)}
            />
            <ImageUp
              title="Image (1000 x 500)"
              value={activeNotification.image || ''}
              parentCallback={() => handleFieldUpdate('image', '')}
              getImg={(value) => handleFieldUpdate('image', value)}
            />
            <TextField
              type="text"
              label="Popup Headline"
              name="popupHeadline"
              value={activeNotification.popupHeadline}
              onChange={(event) => handleFieldUpdate('popupHeadline', event.target.value)}
            />
            <TextField
              type="text"
              label="Popup Text"
              name="popupText"
              value={activeNotification.popupText}
              onChange={(event) => handleFieldUpdate('popupText', event.target.value)}
            />
            <TextField
              type="text"
              label="Popup CTA Text"
              name="ctaText"
              value={activeNotification.ctaText}
              onChange={(event) => handleFieldUpdate('ctaText', event.target.value)}
            />
            <TextField
              type="text"
              label="Popup Link"
              name="link"
              value={activeNotification.link}
              onChange={(event) => handleFieldUpdate('link', event.target.value)}
            />
            <TextField
              type="text"
              label="Order"
              name="order"
              value={activeNotification.order}
              onChange={(event) => handleFieldUpdate('order', event.target.value)}
            />
            <Checkbox
              name="Visibility"
              selected={activeNotification.visible}
              getValue={(value) => handleFieldUpdate('visible', value)}
              bool
            />
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              {!addNew && (
                <CustomButton red type="submit" className="w-1/4" onClick={handleDelete}>
                  Delete
                </CustomButton>
              )}
              <div style={{ width: 20, height: 1 }} />
              <CustomButton type="submit" className="w-1/4" onClick={handleSubmit}>
                {addNew ? 'Create' : 'Save'}
              </CustomButton>
            </div>
          </div>
        )}
      </div>
    </Layout>
  );
};

export default NotificationCenter;
