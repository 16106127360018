import React from 'react';
import { CSVLink } from 'react-csv';
import { useSelector, useDispatch } from 'react-redux';
import { getClickRecipe } from '../../../redux/actions/analytics';
import CustomButton from '../../CustomButton';
import LinkButton from '../../LinkButton';
import { formatDate } from '../helper';
import Spinner from '../../Spinner';

const RecipeClick = ({ startDate, endDate }) => {
  const dispatch = useDispatch();
  const recipeLoading = useSelector((state) => state.analytics.clickrecipe_loading);
  const recipe = useSelector((state) => state.analytics.clickrecipe);

  const search = async () => {
    dispatch(getClickRecipe(formatDate(startDate), formatDate(endDate)));
  };

  const headers = [
    { label: 'Company', key: 'companyname' },
    { label: 'Product', key: 'product' },
    { label: 'Screen', key: 'screen' },
    { label: 'URL', key: 'url' },
    { label: 'Clicks', key: 'pressed' },
    { label: 'Percentage (views / all views)', key: 'percentage' },
    { label: 'Unique', key: 'unique' },
  ];

  return (
    <div className="w-full flex flex-col items-center">
      <div>
        {recipeLoading && <Spinner />}
        {!recipeLoading && (
          <div>
            <div className="my-8 flex items-center justify-center w-full">
              <CustomButton type="button" className="text-white" onClick={search}>
                Search
              </CustomButton>

              {recipe.length !== 0 ? (
                <CSVLink
                  className="bg-orange text-white p-2 rounded"
                  filename={`RecipeClick-${formatDate(startDate)}-${formatDate(endDate)}.csv`}
                  data={recipe.screens}
                  headers={headers}
                >
                  Download this table
                </CSVLink>
              ) : null}
            </div>

            {recipe.length !== 0 && (
              <table>
                <thead>
                  <tr>
                    <th>Company</th>
                    <th>Product</th>
                    <th>Screen</th>
                    <th>URL</th>
                    <th>Clicks</th>
                    <th>Percentage (clicks / all clicks)</th>
                    <th>Unique</th>
                  </tr>
                </thead>
                <tbody>
                  {recipe.screens.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td>{item.companyname}</td>
                        <td>{item.product}</td>
                        <td>{item.screen}</td>
                        <td
                          style={{
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis',
                            overflow: 'hidden',
                          }}
                        >
                          <LinkButton url={item.url} />
                          {item.url}
                        </td>
                        <td>{item.pressed}</td>
                        <td>{item.percentage} %</td>
                        <td>{item.unique}</td>
                      </tr>
                    );
                  })}
                  <tr className="font-bold">
                    <td>SUM</td>
                    <td />
                    <td />
                    <td />
                    <td>{recipe.total}</td>
                    <td />
                    <td>{recipe.uniq}</td>
                  </tr>
                </tbody>
              </table>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default RecipeClick;
