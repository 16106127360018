/* eslint react/forbid-prop-types: 0 */
import React from 'react';

const TextArea = React.forwardRef(
  (
    { id, name, label, type, errors, groupClassName, fieldClassName, value, onChange, disabled },
    ref
  ) => {
    const hasError = !!errors && !!errors[name];

    return (
      <div className="flex flex-col w-full my-1">
        <div className={`relative flex flex-row w-full items-center ${groupClassName || ''}`}>
          <label htmlFor={id || name} className={`w-1/3`}>
            {label}
          </label>
          <textarea
            name={name}
            rows="4"
            id={id || name}
            type={type}
            ref={ref}
            disabled={disabled}
            onChange={onChange}
            value={value}
            className={`w-2/3 border-2 border-extralightorange rounded p-2 focus:border-orange focus:bg-white focus:outline-none ${
              fieldClassName || ''
            }`}
          />
        </div>
        {hasError ? <div className="text-red">{errors[name].message}</div> : null}
      </div>
    );
  }
);

export default TextArea;
