import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { update } from '../../redux/actions/api';
import { getForms, sendForm } from '../../redux/actions/forms';
import TextField from '../TextField';
import TextArea from '../TextArea';
import Checkbox from '../Checkbox';
import CustomButton from '../CustomButton';
import HTMLEditor from '../HTMLEditor';

const FormForm = ({ sourceIdnow, categoryIdnow, formtype, parentCallback, selectedItem }) => {
  const dispatch = useDispatch();
  const forms = useSelector((state) => state.forms.list);
  const [loading, setLoading] = useState(true);
  const [id, setId] = useState(null);
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [buttontext, setButtonText] = useState('');
  const [othertitle, setOtherTitle] = useState('');
  const [name, setName] = useState(false);
  const [email, setEmail] = useState(false);
  const [phone, setPhone] = useState(false);
  const [other, setOther] = useState(false);
  const [expectedDate, setExpectedDate] = useState(false);
  const [address, setAddress] = useState(false);
  const [company, setCompany] = useState('');
  const [sendbuttontext, setSendbuttontext] = useState('');
  const [privacypolicytext, setPrivacypolicytext] = useState('');
  const [data, setData] = useState([]);

  const saveAllData = () => {
    console.log(formtype);
    if (formtype === 'deals') {
      saveDeal();
    }
    if (formtype === 'home') {
      saveHome();
    }
    if (formtype === 'article') {
      saveArticle();
    }
  };

  const onSubmit = () => {
    const form = {
      id,
      categoryId: categoryIdnow,
      sourceId: sourceIdnow,
      title,
      description,
      type: formtype,
      buttontext,
      othertitle,
      name,
      email,
      phone,
      other,
      expectedDate,
      address,
      company,
      sendbuttontext,
      privacypolicytext,
      data,
    };
    dispatch(sendForm(form));
    parentCallback();
  };

  const saveDeal = () => {
    const selected = selectedItem;
    const url = '/deals/' + selectedItem.id;
    dispatch(update(selected, url));
    onSubmit();
  };

  const saveHome = () => {
    const selected = selectedItem;
    const url = '/homescreen/updateweek';
    dispatch(update(selected, url));
    onSubmit();
  };

  const saveArticle = () => {
    const selected = selectedItem;
    const url = '/knowledge/updatedata/' + categoryIdnow;
    dispatch(update(selected, url));
    onSubmit();
  };

  const deleteFormFromItem = () => {
    if (formtype === 'deals') {
      const selected = selectedItem;
      selected.formId = '';
      selected.form = 'false';
      const url = '/deals/' + selectedItem.id;
      dispatch(update(selected, url));
      parentCallback();
    }
    if (formtype === 'home') {
      const selected = selectedItem;
      selected.formId = '';
      selected.form = 'false';
      const url = '/homescreen/updateweek';
      dispatch(update(selected, url));
      parentCallback();
    }
    if (formtype === 'article') {
      const selected = selectedItem;
      selected.formId = '';
      selected.form = 'false';
      const url = '/knowledge/updatedata/' + categoryIdnow;
      dispatch(update(selected, url));
      parentCallback();
    }
  };

  useEffect(() => {
    dispatch(getForms());
  }, [dispatch]);

  useEffect(() => {
    if (forms.length !== 0) {
      const form = forms.filter((item) => item.id.toString() === selectedItem.formId.toString())[0];
      if (form) {
        setId(form.id);
        setTitle(form.title);
        setDescription(form.description);
        setButtonText(form.buttontext);
        setOtherTitle(form.othertitle);
        setName(form.name);
        setEmail(form.email);
        setPhone(form.phone);
        setOther(form.other);
        setExpectedDate(form.expectedDate);
        setAddress(form.address);
        setCompany(form.company);
        setSendbuttontext(form.sendbuttontext);
        setPrivacypolicytext(form.privacypolicytext);
        setData(form.data);
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      } else {
        setLoading(false);
      }
    }
  }, [forms, selectedItem.formId]);

  return (
    <div className="border-t border-b border-orange py-2">
      <TextField
        type="text"
        label="Title"
        name="title"
        value={title}
        onChange={(e) => setTitle(e.target.value)}
      />
      <TextArea
        type="text"
        label="Description"
        name="description"
        value={description}
        onChange={(e) => setDescription(e.target.value)}
      />
      <div className="w-full flex flex-row">
        <div className="w-1/4">Form fields</div>
        <div className="w-3/4 flex flex-col items-start justify-start ml-4 mb-4">
          <Checkbox
            bool
            name="Name"
            selected={name}
            getValue={(data) => {
              setName(data);
            }}
          />
          <Checkbox bool name="Email" selected={email} getValue={(data) => setEmail(data)} />
          <Checkbox bool name="Phone" selected={phone} getValue={(data) => setPhone(data)} />
          <Checkbox
            bool
            name="Expected Date"
            selected={expectedDate}
            getValue={(data) => setExpectedDate(data)}
          />
          <Checkbox bool name="Address" selected={address} getValue={(data) => setAddress(data)} />
          <Checkbox bool name="Other" selected={other} getValue={(data) => setOther(data)} />
        </div>
      </div>
      <TextField
        type="text"
        label="Other title"
        name="othertitle"
        value={othertitle}
        onChange={(e) => setOtherTitle(e.target.value)}
      />
      <TextField
        type="text"
        label="Action Button text (article)"
        name="buttontext"
        disabled={formtype === 'deals'}
        value={buttontext}
        onChange={(e) => setButtonText(e.target.value)}
      />
      <TextField
        type="text"
        label="Send Button text (form)"
        name="sendbuttontext"
        value={sendbuttontext}
        onChange={(e) => setSendbuttontext(e.target.value)}
      />
      <TextField
        type="text"
        label="Company"
        name="company"
        value={company}
        onChange={(e) => setCompany(e.target.value)}
      />
      {!loading && (
        <HTMLEditor
          defaultValue={privacypolicytext}
          title="Privacy policy text"
          dontshowImage
          onChange={(content) => {
            setPrivacypolicytext(content);
          }}
        />
      )}
      <CustomButton type="submit" className="w-1/4" onClick={() => saveAllData()}>
        Save FORM data
      </CustomButton>
      <CustomButton red type="submit" className="w-1/4" onClick={() => deleteFormFromItem()}>
        Delete FORM from this item (keep the data)
      </CustomButton>
    </div>
  );
};

export default FormForm;
