import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import LinkButton from '../LinkButton';
import { formatDate } from './helper';
import MenuButton from '../MenuButton';
import { CSVLink } from 'react-csv';

const CompanyAnalyticsTables = ({ startDate, endDate, companyName }) => {
  const data = useSelector((state) => state.analytics.company);
  const [showTab, setShowTab] = useState('');
  const [all, setAll] = useState('');

  const headers = [
    { label: 'Type', key: 'analyticstype' },
    { label: 'Start date', key: 'start_date' },
    { label: 'End date', key: 'end_date' },
    { label: 'Product', key: 'product' },
    { label: 'Category', key: 'category' },
    { label: 'SubCategory', key: 'subcategory' },
    { label: 'Item / Article', key: 'title' },
    { label: 'URL', key: 'url' },
    { label: 'Views', key: 'appeared' },
    { label: 'Clicks', key: 'pressed' },
    { label: 'CTR', key: 'ctr' },
    { label: 'Unique', key: 'unique' },
  ];

  useEffect(() => {
    if (data) {
      const all = [];

      const coupons = data.coupons.map((item) => item);
      coupons.forEach((item) => {
        item.category = item.screen;
        item.analyticstype = 'Coupon';
        item.appeared = data.couponViews;
        item.url = item.deal;
        item.ctr = ((Number(item.pressed) / Number(item.appeared)) * 100).toFixed(2) + '%';
        item.screen = '';
      });

      const todos = data.todo.map((item) => item);
      todos.forEach((item) => {
        item.analyticstype = 'Todo item';
        if (!item.subcategory) {
          // Old data where subcategory was tracked as `category` and we have no info on parent category
          item.subcategory = item.category;
          item.category = '';
        }
        item.url = item.deal;
        item.appeared =
          data.todoViews.find((cat) => cat.screen.endsWith(item.subcategory))?.appeared ?? 0;
        item.ctr = ((Number(item.pressed) / Number(item.appeared)) * 100).toFixed(2) + ' %';
      });

      const popups = data.popups.map((item) => item);
      popups.forEach((item) => {
        item.analyticstype = 'Popup';
      });

      const notifications = data.notifications.map((item) => item);
      notifications.forEach((item) => {
        item.analyticstype = 'Notification';
      });

      const home = data.home.map((item) => item);
      home.forEach((item) => {
        item.category = item.week + '. week';
        item.subcategory = item.type;
        item.analyticstype = 'Home article';
        item.appeared = data.homeViews.find(
          (screen) => screen.screen === item.screenId && screen.week === Number(item.week)
        )?.pressed;
        item.ctr = !item.appeared
          ? 0
          : ((Number(item.pressed) / Number(item.appeared)) * 100).toFixed(2) + ' %';
      });

      const knowledge = data.knowledge.map((item) => item);
      knowledge.forEach((item) => {
        item.analyticstype = 'Knowledge Article';
        item.appeared = data.knowledgeViews.find(
          (screen) => screen.article === item.title.replaceAll(/ \(banner\)$/g, '')
        )?.pressed;
        item.ctr = !item.appeared
          ? 0
          : ((Number(item.pressed) / Number(item.appeared)) * 100).toFixed(2) + ' %';
      });

      all.push(...coupons, ...todos, ...popups, ...notifications, ...home, ...knowledge);
      setAll(all);
      setShowTab('all');
    }
  }, [data]);

  return (
    <div className="w-full flex flex-col items-center">
      <CSVLink
        className="bg-orange text-white p-2 rounded mb-4"
        filename={`${companyName}-${formatDate(startDate)}--${formatDate(endDate)}.csv`}
        data={all}
        headers={headers}
      >
        Download all data
      </CSVLink>

      <div className="w-full flex flex-row items-center justify-center my-2">
        <MenuButton
          onClick={() => setShowTab('all')}
          active={showTab === 'all'}
          title="All"
          visible="true"
        />
        <MenuButton
          onClick={() => setShowTab('coupon')}
          active={showTab === 'coupon'}
          title="Coupon"
          visible="true"
        />
        <MenuButton
          onClick={() => setShowTab('todo')}
          active={showTab === 'todo'}
          title="Todo"
          visible="true"
        />
        <MenuButton
          onClick={() => setShowTab('popups')}
          active={showTab === 'popups'}
          title="Popups"
          visible="true"
        />
        <MenuButton
          onClick={() => setShowTab('notifications')}
          active={showTab === 'notifications'}
          title="Notifications"
          visible="true"
        />
        <MenuButton
          onClick={() => setShowTab('links')}
          active={showTab === 'links'}
          title="Links"
          visible="true"
        />
      </div>
      {showTab === 'coupon' && (
        <div>
          {data.coupons.length === 0 && <p>There are no Coupon analytics for this company</p>}
          {data.coupons.length !== 0 && (
            <table>
              <thead>
                <tr>
                  <th>Start date</th>
                  <th>End date</th>
                  <th>Product</th>
                  <th>Item</th>
                  <th>Views</th>
                  <th>Clicks</th>
                  <th>Unique</th>
                  <th>CTR</th>
                </tr>
              </thead>
              <tbody>
                {data.coupons.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td>{item.start_date}</td>
                      <td>{item.end_date}</td>
                      <td>{item.product}</td>
                      <td>
                        <LinkButton url={item.url} />
                        {item.title}
                      </td>
                      <td>{item.appeared}</td>
                      <td>{item.pressed}</td>
                      <td>{item.ctr}</td>
                      <td>{item.unique}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          )}
        </div>
      )}
      {showTab === 'todo' && (
        <div>
          {data.todo.length === 0 && <p>There are no Todo analytics for this company</p>}
          {data.todo.length !== 0 && (
            <table>
              <thead>
                <tr>
                  <th>Start date</th>
                  <th>End date</th>
                  <th>Product</th>
                  <th>Category</th>
                  <th>Subcategory</th>
                  <th>Item</th>
                  <th>Views</th>
                  <th>Clicks</th>
                  <th>CTR</th>
                  <th>Unique</th>
                </tr>
              </thead>
              <tbody>
                {data.todo.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td>{item.start_date}</td>
                      <td>{item.end_date}</td>
                      <td>{item.product.join(', ')}</td>
                      <td>{item.category}</td>
                      <td>{item.subcategory}</td>
                      <td>
                        <LinkButton url={item.url} />
                        {item.title}
                      </td>
                      <td>{item.appeared}</td>
                      <td>{item.pressed}</td>
                      <td>{item.ctr}</td>
                      <td>{item.unique}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          )}
        </div>
      )}
      {showTab === 'popups' && (
        <div>
          {data.popups.length === 0 && <p>There are no Popup analytics for this company</p>}
          {data.popups.length !== 0 && (
            <table>
              <thead>
                <tr>
                  <th>Start date</th>
                  <th>End date</th>
                  <th>Product</th>
                  <th>Item</th>
                  <th>Views</th>
                  <th>Clicks</th>
                  <th>CTR</th>
                  <th>Unique</th>
                </tr>
              </thead>
              <tbody>
                {data.popups.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td>{item.start_date}</td>
                      <td>{item.end_date}</td>
                      <td>{item.product}</td>
                      <td>{item.title}</td>
                      <td>{item.appeared}</td>
                      <td>{item.pressed}</td>
                      <td>{item.ctr}</td>
                      <td>{item.unique}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          )}
        </div>
      )}
      {showTab === 'notifications' && (
        <div>
          {data.notifications.length === 0 && (
            <p>There are no Notification analytics for this company</p>
          )}
          {data.notifications.length !== 0 && (
            <table>
              <thead>
                <tr>
                  <th>Start date</th>
                  <th>End date</th>
                  <th>Product</th>
                  <th>Category</th>
                  <th>Subcategory</th>
                  <th>Item</th>
                  <th>Views</th>
                  <th>Clicks</th>
                  <th>CTR</th>
                  <th>Unique</th>
                </tr>
              </thead>
              <tbody>
                {data.notifications.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td>{item.start_date}</td>
                      <td>{item.end_date}</td>
                      <td>{item.product}</td>
                      <td>{item.category}</td>
                      <td>{item.subcategory}</td>
                      <td>
                        <LinkButton url={item.url} />
                        {item.title}
                      </td>
                      <td>{item.appeared}</td>
                      <td>{item.pressed}</td>
                      <td>{item.ctr}</td>
                      <td>{item.unique}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          )}
        </div>
      )}
      {showTab === 'links' && (
        <div>
          {data.home.length === 0 && data.knowledge.length === 0 && (
            <p>There are no link analytics for this company</p>
          )}
          {(data.home.length !== 0 || data.knowledge.length !== 0) && (
            <table>
              <thead>
                <tr>
                  <th>Type</th>
                  <th>Start date</th>
                  <th>End date</th>
                  <th>Product</th>
                  <th>Category</th>
                  <th>Subcategory</th>
                  <th>Item / Article</th>
                  <th>Views</th>
                  <th>Clicks</th>
                  <th>CTR</th>
                  <th>Unique</th>
                </tr>
              </thead>
              <tbody>
                {data.home.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td>Home link</td>
                      <td></td>
                      <td></td>
                      <td>{item.product}</td>
                      <td>{item.week}</td>
                      <td>{item.type}</td>
                      <td>
                        <LinkButton url={item.url} /> {}
                      </td>
                      <td>{item.appeared}</td>
                      <td>{item.pressed}</td>
                      <td>{item.ctr}</td>
                      <td>{item.unique}</td>
                    </tr>
                  );
                })}
                {data.knowledge.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td>Knowledge Category</td>
                      <td>{item.start_date}</td>
                      <td>{item.end_date}</td>
                      <td>{item.product}</td>
                      <td>{item.category}</td>
                      <td>{}</td>
                      <td>
                        <LinkButton url={item.url} />
                        {item.screen}
                      </td>
                      <td>{item.appeared}</td>
                      <td>{item.pressed}</td>
                      <td>{item.ctr}</td>
                      <td>{item.unique}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          )}
        </div>
      )}
      {showTab === 'all' && (
        <div>
          {all.length === 0 && (
            <p>There are no analytics for this company in the given date range</p>
          )}
          {all.length !== 0 && (
            <table>
              <thead>
                <tr>
                  <th>Type</th>
                  <th>Start date</th>
                  <th>End date</th>
                  <th>Product</th>
                  <th>Category</th>
                  <th>Subcategory</th>
                  <th>Item / Article</th>
                  <th>Views</th>
                  <th>Clicks</th>
                  <th>CTR</th>
                  <th>Unique</th>
                </tr>
              </thead>
              <tbody>
                {all.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td>{item.analyticstype}</td>
                      <td>{item.start_date}</td>
                      <td>{item.end_date}</td>
                      <td>{item.product}</td>
                      <td>{item.category}</td>
                      <td>{item.subcategory}</td>
                      <td>
                        <LinkButton url={item.url} />
                        {item.title}
                      </td>
                      <td>{item.appeared}</td>
                      <td>{item.pressed}</td>
                      <td>{item.ctr}</td>
                      <td>{item.unique}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          )}
        </div>
      )}
    </div>
  );
};

export default CompanyAnalyticsTables;
