/* eslint react/forbid-prop-types: 0 */
import React from 'react';

const TextField = React.forwardRef(
  (
    {
      id,
      name,
      label,
      hint,
      errors,
      rootClassName,
      groupClassName,
      fieldClassName,
      labelClassName,
      children,
      ...rest
    },
    ref
  ) => {
    const hasError = !!errors && !!errors[name];

    return (
      <div className={`flex flex-col w-full my-1 ${rootClassName || ''}`}>
        <div
          className={`text-field-inner relative flex flex-row w-full items-center ${
            groupClassName || ''
          }`}
        >
          {label && (
            <label htmlFor={id || name} className={`w-1/3 ${labelClassName || ''}`}>
              {label}
            </label>
          )}
          {children || (
            <input
              name={name}
              id={id || name}
              className={`border-2 border-extralightorange border-solid rounded p-2 focus:border-2 focus:border-orange focus:outline-none 
            ${label ? 'w-2/3' : 'w-full'}
            ${rest?.disabled ? 'bg-disabled' : 'bg-white'}
            ${fieldClassName || ''}`}
              {...rest}
              ref={ref}
            />
          )}
        </div>
        {hint ? (
          <p className="mt-2 ml-auto w-2/3 text-sm text-gray-500" id="email-description">
            {hint}
          </p>
        ) : null}
        {hasError ? <div className="text-red">{errors[name].message}</div> : null}
      </div>
    );
  }
);

export default TextField;
