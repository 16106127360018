import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { login } from '../redux/actions/auth';
import TextField from '../components/TextField';
import CustomButton from '../components/CustomButton';
import logo from '../assets/logo.png';
import { useNavigate } from 'react-router-dom';
import '../style/screens/login.css';

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token = useSelector((state) => state.state.token);
  const logerror = useSelector((state) => state.auth.logerror);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  useEffect(() => {
    if (token !== '') {
      navigate('/home');
    }
  }, [navigate, token]);

  return (
    <div className="bg-orange flex items-center justify-center pt-16 h-screen">
      <div className="login-container">
        <div className="flex justify-center items-center mb-4">
          <img alt="logo" src={logo} />
          {process.env.REACT_APP_LANGUAGE === 'de' ? <span className={'text-5xl'}>🇩🇪</span> : null}
        </div>
        <div className="bg-white rounded p-8 pt-12 shadow">
          <TextField
            type="text"
            label="E-mail cím:"
            name="email"
            value={email}
            onChange={(event) => setEmail(event.target.value)}
          />
          <TextField
            type="password"
            label="Jelszó:"
            name="password"
            value={password}
            onChange={(event) => setPassword(event.target.value)}
          />
          <CustomButton onClick={() => dispatch(login(email, password))}>Belépés</CustomButton>
          <p stlye={{ color: 'red' }}>{logerror}</p>
        </div>
      </div>
    </div>
  );
};

export default Login;
