/* eslint-disable react-hooks/exhaustive-deps */
import { subDays } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { CSVLink } from 'react-csv';
import { useDispatch, useSelector } from 'react-redux';
import { getUserActivity } from '../../../redux/actions/analytics';
import CustomButton from '../../CustomButton';
import DateSelector from '../../DateSelector';
import Spinner from '../../Spinner';
import { formatDate } from '../helper';

const UserActivity = () => {
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.analytics.useractivity_loading);
  const userActivity = useSelector((state) => state.analytics.useractivity);

  const [startDate, setStartDate] = useState(subDays(new Date(), 30));
  const [endDate, setEndDate] = useState(new Date());

  useEffect(() => {
    dispatch(getUserActivity(formatDate(startDate), formatDate(endDate)));
  }, []);

  const search = () => {
    dispatch(getUserActivity(formatDate(startDate), formatDate(endDate)));
  };

  const headers = [
    { label: 'Date', key: 'date' },
    { label: 'Active users', key: 'users' },
    { label: 'Activity number', key: 'all' },
  ];

  return (
    <div className="w-full flex flex-col items-center">
      <DateSelector
        startDate={startDate}
        endDate={endDate}
        startDateChanged={(date) => setStartDate(date)}
        endDateChanged={(date) => setEndDate(date)}
      />

      <div className="flex items-center justify-center w-full">
        <CustomButton type="button" className="text-white" onClick={search}>
          Search
        </CustomButton>

        {userActivity.length !== 0 && (
          <CSVLink
            className="bg-orange text-white p-2 rounded"
            filename={`UserActivity-${formatDate(startDate)}-${formatDate(endDate)}.csv`}
            data={userActivity}
            headers={headers}
          >
            Download this table
          </CSVLink>
        )}
      </div>

      <div className="mx-12">
        {isLoading && <Spinner />}
        {!isLoading && userActivity.length !== 0 && (
          <table>
            <thead>
              <tr>
                <th>Date</th>
                <th>Active users</th>
                <th>Activity number</th>
              </tr>
            </thead>
            <tbody>
              {userActivity.map((item, index) => {
                return (
                  <tr key={index}>
                    <td>{item.date}</td>
                    <td>{item.users}</td>
                    <td>{item.all}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};

export default UserActivity;
