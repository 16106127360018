/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { CSVLink } from 'react-csv';
import { useSelector, useDispatch } from 'react-redux';
import { getClickHome } from '../../../redux/actions/analytics';
import CustomButton from '../../CustomButton';
import LinkButton from '../../LinkButton';
import { formatDate } from '../helper';
import Spinner from '../../Spinner';
import Dropdown from '../../Dropdown';

const calculateTotals = (data = []) => {
  return data.reduce(
    (acc, item) => {
      acc.total += item.pressed;
      acc.unique += item.unique;

      return acc;
    },
    {
      total: 0,
      unique: 0,
    }
  );
};

const HomeClick = ({ startDate, endDate }) => {
  const [selectedType, setSelectedType] = useState('');
  const dispatch = useDispatch();
  const homeLoading = useSelector((state) => state.analytics.clickhome_loading);
  const home = useSelector((state) => state.analytics.clickhome);

  const total = calculateTotals(home.screens);
  const babyTotal = calculateTotals(home?.screens?.filter((item) => item.screenId === 'baby'));
  const dadTotal = calculateTotals(home?.screens?.filter((item) => item.screenId === 'dad'));
  const mumTotal = calculateTotals(home?.screens?.filter((item) => item.screenId === 'mum'));

  const headers = [
    { label: 'Company', key: 'companyname' },
    { label: 'Product', key: 'product' },
    { label: 'Week', key: 'week' },
    { label: 'Type', key: 'screenId' },
    { label: 'URL', key: 'url' },
    { label: 'Clicks', key: 'pressed' },
    { label: 'Percentage (click / all clicks)', key: 'percentage' },
    { label: 'Unique', key: 'unique' },
  ];

  const search = () => {
    dispatch(getClickHome(formatDate(startDate), formatDate(endDate)));
  };

  return (
    <div className="w-full flex flex-col items-center">
      <div className="w-1/2 mt-8">
        <Dropdown
          value={selectedType}
          options={[
            { value: '', label: 'All' },
            { value: 'baby', label: 'Baby' },
            { value: 'dad', label: 'Dad' },
            { value: 'mum', label: 'Mum' },
          ]}
          onChange={(value) => setSelectedType(value.value)}
        />
      </div>
      {homeLoading && <Spinner />}
      {!homeLoading && (
        <div>
          <div className="my-8 flex items-center justify-center w-full">
            <CustomButton type="button" className="text-white" onClick={search}>
              Search
            </CustomButton>

            {home.length !== 0 ? (
              <CSVLink
                className="bg-orange text-white p-2 rounded"
                filename={`HomeClick-${formatDate(startDate)}-${formatDate(endDate)}.csv`}
                data={home.screens}
                headers={headers}
              >
                Download this table
              </CSVLink>
            ) : null}
          </div>

          {home.length !== 0 && (
            <table>
              <thead>
                <tr>
                  <th>Company</th>
                  <th>Product</th>
                  <th>Week</th>
                  <th>Type</th>
                  <th>URL</th>
                  <th>Clicks</th>
                  <th>Percentage (click / all clicks)</th>
                  <th>Unique</th>
                </tr>
              </thead>
              <tbody>
                {home.screens
                  .filter((item) => (selectedType === '' ? item : item.screenId === selectedType))
                  .map((item, index) => {
                    return (
                      <tr key={index}>
                        <td>{item.companyname.join(', ')}</td>
                        <td>{item.product.join(', ')}</td>
                        <td>{item.week}</td>
                        <td>{item.screenId}</td>
                        <td
                          style={{
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis',
                            overflow: 'hidden',
                          }}
                        >
                          <LinkButton url={item.url} />
                          {item.url}
                        </td>
                        <td>{item.pressed}</td>
                        <td>
                          {selectedType === 'baby'
                            ? ((Number(item.pressed) / Number(babyTotal.total)) * 100).toFixed(2)
                            : selectedType === 'dad'
                            ? ((Number(item.pressed) / Number(dadTotal.total)) * 100).toFixed(2)
                            : selectedType === 'mum'
                            ? ((Number(item.pressed) / Number(mumTotal.total)) * 100).toFixed(2)
                            : ((Number(item.pressed) / Number(total.total)) * 100).toFixed(2)}{' '}
                          %
                        </td>
                        <td>{item.unique}</td>
                      </tr>
                    );
                  })}
                {selectedType === 'baby' && (
                  <tr className="font-bold">
                    <td>SUM Baby</td>
                    <td />
                    <td />
                    <td />
                    <td />
                    <td>{babyTotal.total}</td>
                    <td />
                    <td>{babyTotal.unique}</td>
                  </tr>
                )}
                {selectedType === 'dad' && (
                  <tr className="font-bold">
                    <td>SUM Dad</td>
                    <td />
                    <td />
                    <td />
                    <td />
                    <td>{dadTotal.total}</td>
                    <td />
                    <td>{dadTotal.unique}</td>
                  </tr>
                )}
                {selectedType === 'mum' && (
                  <tr className="font-bold">
                    <td>SUM Mum</td>
                    <td />
                    <td />
                    <td />
                    <td />
                    <td>{mumTotal.total}</td>
                    <td />
                    <td>{mumTotal.unique}</td>
                  </tr>
                )}
                {selectedType === '' && (
                  <tr className="font-bold">
                    <td>SUM</td>
                    <td />
                    <td />
                    <td />
                    <td />
                    <td>{total.total}</td>
                    <td />
                    <td>{total.unique}</td>
                  </tr>
                )}
              </tbody>
            </table>
          )}
        </div>
      )}
    </div>
  );
};

export default HomeClick;
