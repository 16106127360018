import { API_TOKEN } from '../../constants/auth';

export const openDeleteModal = ({ selected, url, type, title, submitFunction }) => ({
  type: 'OPEN_DELETEMODAL',
  data: { item: selected, url, type, title, submitFunction },
});

export const closeDeleteModal = () => {
  return { type: 'CLOSE_DELETEMODAL' };
};

export const setIntervals = (prop) => {
  return { type: 'SET_INTERVAL', data: prop };
};
export const saveToken = (token) => {
  sessionStorage.setItem(API_TOKEN, token);

  return { type: 'SAVE_TOKEN', data: token };
};
export const logout = () => {
  sessionStorage.removeItem(API_TOKEN);

  return { type: 'LOGOUT' };
};

export const updatedData = () => {
  return { type: 'DATA_UPDATED' };
};

export const unauthorizedError = () => {
  sessionStorage.removeItem(API_TOKEN);

  return { type: '401_ERROR' };
};
