export const initialState = {
  user: {},
  logerror: '',
};

const auth = (state = initialState, action) => {
  switch (action.type) {
    case 'LOGIN':
      return {
        ...state,
        user: action.data,
        logerror: '',
      };
    case 'LOGIN_ERROR':
      return {
        ...state,
        user: {},
        logerror: action.data,
      };
    case 'GET_ME':
      return {
        ...state,
        user: action.data.data,
      };
    case 'GET_ME_ERROR':
      return {
        ...state,
        user: {},
      };
    default:
      return state;
  }
};

export default auth;
