import React from 'react';
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css'; // Import Sun Editor's CSS File
import {
  align,
  font,
  fontColor,
  fontSize,
  formatBlock,
  hiliteColor,
  horizontalRule,
  lineHeight,
  list,
  paragraphStyle,
  template,
  textStyle,
  image,
  video,
  link,
  audio,
  table,
  blockquote,
} from 'suneditor/src/plugins';
import { apiUrl, master_key } from '../config/settings';

const lang = {
  code: 'en',
  toolbar: {
    default: 'Default',
    save: 'Save',
    font: 'Font',
    formats: 'Formats',
    fontSize: 'Size',
    bold: 'Bold',
    underline: 'Underline',
    italic: 'Italic',
    strike: 'Strike',
    subscript: 'Subscript',
    superscript: 'Superscript',
    removeFormat: 'Remove Format',
    fontColor: 'Font Color',
    hiliteColor: 'Highlight Color',
    indent: 'Indent',
    outdent: 'Outdent',
    align: 'Align',
    alignLeft: 'Align left',
    alignRight: 'Align right',
    alignCenter: 'Align center',
    alignJustify: 'Align justify',
    list: 'List',
    orderList: 'Ordered list',
    unorderList: 'Unordered list',
    horizontalRule: 'Horizontal line',
    hr_solid: 'Solid',
    hr_dotted: 'Dotted',
    hr_dashed: 'Dashed',
    table: 'Table',
    link: 'Link',
    math: 'Math',
    image: 'Image',
    video: 'Video',
    audio: 'Audio',
    fullScreen: 'Full screen',
    showBlocks: 'Show blocks',
    codeView: 'Code view',
    undo: 'Undo',
    redo: 'Redo',
    preview: 'Preview',
    print: 'print',
    tag_p: 'Paragraph',
    tag_div: 'Normal (DIV)',
    tag_h: 'Header',
    tag_blockquote: 'Quote',
    tag_pre: 'Code',
    template: 'Template',
    lineHeight: 'Line height',
    paragraphStyle: 'Paragraph style',
    textStyle: 'Text style',
    imageGallery: 'Image gallery',
    mention: 'Mention',
  },
  dialogBox: {
    linkBox: {
      title: 'Insert Link',
      url: 'Text or Image Link',
      text: 'Text to display',
      newWindowCheck: 'Open in new window',
      downloadLinkCheck: 'Download link',
      bookmark: 'Bookmark',
    },
    mathBox: {
      title: 'Math',
      inputLabel: 'Mathematical Notation',
      fontSizeLabel: 'Font Size',
      previewLabel: 'Preview',
    },
    imageBox: {
      title: 'Insert image',
      file: 'Select from files',
      url: 'Impression URL',
      altText: 'Alternative text',
    },
    videoBox: {
      title: 'Insert Video',
      file: 'Select from files',
      url: 'Media embed URL, YouTube/Vimeo',
    },
    audioBox: {
      title: 'Insert Audio',
      file: 'Select from files',
      url: 'Audio URL',
    },
    browser: {
      tags: 'Tags',
      search: 'Search',
    },
    caption: 'Insert description',
    close: 'Close',
    submitButton: 'Submit',
    revertButton: 'Revert',
    proportion: 'Constrain proportions',
    basic: 'Basic',
    left: 'Left',
    right: 'Right',
    center: 'Center',
    width: 'Width',
    height: 'Height',
    size: 'Size',
    ratio: 'Ratio',
  },
  controller: {
    edit: 'Edit',
    unlink: 'Unlink',
    remove: 'Remove',
    insertRowAbove: 'Insert row above',
    insertRowBelow: 'Insert row below',
    deleteRow: 'Delete row',
    insertColumnBefore: 'Insert column before',
    insertColumnAfter: 'Insert column after',
    deleteColumn: 'Delete column',
    fixedColumnWidth: 'Fixed column width',
    resize100: 'Resize 100%',
    resize75: 'Resize 75%',
    resize50: 'Resize 50%',
    resize25: 'Resize 25%',
    autoSize: 'Auto size',
    mirrorHorizontal: 'Mirror, Horizontal',
    mirrorVertical: 'Mirror, Vertical',
    rotateLeft: 'Rotate left',
    rotateRight: 'Rotate right',
    maxSize: 'Max size',
    minSize: 'Min size',
    tableHeader: 'Table header',
    mergeCells: 'Merge cells',
    splitCells: 'Split Cells',
    HorizontalSplit: 'Horizontal split',
    VerticalSplit: 'Vertical split',
  },
  menu: {
    spaced: 'Spaced',
    bordered: 'Bordered',
    neon: 'Neon',
    translucent: 'Translucent',
    shadow: 'Shadow',
    code: 'Code',
  },
};

const HTMLEditor = ({ defaultValue, onChange, title, setContents }) => {
  return (
    <div>
      <div>{title}</div>
      <SunEditor
        onChange={onChange}
        defaultValue={defaultValue}
        setContents={setContents}
        setDefaultStyle="font-family: Poppins-Regular;"
        autoFocus={false}
        lang={lang}
        width="100%"
        setOptions={{
          showPathLabel: false,
          minHeight: '50vh',
          maxHeight: '50vh',
          placeholder: 'Enter your text here!!!',
          plugins: [
            align,
            font,
            fontColor,
            fontSize,
            formatBlock,
            hiliteColor,
            horizontalRule,
            lineHeight,
            list,
            paragraphStyle,
            template,
            table,
            textStyle,
            image,
            video,
            link,
            audio,
            blockquote,
          ],
          buttonList: [
            ['undo', 'redo', 'preview', 'codeView'],
            ['font', 'fontSize', 'formatBlock'],
            ['paragraphStyle', 'blockquote'],
            ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript'],
            ['fontColor', 'hiliteColor'],
            ['removeFormat'],
            '/', // Line break
            ['outdent', 'indent'],
            ['align', 'horizontalRule', 'list', 'lineHeight'],
            ['table', 'link', 'image', 'video', 'audio'],
          ],
          formats: ['p', 'div', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6'],
          font: [
            'Poppins-Light',
            'Poppins-Regular',
            'Poppins-Bold',
            'Arial-Regular',
            'Arial-Bold',
            'Montserrat-Regular',
            'Montserrat-Bold',
          ],
          fontSize: [8, 9, 10, 11, 12, 13, 14, 16, 18, 20, 22, 24, 26, 28, 36, 48, 72],
          imageRotation: true,
          imageMultipleFile: false,
          imageUploadHeader: {
            Authorization: `Bearer ${master_key}`,
          },
          imageUploadUrl: `${apiUrl}/admin/storage/upload`,
          colorList: [
            ['#ffffff', '#cccccc', '#999999', '#666666', '#333333', '#000000'],
            ['#F5F2EE', '#FEDDAE', '#FCC27B', '#666666', '#333333', '#000000'],
            ['#FF0000', '#800000', '#FFFF00', '#808000', '#00FF00', '#008000'],
            ['#00FFFF', '#008080', '#0000FF', '#000080', '#FF00FF', '#800080'],
          ],
        }}
      />
    </div>
  );
};

export default HTMLEditor;
