const ContentViewSummaryTable = ({ data, fieldName = 'screens', title, screenColumnName } = {}) => {
  return (
    <>
      {data.from && data.to ? (
        <p className={'text-center'}>
          Displayed period:{' '}
          <strong>
            {data.from} - {data.to}
          </strong>
        </p>
      ) : (
        ''
      )}
      <table>
        <thead>
          <tr>
            <th>{screenColumnName ?? 'Screen'}</th>
            <th>Views</th>
            <th>Percentage (views / all views)</th>
            <th>Unique</th>
          </tr>
        </thead>
        <tbody>
          {data[fieldName].map((item, index) => {
            return (
              <tr key={index}>
                <td>{item.screen}</td>
                <td>{item.appeared}</td>
                <td>{((item.appeared / data.totalAppeared) * 100).toFixed(2)}%</td>
                <td>{item.unique}</td>
              </tr>
            );
          })}
          <tr className="font-bold">
            <td>Total for {title}</td>
            <td>{data.totalAppeared}</td>
            <td />
            <td>{data.uniq}</td>
          </tr>
        </tbody>
      </table>
    </>
  );
};

export default ContentViewSummaryTable;
