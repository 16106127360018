/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Dropdown from '../../components/Dropdown';
import { openDeleteModal } from '../../redux/actions/state';
import FormForm from '../../components/forms/FormForm';
import Checkbox from '../../components/Checkbox';
import ImageUp from '../../components/ImageUp';
import HTMLEditor from '../../components/HTMLEditor';
import Layout from '../../components/layout/Layout';
import TextField from '../../components/TextField';
import TextArea from '../../components/TextArea';
import NewButton from '../../components/NewButton';
import MenuButton from '../../components/MenuButton';
import CustomButton from '../../components/CustomButton';
import MarkdownEditor from '../../components/MarkdownEditor';
import { listDeals, createDeal, updateDeal, removeDeal } from '../../redux/actions/deals';
import { isBetweenPublishDateRange, validateStartEndDates } from '../../utils/date';

const DEFAULT_SELECTED_ITEM = {
  id: '',
  img: '',
  link: '',
  impressionUrl: '',
  order: '',
  internal_name: '',
  title: '',
  description: '',
  companyname: '',
  article: '',
  articleHtml: '',
  summary: '',
  product: '',
  formdescription: '',
  formtext: '',
  formtext2: '',
  secondimg: '',
  secondtitle: '',
  seconddescription: '',
  secondsummary: '',
  visible: 'false',
  abtest: 'false',
  form: 'false',
  middlePageButtonText: '',
  formfields: {
    name: 'false',
    phone: 'false',
    email: 'false',
    comment: 'false',
    expectedDate: 'false',
  },
  start_date: '',
  end_date: '',
};

const Deals = () => {
  const dispatch = useDispatch();
  const [newCreate, setNew] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});
  const [term, setTerm] = useState('');
  const companyList = useSelector((state) => state.analytics.companylist);
  const deals = useSelector((state) => state.deals.deals);

  const activeDeals = deals.filter(
    (deal) =>
      deal.visible !== 'false' &&
      isBetweenPublishDateRange(deal) &&
      ((deal.companyname && deal.companyname.toLowerCase().includes(term.toLowerCase())) || !term)
  );

  const inactiveDeals = deals.filter(
    (deal) =>
      (deal.visible === 'false' || !isBetweenPublishDateRange(deal)) &&
      ((deal.companyname && deal.companyname.toLowerCase().includes(term.toLowerCase())) || !term)
  );

  useEffect(() => {
    dispatch(listDeals()).then(() => setSelectedItem({}));
  }, [dispatch]);

  useEffect(() => {
    if (selectedItem?.id && !deals.find((deal) => deal.id === selectedItem?.id)) {
      setNew(false);
      setSelectedItem({ ...DEFAULT_SELECTED_ITEM });
    }
  }, [deals]);

  const save = async () => {
    const selected = selectedItem;

    const dateValidationResult = validateStartEndDates(selected);

    if (!dateValidationResult.valid) return alert(dateValidationResult.error);

    if (newCreate) {
      selected.id = Date.now().toString();
      await dispatch(createDeal(selected));
      setNew(false);
    } else {
      await dispatch(updateDeal(selected));
    }
  };

  const deleteCategory = () => {
    dispatch(
      openDeleteModal({
        type: 'coupons',
        title: selectedItem.title,
        submitFunction: () => dispatch(removeDeal(selectedItem.id)),
      })
    );
  };

  const renderDealForm = () => {
    const deeplink = newCreate ? '' : 'preghello://deals';

    return (
      <div className="mx-12">
        {(Object.keys(selectedItem).length > 0 || newCreate) && (
          <div key={selectedItem.id}>
            <TextField type="text" label="Id" name="id" value={selectedItem.id} disabled />
            <TextField type="text" label="Deeplink" name="deeplink" value={deeplink} disabled />
            <TextField
              type="text"
              label="Internal name"
              name="internal_name"
              value={selectedItem.internal_name}
              onChange={(event) => {
                const selectedItemNew = { ...selectedItem };
                selectedItemNew.internal_name = event.target.value;
                setSelectedItem(selectedItemNew);
              }}
            />
            <TextField
              type="text"
              label="Title (max 35 characters)"
              name="title"
              value={selectedItem.title}
              onChange={(event) => {
                const selectedItemNew = { ...selectedItem };
                selectedItemNew.title = event.target.value;
                setSelectedItem(selectedItemNew);
              }}
            />
            <TextField label="Company name">
              <Dropdown
                value={selectedItem.companyname}
                options={[{ value: '', label: 'None' }, ...companyList]}
                onChange={(value) => {
                  const selectedItemNew = { ...selectedItem };
                  selectedItemNew.companyname = value.value;
                  setSelectedItem(selectedItemNew);
                }}
                className={'w-2/3'}
              />
            </TextField>
            <TextField
              type="text"
              label="Start Date"
              name="start_date"
              placeholder="2023-01-01"
              required
              value={selectedItem.start_date}
              onChange={(event) => {
                const selectedItemNew = { ...selectedItem };
                selectedItemNew.start_date = event.target.value;
                setSelectedItem(selectedItemNew);
              }}
            />
            <TextField
              type="text"
              label="End Date"
              name="end_date"
              placeholder="2023-01-02"
              required
              value={selectedItem.end_date}
              onChange={(event) => {
                const selectedItemNew = { ...selectedItem };
                selectedItemNew.end_date = event.target.value;
                setSelectedItem(selectedItemNew);
              }}
            />
            <TextField
              type="text"
              label="Product"
              name="product"
              value={selectedItem.product}
              onChange={(event) => {
                const selectedItemNew = { ...selectedItem };
                selectedItemNew.product = event.target.value;
                setSelectedItem(selectedItemNew);
              }}
            />
            <TextArea
              type="text"
              label="Ad text if large coupon (max 110 characters)"
              name="description"
              value={selectedItem.description}
              onChange={(event) => {
                const selectedItemNew = { ...selectedItem };
                selectedItemNew.description = event.target.value;
                setSelectedItem(selectedItemNew);
              }}
            />
            <TextArea
              type="text"
              label="Ad text if small coupon (max 65 characters)"
              name="summary"
              value={selectedItem.summary}
              onChange={(event) => {
                const selectedItemNew = { ...selectedItem };
                selectedItemNew.summary = event.target.value;
                setSelectedItem(selectedItemNew);
              }}
            />
            <TextField
              type="text"
              label="Button link (should start with 'https://')"
              name="link"
              value={selectedItem.link}
              onChange={(event) => {
                const selectedItemNew = { ...selectedItem };
                selectedItemNew.link = event.target.value;
                setSelectedItem(selectedItemNew);
              }}
            />
            <TextField
              type="text"
              label="Impression URL (should start with 'https://')"
              name="impressionUrl"
              value={selectedItem.impressionUrl}
              onChange={(event) => {
                const selectedItemNew = { ...selectedItem };
                selectedItemNew.impressionUrl = event.target.value;
                setSelectedItem(selectedItemNew);
              }}
            />
            <ImageUp
              title="Cover Image (1146 x 759 in large coupon) or (760 x 480 in small coupon) (safe area: 40-44 pixel from the top)"
              parentCallback={() => {
                const selectedItemNew = { ...selectedItem };
                selectedItemNew.img = '';
                setSelectedItem(selectedItemNew);
              }}
              value={selectedItem.img}
              getImg={(value) => {
                const selectedItemNew = { ...selectedItem };
                selectedItemNew.img = value;
                setSelectedItem(selectedItemNew);
              }}
            />
            <HTMLEditor
              title="Text for the middle page (html)"
              defaultValue={selectedItem.articleHtml}
              setContents={selectedItem.articleHtml}
              onChange={(html) => {
                const selectedItemNew = { ...selectedItem };
                selectedItemNew.articleHtml = html;
                setSelectedItem(selectedItemNew);
              }}
            />
            <MarkdownEditor
              title="Text for the middle page (markdown)"
              value={selectedItem.article}
              onChange={({ text }) => {
                const selectedItemNew = { ...selectedItem };
                selectedItemNew.article = text;
                setSelectedItem(selectedItemNew);
              }}
            />
            <TextField
              type="text"
              label="Middle page button text"
              name="middlePageButtonText"
              value={selectedItem.middlePageButtonText}
              onChange={(event) => {
                const selectedItemNew = { ...selectedItem };
                selectedItemNew.middlePageButtonText = event.target.value;
                setSelectedItem(selectedItemNew);
              }}
            />
            <TextField
              type="text"
              label="Order"
              name="order"
              value={selectedItem.order}
              onChange={(event) => {
                const selectedItemNew = { ...selectedItem };
                selectedItemNew.order = event.target.value;
                setSelectedItem(selectedItemNew);
              }}
            />
            <div className="flex items-start justify-start">
              <div className="w-1/3" />
              <Checkbox
                name="Visibility"
                selected={selectedItem.visible}
                getValue={(data) => {
                  const selectedItemNew = { ...selectedItem };
                  selectedItemNew.visible = data;
                  setSelectedItem(selectedItemNew);
                }}
              />
            </div>
            <div className="flex items-start justify-start">
              <div className="w-1/3" />
              <Checkbox
                name="AB Test"
                selected={selectedItem.abtest}
                getValue={(data) => {
                  const selectedItemNew = { ...selectedItem };
                  selectedItemNew.abtest = data;
                  setSelectedItem(selectedItemNew);
                }}
              />
            </div>
            {selectedItem.abtest === 'true' && (
              <div>
                <TextField
                  type="text"
                  label="Second title"
                  name="secondtitle"
                  value={selectedItem.secondtitle}
                  onChange={(event) => {
                    const selectedItemNew = { ...selectedItem };
                    selectedItemNew.secondtitle = event.target.value;
                    setSelectedItem(selectedItemNew);
                  }}
                />
                <TextArea
                  type="text"
                  label="Second ad text if large coupon (max 110 characters)"
                  name="seconddescription"
                  value={selectedItem.seconddescription}
                  onChange={(event) => {
                    const selectedItemNew = { ...selectedItem };
                    selectedItemNew.seconddescription = event.target.value;
                    setSelectedItem(selectedItemNew);
                  }}
                />
                <TextArea
                  type="text"
                  label="Second ad text if small coupon (max 110 characters)"
                  name="secondsummary"
                  value={selectedItem.secondsummary}
                  onChange={(event) => {
                    const selectedItemNew = { ...selectedItem };
                    selectedItemNew.secondsummary = event.target.value;
                    setSelectedItem(selectedItemNew);
                  }}
                />
                <ImageUp
                  title="Second image (1120 x 744) (safe area: 40-44 pixel from the top)"
                  parentCallback={() => {
                    const selectedItemNew = { ...selectedItem };
                    selectedItemNew.secondimg = '';
                    setSelectedItem(selectedItemNew);
                  }}
                  value={selectedItem.secondimg}
                  getImg={(value) => {
                    const selectedItemNew = { ...selectedItem };
                    selectedItemNew.secondimg = value;
                    setSelectedItem(selectedItemNew);
                  }}
                />
              </div>
            )}
            <div className="flex items-start justify-start">
              <div className="w-1/3" />
              <Checkbox
                name="Form visible"
                selected={selectedItem.form}
                getValue={(data) => {
                  const selectedItemNew = { ...selectedItem };
                  selectedItemNew.form = data;
                  setSelectedItem(selectedItemNew);
                }}
              />
            </div>
            {selectedItem.form === 'true' && selectedItem.id && (
              <FormForm
                formtype="deals"
                sourceIdnow={selectedItem.id}
                categoryIdnow={null}
                parentCallback={() => setSelectedItem({})}
                selectedItem={selectedItem}
              />
            )}
            {selectedItem.form === 'true' && !selectedItem.id && (
              <div>Please first save this item</div>
            )}
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <CustomButton red type="submit" className="w-1/4" onClick={() => deleteCategory()}>
                Delete
              </CustomButton>
              <div style={{ width: 20, height: 1 }} />
              <CustomButton type="submit" className="w-1/4" onClick={() => save()}>
                Save
              </CustomButton>
            </div>
          </div>
        )}
      </div>
    );
  };

  const getItemData = (value) => {
    return {
      id: value.id,
      img: value.img ? value.img : '',
      internal_name: value.internal_name ? value.internal_name : '',
      title: value.title ? value.title : '',
      link: value.link ? value.link : '',
      impressionUrl: value.impressionUrl ? value.impressionUrl : '',
      order: value.order ? value.order : '',
      description: value.description ? value.description : '',
      companyname: value.companyname ? value.companyname : '',
      article: value.article ? value.article : '',
      articleHtml: value.articleHtml ? value.articleHtml : '',
      summary: value.summary ? value.summary : '',
      product: value.product ? value.product : '',
      form: value.form ? value.form : 'false',
      formId: value.formId ? value.formId : '',
      abtest: value.abtest ? value.abtest : 'false',
      visible: value.visible ? value.visible : 'false',
      secondimg: value.secondimg ? value.secondimg : '',
      secondtitle: value.secondtitle ? value.secondtitle : '',
      seconddescription: value.seconddescription ? value.seconddescription : '',
      secondsummary: value.secondsummary ? value.secondsummary : '',
      middlePageButtonText: value.middlePageButtonText ? value.middlePageButtonText : '',
      start_date: value.start_date || '',
      end_date: value.end_date || '',
    };
  };

  return (
    <Layout active="deals">
      <div>
        <div className="w-2/3 mt-12 mx-auto mb-8 flex items-center justify-center">
          <TextField label="Search Company">
            <Dropdown
              value={term}
              options={[{ value: '', label: 'None' }, ...companyList]}
              onChange={({ value }) => setTerm(value)}
              className={'w-2/3'}
            />
          </TextField>
        </div>
        <div className="flex flex-col justify-center w-full my-12">
          <div className="mx-12 flex flex-wrap">
            <NewButton
              onClick={() => {
                setSelectedItem({ ...DEFAULT_SELECTED_ITEM });
                setNew(true);
              }}
              title="Add Deal"
            />
            {activeDeals.length > 0 &&
              activeDeals.map((deal) => {
                let title = `${deal.internal_name || deal.title} - ${deal.order}`;
                if (deal.product) {
                  title = `${deal.product} - ${title}`;
                }

                if (deal.companyname) {
                  title = `${deal.companyname} - ${title}`;
                }

                return (
                  <MenuButton
                    key={deal.id}
                    onClick={() => {
                      setSelectedItem(getItemData(deal));
                      setNew(false);
                    }}
                    active={selectedItem.id === deal.id}
                    visible={'true'}
                    title={title}
                  />
                );
              })}
          </div>

          {inactiveDeals.length > 0 && (
            <div className="mx-12 flex flex-wrap">
              <details>
                <summary className={'cursor-pointer'}>Inactive deals</summary>

                {inactiveDeals.map((deal) => {
                  let title = `${deal.internal_name || deal.title} - ${deal.order}`;
                  if (deal.product) {
                    title = `${deal.product} - ${title}`;
                  }

                  if (deal.companyname) {
                    title = `${deal.companyname} - ${title}`;
                  }

                  return (
                    <MenuButton
                      key={deal.id}
                      onClick={() => {
                        setSelectedItem(getItemData(deal));
                      }}
                      active={selectedItem.id === deal.id}
                      visible={'false'}
                      title={title}
                    />
                  );
                })}
              </details>
            </div>
          )}
        </div>
        {renderDealForm()}
      </div>
    </Layout>
  );
};

export default Deals;
