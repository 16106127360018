/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { CSVLink } from 'react-csv';
import { useSelector, useDispatch } from 'react-redux';
import { subDays } from 'date-fns';
import { getScreenMain, setLoadingFalse } from '../../../redux/actions/analytics';
import CustomButton from '../../CustomButton';
import { formatDate } from '../helper';
import HomeScreen from './HomeScreen';
import ToolScreen from './ToolScreen';
import TodoScreen from './TodoScreen';
import KnowledgeScreen from './KnowledgeScreen';
import DealsScreen from './DealsScreen';
import DateSelector from '../../DateSelector';
import Dropdown from '../../Dropdown';
import Spinner from '../../Spinner';

const ScreenAnalytics = () => {
  const dispatch = useDispatch();
  const mainLoading = useSelector((state) => state.analytics.screenmain_loading);
  const main = useSelector((state) => state.analytics.screenmain);
  const [startDate, setStartDate] = useState(subDays(new Date(), 30));
  const [endDate, setEndDate] = useState(new Date());
  const [selectedScreen, setSelectedScreen] = useState('');

  const mainScreenOptions = [
    { value: '', label: 'All' },
    { value: 'home', label: 'Home' },
    { value: 'knowledge', label: 'Knowledge' },
    { value: 'todo', label: 'Todo' },
    { value: 'tools', label: 'Tools' },
    { value: 'deals', label: 'Deals' },
  ];

  const headers = [
    { label: 'Screen', key: 'screen' },
    { label: 'Views', key: 'appeared' },
    { label: 'Percentage', key: 'percentage' },
    { label: 'Unique', key: 'unique' },
  ];

  useEffect(() => {
    if (startDate !== '' && endDate !== '' && selectedScreen === '') {
      dispatch(getScreenMain(formatDate(startDate), formatDate(endDate)));
    }
  }, []);

  const search = () => {
    dispatch(getScreenMain(formatDate(startDate), formatDate(endDate)));
  };

  return (
    <div className="w-full flex flex-col items-center">
      <DateSelector
        startDate={startDate}
        endDate={endDate}
        startDateChanged={(date) => setStartDate(date)}
        endDateChanged={(date) => setEndDate(date)}
      />
      <div className="w-1/2">
        <Dropdown
          value={selectedScreen}
          options={mainScreenOptions}
          onChange={(value) => {
            setSelectedScreen(value.value);
            dispatch(setLoadingFalse());
          }}
        />
      </div>
      {selectedScreen === '' && (
        <div>
          <div className="my-8 flex items-center justify-center w-full">
            <CustomButton type="button" className="text-white" onClick={search}>
              Search
            </CustomButton>

            {!mainLoading && main.length !== 0 && (
              <CSVLink
                className="bg-orange text-white p-2 rounded"
                filename={`ContentViews-ALL-${formatDate(startDate)}--${formatDate(endDate)}.csv`}
                data={main.screens}
                headers={headers}
              >
                Download this table
              </CSVLink>
            )}
          </div>

          {mainLoading && <Spinner />}
          {!mainLoading && startDate !== '' && main.length !== 0 && (
            <div>
              <div className="rounded-md bg-lightorange mb-4 p-4">
                <h3 className="text-sm font-medium text-yellow-800">Legend</h3>
                <div className="mt-2 text-sm text-yellow-700">
                  <ul className="list-disc pl-4">
                    <li>
                      <strong>article:</strong> visits to individual Knowledge articles
                    </li>
                    <li>
                      <strong>knowledge:</strong> visits to the main Knowledge screen
                    </li>
                    <li>
                      <strong>knowledge-detail:</strong> visits to individual Knowledge categories
                    </li>
                  </ul>
                </div>
              </div>

              <table>
                <thead>
                  <tr>
                    <th>Screen</th>
                    <th>Views</th>
                    <th>Percentage (views / all views)</th>
                    <th>Unique</th>
                  </tr>
                </thead>
                <tbody>
                  {main.screens.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td>{item.screen}</td>
                        <td>{item.appeared}</td>
                        <td>{((item.appeared / main.totalAppeared) * 100).toFixed(2)}%</td>
                        <td>{item.unique}</td>
                      </tr>
                    );
                  })}
                  <tr className="font-bold">
                    <td>SUM</td>
                    <td>{main.totalAppeared}</td>
                    <td />
                    <td>{main.uniq}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          )}
        </div>
      )}
      {selectedScreen === 'home' && <HomeScreen startDate={startDate} endDate={endDate} />}
      {selectedScreen === 'knowledge' && (
        <KnowledgeScreen startDate={startDate} endDate={endDate} />
      )}
      {selectedScreen === 'todo' && <TodoScreen startDate={startDate} endDate={endDate} />}
      {selectedScreen === 'tools' && <ToolScreen startDate={startDate} endDate={endDate} />}
      {selectedScreen === 'deals' && <DealsScreen startDate={startDate} endDate={endDate} />}
    </div>
  );
};

export default ScreenAnalytics;
