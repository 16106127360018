import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getUserEmail, deleteUser } from '../redux/actions/api';
import Layout from '../components/layout/Layout';
import CustomButton from '../components/CustomButton';
import TextField from '../components/TextField';

const UserDelete = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.api.useremail);
  const userMissing = useSelector((state) => state.api.useremail404);
  const [email, setEmail] = useState('');

  return (
    <Layout active="userdelete">
      <div className="flex flex-col items-start mx-12 mt-8">
        <div className="flex flex-row items-center justify-around mb-8 w-full">
          <TextField
            fieldClassName="mx-28"
            onChange={(event) => setEmail(event.target.value)}
            value={email}
          />
          <CustomButton
            type="submit"
            className="w-1/4"
            onClick={() => dispatch(getUserEmail(email))}
          >
            Find
          </CustomButton>
        </div>
        {userMissing === true && <div>Sorry, we cannot find this email in the database.</div>}
        {userMissing === false && user.email && (
          <div className="flex flex-col w-full bg-white p-8 rounded">
            <div className="flex flex-row w-full">
              <div className="w-1/4 font-bold">Name</div>
              <div className="w-1/4 font-bold">Email</div>
              <div className="w-1/4 font-bold">Notiid</div>
              <div className="w-1/4 font-bold">Expected Date</div>
            </div>
            <div className="flex flex-row w-full">
              <div className="w-1/4">{user.name}</div>
              <div className="w-1/4">{user.email}</div>
              <div className="w-1/4">{user.notifId}</div>
              <div className="w-1/4">{user.pregdata.expectedDate}</div>
            </div>
          </div>
        )}
        {userMissing === false && user.email && (
          <CustomButton
            red
            type="submit"
            className="w-1/5"
            onClick={() => {
              dispatch(deleteUser(user.id));
            }}
          >
            Delete user
          </CustomButton>
        )}
      </div>
    </Layout>
  );
};

export default UserDelete;
