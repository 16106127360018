import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getFoodsafeties, update, create } from '../../redux/actions/tools';
import { sortByVisibility, sortByVisibilityAndOrder } from '../../utils/sort';
import { openDeleteModal } from '../../redux/actions/state';
import Checkbox from '../Checkbox';
import MenuButton from '../MenuButton';
import NewButton from '../NewButton';
import CustomButton from '../CustomButton';
import TextField from '../TextField';
import TextArea from '../TextArea';

const FoodSafety = () => {
  const dispatch = useDispatch();
  const foodSafety = useSelector((state) => state.tools.foodsafeties);

  const deleteType = useSelector((state) => state.state.deleteType);
  const [tab, setTab] = useState(0);
  const [week, setWeek] = useState(null);
  const [newCreate, setNew] = useState(true);
  const [type, setType] = useState('');
  const [selectedItem, setSelectedItem] = useState({});
  const [selectedItem2, setSelectedItem2] = useState({});

  useEffect(() => {
    if (foodSafety === [] || !foodSafety) {
      dispatch(getFoodsafeties());
    }
  }, [dispatch, foodSafety]);

  useEffect(() => {
    if (deleteType === 'foodsafety-category') {
      setNew(false);
      setWeek(null);
      setSelectedItem({});
      setSelectedItem2({});
    }
    if (deleteType === 'foodsafety-article') {
      setNew(false);
      setWeek(null);
      setSelectedItem2({});
    }
  }, [foodSafety, deleteType]);

  const save = (e) => {
    e.preventDefault();
    const selected = selectedItem;
    const url = '/foodsafety/' + selectedItem.id;
    dispatch(update(selected, url, 'foodsafety'));
  };
  const save2 = (e) => {
    e.preventDefault();
    const selected = selectedItem2;
    const url = '/foodsafety/updatedata/' + selectedItem.id;
    dispatch(update(selected, url, 'foodsafety'));
  };

  const createArticle = (e) => {
    e.preventDefault();
    const selected = selectedItem2;
    selected.id = Date.now().toString();
    const url = '/foodsafety/data/' + selectedItem.id;
    setSelectedItem2({});
    setNew(false);
    setType('');
    dispatch(update(selected, url, 'foodsafety'));
  };
  const createCategory = (e) => {
    e.preventDefault();
    const selected = selectedItem;
    selected.id = Date.now().toString();
    const url = '/foodsafety/';
    setSelectedItem({});
    setNew(false);
    setType('');
    dispatch(create(selected, url, 'foodsafety'));
  };

  const deleteArticle = (e) => {
    e.preventDefault();
    const selected = selectedItem2;
    const url = '/foodsafety/removedata/' + selectedItem.id;
    const type = 'foodsafety-article';
    const title = selected.title;
    dispatch(openDeleteModal({ selected, url, type, title }));
  };

  const deleteCategory = (e) => {
    e.preventDefault();
    const selected = selectedItem;
    const url = '/foodsafety/' + selectedItem.id;
    const type = 'foodsafety-category';
    const title = selected.title;
    dispatch(openDeleteModal({ selected, url, type, title }));
  };

  const deeplink = newCreate ? '' : 'preghello://tools/food-safety';

  return (
    <div>
      <div
        className="mx-auto"
        style={{
          display: 'flex',
          width: '80%',
          flexDirection: 'row',
          flexWrap: 'wrap',
        }}
      >
        <NewButton
          onClick={() => {
            setNew(true);
            setType('first');
            setSelectedItem({
              visible: 'false',
            });
          }}
          title="Add Category"
        />

        {foodSafety &&
          foodSafety
            .sort((a, b) => a.title?.localeCompare(b?.title))
            .sort(sortByVisibilityAndOrder())
            .map((e, i) => (
              <MenuButton
                key={i}
                onClick={() => {
                  const datatorender = {
                    id: e.id,
                    data: e.data ? e.data : [],
                    title: e.title ? e.title : '',
                    order: e.order ? e.order : '',
                    visible: e.visible ? e.visible : 'true',
                  };
                  setTab(i);
                  setWeek(null);
                  setNew(false);
                  setSelectedItem(datatorender);
                  setSelectedItem2({});
                }}
                active={tab === i}
                title={e.title + ' - ' + e.order}
                visible={e.visible ? e.visible : 'true'}
              />
            ))}
      </div>
      <div
        style={{
          width: '100%',
          marginTop: 20,
          marginBottom: 20,
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        {foodSafety && foodSafety.length > 0 && Object.keys(selectedItem).length > 0 && (
          <div style={{ width: '80%', display: 'flex', flexWrap: 'wrap' }}>
            <NewButton
              onClick={() => {
                setNew(true);
                setType('second');
                setSelectedItem2({ visible: 'false', level: 'red' });
              }}
              title="Add Article"
            />
            {foodSafety[tab].data
              .sort(sortByVisibility())
              .sort((a, b) => a.title?.localeCompare(b?.title))
              .map((value, index) => {
                if (!value) return null;
                return (
                  <MenuButton
                    key={index}
                    onClick={() => {
                      const datatorender = {
                        id: value.id,
                        level: value.level ? value.level : '',
                        summary: value.summary ? value.summary : '',
                        title: value.title ? value.title : '',
                        visible: value.visible ? value.visible : 'true',
                      };
                      setWeek(index);
                      setNew(false);
                      setSelectedItem2(datatorender);
                    }}
                    active={week === index}
                    title={value.title}
                    visible={value.visible ? value.visible : 'true'}
                  />
                );
              })}
          </div>
        )}
      </div>
      {((newCreate && type === 'first') ||
        (Object.keys(selectedItem).length > 0 && Object.keys(selectedItem2).length === 0)) && (
        <div className="mx-12">
          <TextField type="text" label="Deeplink" name="deeplink" value={deeplink} disabled />
          <TextField
            type="text"
            label="Title"
            name="title"
            value={selectedItem.title}
            onChange={(event) => {
              var selectedItemNew = { ...selectedItem };
              selectedItemNew.title = event.target.value;
              setSelectedItem(selectedItemNew);
            }}
          />
          <TextField
            type="text"
            label="Order"
            name="order"
            value={selectedItem.order}
            onChange={(event) => {
              var selectedItemNew = { ...selectedItem };
              selectedItemNew.order = event.target.value;
              setSelectedItem(selectedItemNew);
            }}
          />
          <Checkbox
            name="Visibility"
            selected={selectedItem.visible}
            getValue={(data) => {
              var selectedItemNew = { ...selectedItem };
              selectedItemNew.visible = data;
              setSelectedItem(selectedItemNew);
            }}
          />
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            {!newCreate && (
              <CustomButton red type="submit" className="w-1/5" onClick={(e) => deleteCategory(e)}>
                Delete
              </CustomButton>
            )}
            <CustomButton
              type="submit"
              className="w-1/5"
              onClick={(e) => {
                if (newCreate) {
                  createCategory(e);
                } else {
                  save(e);
                }
              }}
            >
              Save
            </CustomButton>
          </div>
        </div>
      )}
      {((newCreate && type === 'second') ||
        (Object.keys(selectedItem2).length > 0 && !newCreate)) && (
        <div className="mx-12">
          <TextField type="text" label="Id" name="id" value={selectedItem2.id} disabled />
          <TextField
            type="text"
            label="Title"
            name="title"
            value={selectedItem2.title}
            onChange={(event) => {
              var selectedItemNew = { ...selectedItem2 };
              selectedItemNew.title = event.target.value;
              setSelectedItem2(selectedItemNew);
            }}
          />
          <Checkbox
            name="Visibility"
            selected={selectedItem2.visible}
            getValue={(data) => {
              var selectedItemNew = { ...selectedItem2 };
              selectedItemNew.visible = data;
              setSelectedItem2(selectedItemNew);
            }}
          />
          <div className="flex flex-row">
            <div className="w-1/3">Level</div>
            <div className="w-2/3 flex flex-row items-center justify-start">
              <div
                onClick={() => {
                  var selectedItemNew = { ...selectedItem2 };
                  selectedItemNew.level = 'red';
                  setSelectedItem2(selectedItemNew);
                }}
                style={{
                  width: 25,
                  height: 25,
                  margin: 10,
                  borderRadius: 25,
                  border: '1px solid red',
                  backgroundColor: selectedItem2.level === 'red' ? 'red' : 'transparent',
                }}
              />
              <div
                onClick={() => {
                  var selectedItemNew = { ...selectedItem2 };
                  selectedItemNew.level = 'yellow';
                  setSelectedItem2(selectedItemNew);
                }}
                style={{
                  width: 25,
                  height: 25,
                  margin: 10,
                  borderRadius: 25,
                  border: '1px solid yellow',
                  backgroundColor: selectedItem2.level === 'yellow' ? 'yellow' : 'transparent',
                }}
              />
              <div
                onClick={() => {
                  var selectedItemNew = { ...selectedItem2 };
                  selectedItemNew.level = 'green';
                  setSelectedItem2(selectedItemNew);
                }}
                style={{
                  width: 25,
                  height: 25,
                  margin: 10,
                  borderRadius: 25,
                  border: '1px solid green',
                  backgroundColor: selectedItem2.level === 'green' ? 'green' : 'transparent',
                }}
              />
            </div>
          </div>

          <TextArea
            type="text"
            label="Summary"
            name="summary"
            value={selectedItem2.summary}
            onChange={(event) => {
              var selectedItemNew = { ...selectedItem2 };
              selectedItemNew.summary = event.target.value;
              setSelectedItem2(selectedItemNew);
            }}
          />
          {newCreate && (
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <CustomButton
                type="submit"
                className="w-1/4"
                onClick={() => {
                  setNew(false);
                  setType('');
                  setSelectedItem2({});
                }}
              >
                Cancel
              </CustomButton>
              <div style={{ width: 20, height: 1 }} />
              <CustomButton
                type="submit"
                className="w-1/4"
                onClick={(e) => type === 'second' && createArticle(e)}
              >
                Create
              </CustomButton>
            </div>
          )}
          {!newCreate && (
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <CustomButton red type="submit" className="w-1/5" onClick={(e) => deleteArticle(e)}>
                Delete
              </CustomButton>
              <div style={{ width: 20, height: 1 }} />
              <CustomButton type="submit" className="w-1/5" onClick={(e) => save2(e)}>
                Save
              </CustomButton>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default FoodSafety;
