import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { subDays } from 'date-fns';
import { formatDate } from '../../components/analytics/helper';
import {
  getCompanyAnalytics,
  getCompanyList,
  createNewCompany,
  setCompanyLoadedFalse,
} from '../../redux/actions/analytics';
import Layout from '../../components/layout/Layout';
import Dropdown from '../../components/Dropdown';
import DateSelector from '../../components/DateSelector';
import TextField from '../../components/TextField';
import CustomButton from '../../components/CustomButton';
import Spinner from '../../components/Spinner';
import CompanyAnalyticsTables from '../../components/analytics/CompanyAnalyticsTables';
import { openDeleteModal } from '../../redux/actions/state';

const Company = () => {
  const dispatch = useDispatch();
  const companyList = useSelector((state) => state.analytics.companylist);
  const companyLoading = useSelector((state) => state.analytics.company_loading);
  const companyLoaded = useSelector((state) => state.analytics.company_loaded);
  const [newCompany, setNewCompany] = useState('');
  const [company, setCompany] = useState({});
  const [companyName, setCompanyName] = useState('');
  const [startDate, setStartDate] = useState(subDays(new Date(), 30));
  const [endDate, setEndDate] = useState(new Date());
  const [product, setProduct] = useState('');
  const [isCompanyListFetched, setIsCompanyListFetched] = useState(false);

  useEffect(() => {
    if (companyList.length === 0 && !isCompanyListFetched) {
      dispatch(getCompanyList());
      setIsCompanyListFetched(true);
    }
  }, [companyList, isCompanyListFetched, dispatch]);

  const deleteItem = () => {
    const selected = company;
    const url = `/company/${company.id}`;
    const type = 'company';
    const title = selected.label;
    dispatch(openDeleteModal({ selected, url, type, title }));
    setCompany({});
    setCompanyName('');
  };

  return (
    <Layout active="companyanalytics">
      <div className="flex flex-col items-center justify-center mx-20">
        <div className="w-full flex flex-row items-center justify-center my-2">
          Company Analytics
        </div>
        <div className="w-1/2 mt-8">
          <div className={'flex items-center'} style={{ gap: '10px' }}>
            <Dropdown
              value={company.label}
              placeholder={'Select company...'}
              className={'w-1/2'}
              options={companyList}
              onChange={(value) => {
                setCompany(value);
                setCompanyName(value.label);
                dispatch(setCompanyLoadedFalse());
              }}
            />

            {companyName && (
              <CustomButton red type="submit" className="w-1/2 mx-auto block" onClick={deleteItem}>
                Delete {companyName}
              </CustomButton>
            )}
          </div>

          <div className={'flex'} style={{ gap: '10px' }}>
            <div className={'w-1/2'}>
              <TextField
                type="text"
                label="New company name"
                name="company"
                value={newCompany}
                onChange={(e) => setNewCompany(e.target.value)}
              />
            </div>

            <CustomButton
              type="submit"
              className="w-1/2 mx-auto block"
              onClick={async () => {
                await dispatch(createNewCompany(newCompany));
                setNewCompany('');
              }}
            >
              Create New Company
            </CustomButton>
          </div>

          <DateSelector
            startDate={startDate}
            endDate={endDate}
            startDateChanged={(date) => setStartDate(date)}
            endDateChanged={(date) => setEndDate(date)}
          />
          <TextField
            type="text"
            label="Product"
            name="product"
            value={product}
            onChange={(e) => setProduct(e.target.value)}
          />
          <CustomButton
            type="submit"
            className="w-1/2 mx-auto block"
            onClick={() => {
              if (companyName === '') {
                return alert('First select a company!');
              }

              dispatch(
                getCompanyAnalytics(
                  formatDate(startDate),
                  formatDate(endDate),
                  companyName,
                  product
                )
              );
            }}
          >
            Search
          </CustomButton>
        </div>
        {companyLoading && <Spinner />}
        {!companyLoading && companyLoaded && (
          <CompanyAnalyticsTables
            startDate={startDate}
            endDate={endDate}
            companyName={companyName}
            product={product}
          />
        )}
      </div>
    </Layout>
  );
};

export default Company;
