import axios from 'axios';
import { apiUrl, master_key } from '../../config/settings';
import { getCompanyList } from './analytics';
import { getFoodsafeties, getPhotographers } from './tools';
import { unauthorizedError, updatedData } from './state';

export const api = axios.create({
  baseURL: apiUrl,
  headers: {
    Authorization: `Bearer ${master_key}`,
  },
  timeout: 10000,
});

export const update = (selectedItem, url) => (dispatch, getState) => {
  api
    .put(url, {
      ...selectedItem,
    })
    .then((response) => {
      dispatch({ type: 'UPDATE_' + url });
      alert('Updated');
      dispatch(getAllData());
      dispatch(getPopups());
      dispatch(getPhotographers());
      dispatch(getFoodsafeties());
    })
    .catch(function (error) {
      alert('Update ERROR');
      dispatch({ type: 'UPDATE_' + url + '_ERROR' });
      if (error.response) {
        if (error.response.status === 401) {
          dispatch(unauthorizedError());
        }
      }
    });
};
export const create = (selectedItem, url) => (dispatch, getState) => {
  api
    .post(url, {
      ...selectedItem,
    })
    .then((response) => {
      dispatch({ type: 'UPDATE_' + url });
      alert('Updated');
      dispatch(getAllData());
      dispatch(getPopups());
    })
    .catch(function (error) {
      alert('Update ERROR');
      dispatch({ type: 'UPDATE_' + url + '_ERROR' });
      if (error.response) {
        if (error.response.status === 401) {
          dispatch(unauthorizedError());
        }
      }
    });
};

export const sendNotif =
  (title, body, from, to, selectedItem, url, users, articleID, categoryID, link) =>
  async (dispatch, getState) => {
    let segment = [];
    if (!(from === 0 && to === 0)) {
      users.forEach((x) => {
        if (x.pregdata.currentWeek >= from && x.pregdata.currentWeek <= to && x.notifId) {
          segment.push(x.notifId);
        }
      });
    } else {
      segment.push('All');
    }
    let _ = {};
    if (segment[0] === 'All') {
      _ = {
        app_id: 'c0298c8a-b3f0-4a2a-96d2-5dc6b38ba450',
        contents: { en: body },
        headings: { en: title },
        data: { articleId: articleID, categoryId: categoryID, url: link },
        included_segments: segment,
      };
    } else {
      _ = {
        app_id: 'c0298c8a-b3f0-4a2a-96d2-5dc6b38ba450',
        contents: { en: body },
        headings: { en: title },
        data: { articleId: articleID, categoryId: categoryID, url: link },
        include_player_ids: segment,
      };
    }

    axios
      .post('https://onesignal.com/api/v1/notifications', _, {
        headers: {
          Authorization: 'Basic NTc0OGNmNTAtZmEzOS00ZWEzLTgyNDktMzI4OWJhMTJmNjEw',
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        if (segment[0] !== 'All') alert('Sent to ' + segment.length + ' device');
        alert('Success');
      })
      .catch(function (error) {
        alert('ERROR');
      });
  };

export const remove = (selectedItem, url) => (dispatch, getState) => {
  api
    .delete(url)
    .then((response) => {
      dispatch({ type: 'UPDATE_' + url });
      alert('Updated');
      dispatch(getAllData());
      dispatch(getCompanyList());
      dispatch(getPopups());
      dispatch(getPhotographers());
      dispatch(getFoodsafeties());
    })
    .catch(function (error) {
      alert('Update ERROR');
      dispatch({ type: 'UPDATE_' + url + '_ERROR' });
      if (error.response) {
        if (error.response.status === 401) {
          dispatch(unauthorizedError());
        }
      }
    });
};

export const getAllData = () => (dispatch, getState) => {
  return api
    .get('/alldatawithoutcache')
    .then((response) => {
      dispatch(getScrolls());
      dispatch({ type: 'GET_ALL_DATA', data: response.data });
      dispatch(updatedData());
      return response.data;
    })
    .catch(function (error) {
      dispatch({ type: 'GET_ALL_DATA_ERROR' });
      if (error.response) {
        if (error.response.status === 401) {
          dispatch(unauthorizedError());
        }
      }
    });
};

export const refreshCache = () => (dispatch, getState) => {
  return api
    .get('/alldata/refresh')
    .then((response) => {
      dispatch({ type: 'REFRESH_CACHE', data: response.data });
      alert('Successfully refreshed all data to all users.');
      return response.data;
    })
    .catch(function (error) {
      if (error.response) {
        if (error.response.status === 401) {
          dispatch(unauthorizedError());
        }
      }
    });
};

export const sendNotification =
  (from, to, body, title, link, isTestRun = true) =>
  (dispatch) => {
    api
      .post('/sendnotification', {
        from: from,
        to: to,
        body: body,
        title: title,
        link: link,
        isTestRun: isTestRun,
      })
      .then((response) => {
        dispatch({ type: 'SEND_NOTIFICATION' });
        alert(response.data.message);
      })
      .catch(function (error) {
        dispatch({ type: 'SEND_NOTIFICATION_ERROR' });
      });
  };

//****************************************************USERS ************************************** */

export const getAnalyticsUserCount = () => (dispatch, getState) => {
  dispatch({ type: 'SET_LOADING_TRUE' });
  return api
    .get('/analyticsusers')
    .then((response) => {
      dispatch({ type: 'GET_USER_COUNT', data: response.data });
      dispatch({ type: 'SET_LOADING_FALSE' });
    })
    .catch(function (error) {
      dispatch({ type: 'SET_LOADING_FALSE' });
      alert(error);
      if (error.response) {
        if (error.response.status === 401) {
          dispatch(unauthorizedError());
        }
      }
    });
};

export const getAnalyticsUserWeeks = () => (dispatch, getState) => {
  dispatch({ type: 'SET_LOADING_TRUE' });
  return api
    .get('/analyticsuserweeks')
    .then((response) => {
      dispatch({ type: 'GET_USER_WEEK_COUNT', data: response.data });
      dispatch({ type: 'SET_LOADING_FALSE' });
    })
    .catch(function (error) {
      dispatch({ type: 'SET_LOADING_FALSE' });
      alert(error);
      if (error.response) {
        if (error.response.status === 401) {
          dispatch(unauthorizedError());
        }
      }
    });
};

export const getUserEmail = (email) => (dispatch, getState) => {
  dispatch({ type: 'GET_USER_EMAIL_ERROR_DELETE' });
  api
    .get(`/users/email/${email}`, {
      headers: { Authorization: 'Bearer ' + getState().state.token },
    })
    .then((response) => {
      dispatch({ type: 'GET_USER_EMAIL', data: response.data });
    })
    .catch(function (error) {
      dispatch({ type: 'GET_USER_EMAIL_ERROR' });
      if (error.response) {
        if (error.response.status === 401) {
          dispatch(unauthorizedError());
        }
      }
    });
};

export const getUsers_ =
  (isNoti, title, body, from, to, selectedItem, url, users, articleID, categoryID, link) =>
  (dispatch, getState) => {
    let i = -1000;
    let users = [];
    var interval = window.setInterval(() => {
      i = i + 1000;
      return api
        .get(`/users?skip=${i}`, {
          headers: {
            'Cache-Control': 'no-cache',
            Authorization: 'Bearer ' + getState().state.token,
          },
        })
        .then((response) => {
          users.push(...response.data);

          if (response.data.length === 0) {
            clearInterval(interval);
            dispatch({ type: 'GET_USERS', data: users });
            if (isNoti) {
              dispatch(
                sendNotif(
                  title,
                  body,
                  from,
                  to,
                  selectedItem,
                  url,
                  users,
                  articleID,
                  categoryID,
                  link
                )
              );
            } else {
              dispatch(getUsers(users));
            }
          }
        })
        .catch(function (error) {
          dispatch({ type: 'GET_USERS_ERROR', data: 'error event' });
          if (error.response) {
            if (error.response.status === 401) {
              dispatch(unauthorizedError());
            }
          }
          return [];
        });
    }, 2500);
  };
export const getUsers = (response) => (dispatch, getState) => {
  let weeks = [
    {
      week: 0,
      people: 0,
      percentage: 0,
    },
    {
      week: 1,
      people: 0,
      percentage: 0,
    },
    {
      week: 2,
      people: 0,
      percentage: 0,
    },
    {
      week: 3,
      people: 0,
      percentage: 0,
    },
    {
      week: 4,
      people: 0,
      percentage: 0,
    },
    {
      week: 5,
      people: 0,
      percentage: 0,
    },
    {
      week: 6,
      people: 0,
      percentage: 0,
    },
    {
      week: 7,
      people: 0,
      percentage: 0,
    },
    {
      week: 8,
      people: 0,
      percentage: 0,
    },
    {
      week: 9,
      people: 0,
      percentage: 0,
    },
    {
      week: 10,
      people: 0,
      percentage: 0,
    },
    {
      week: 11,
      people: 0,
      percentage: 0,
    },
    {
      week: 12,
      people: 0,
      percentage: 0,
    },
    {
      week: 13,
      people: 0,
      percentage: 0,
    },
    {
      week: 14,
      people: 0,
      percentage: 0,
    },
    {
      week: 15,
      people: 0,
      percentage: 0,
    },
    {
      week: 16,
      people: 0,
      percentage: 0,
    },
    {
      week: 17,
      people: 0,
      percentage: 0,
    },
    {
      week: 18,
      people: 0,
      percentage: 0,
    },
    {
      week: 19,
      people: 0,
      percentage: 0,
    },
    {
      week: 20,
      people: 0,
      percentage: 0,
    },
    {
      week: 21,
      people: 0,
      percentage: 0,
    },
    {
      week: 22,
      people: 0,
      percentage: 0,
    },
    {
      week: 23,
      people: 0,
      percentage: 0,
    },
    {
      week: 24,
      people: 0,
      percentage: 0,
    },
    {
      week: 25,
      people: 0,
      percentage: 0,
    },
    {
      week: 26,
      people: 0,
      percentage: 0,
    },
    {
      week: 27,
      people: 0,
      percentage: 0,
    },
    {
      week: 28,
      people: 0,
      percentage: 0,
    },
    {
      week: 29,
      people: 0,
      percentage: 0,
    },
    {
      week: 30,
      people: 0,
      percentage: 0,
    },
    {
      week: 31,
      people: 0,
      percentage: 0,
    },
    {
      week: 32,
      people: 0,
      percentage: 0,
    },
    {
      week: 33,
      people: 0,
      percentage: 0,
    },
    {
      week: 34,
      people: 0,
      percentage: 0,
    },
    {
      week: 35,
      people: 0,
      percentage: 0,
    },
    {
      week: 36,
      people: 0,
      percentage: 0,
    },
    {
      week: 37,
      people: 0,
      percentage: 0,
    },
    {
      week: 38,
      people: 0,
      percentage: 0,
    },
    {
      week: 39,
      people: 0,
      percentage: 0,
    },
    {
      week: 40,
      people: 0,
      percentage: 0,
    },
  ];
  response.forEach((e) => {
    if (e.pregdata.currentWeek) {
      weeks.forEach((x) => {
        if (e.pregdata.currentWeek === x.week) {
          x.people = x.people + 1;
        }
      });
    }
  });
  let allRealPeople = 0;
  weeks.forEach((e) => {
    allRealPeople += e.people;
  });
  const finishedWeeks = weeks.map((e) => {
    return {
      week: e.week,
      people: e.people,
      percentage: Math.round((100 / allRealPeople) * e.people * 100) / 100,
    };
  });

  dispatch({ type: 'GET_USERS', data: response.data });
  dispatch({
    type: 'GET_PREG_USERS_ANALYTICS',
    data: finishedWeeks,
    real: allRealPeople,
  });
};

export const deleteUser = (id) => (dispatch, getState) => {
  api
    .delete(`/users/${id}`, {
      headers: { Authorization: 'Bearer ' + getState().state.token },
    })
    .then((response) => {
      dispatch({ type: 'DELETE_USER' });
      alert('User deleted');
      //dispatch(getUsers());
    })
    .catch(function (error) {
      dispatch({ type: 'DELETE_USER_ERROR' });
      if (error.response) {
        if (error.response.status === 401) {
          dispatch(unauthorizedError());
        }
      }
    });
};

/*******************************************************************      POPUP     ****************************************************************/
export const getPopups = () => (dispatch, getState) => {
  return api
    .get('/popups-new?sort=-createdAt')
    .then((response) => {
      dispatch({ type: 'GET_POPUPS', data: response.data });
      return response.data;
    })
    .catch(function (error) {
      dispatch({ type: 'GET_POPUPS_ERROR' });
      if (error.response) {
        if (error.response.status === 401) {
          dispatch(unauthorizedError());
        }
      }
    });
};

export const createPopup = (selectedItem) => (dispatch) => {
  api
    .post('/popups-new', selectedItem)
    .then((response) => {
      dispatch(getPopups());
      alert('Created popup!');
    })
    .catch(function (error) {
      if (error.response) {
        if (error.response.status === 401) {
          dispatch(unauthorizedError());
        }

        if (error.response.data.error) {
          alert(Object.values(error.response.data.error).join(', '));
        }
      }
    });
};

export const updatePopup = (id, selectedItem) => (dispatch) => {
  api
    .put(`/popups-new/${id}`, selectedItem)
    .then((response) => {
      dispatch(getPopups());
      alert('Popup updated!');
    })
    .catch(function (error) {
      if (error.response) {
        if (error.response.status === 401) {
          dispatch(unauthorizedError());
        }

        if (error.response.data.error) {
          alert(Object.values(error.response.data.error).join(', '));
        }
      }
    });
};

export const duplicatePopup = (id, body) => (dispatch) => {
  return api
    .post(`/popups-new/duplicate/${id}`, body)
    .then(() => dispatch(getPopups()))
    .then((response) => {
      alert('Popup duplicated!');
      return response.data;
    })
    .catch(function (error) {
      if (error.response) {
        if (error.response.status === 401) {
          dispatch(unauthorizedError());
        }
      }
    });
};

export const deletePopup = (id) => (dispatch) => {
  api
    .delete(`/popups-new/${id}`)
    .then((response) => {
      dispatch(getPopups());
      alert('Popup deleted!');
    })
    .catch(function (error) {
      if (error.response) {
        if (error.response.status === 401) {
          dispatch(unauthorizedError());
        }
      }
    });
};

export const getScrolls = () => (dispatch, getState) => {
  return api
    .get('/scrollweeks')
    .then((response) => {
      dispatch({ type: 'GET_SCROLLS', data: response.data });
      return response.data;
    })
    .catch(function (error) {
      dispatch({ type: 'GET_SCROLLS_ERROR' });
      if (error.response) {
        if (error.response.status === 401) {
          dispatch(unauthorizedError());
        }
      }
    });
};

export const createScrolls =
  (
    week,
    scrollweek,
    type,
    boxtype,
    order,
    categorytitle,
    headline,
    text,
    img,
    link,
    tool,
    articleId,
    categoryId,
    dealId
  ) =>
  (dispatch) => {
    api
      .post('/scrollitems', {
        week: week,
        scrollweek: scrollweek,
        type: type,
        boxtype: boxtype,
        order: order,
        categorytitle: categorytitle,
        headline: headline,
        text: text,
        img: img,
        link: link,
        tool: tool,
        articleId: articleId,
        categoryId: categoryId,
        dealId: dealId,
      })
      .then((response) => {
        dispatch(getScrolls());
        alert('Created scroll!');
      })
      .catch(function (error) {
        if (error.response) {
          if (error.response.status === 401) {
            dispatch(unauthorizedError());
          }
        }
      });
  };

export const updateScrolls =
  (
    id,
    week,
    scrollweek,
    type,
    boxtype,
    order,
    categorytitle,
    headline,
    text,
    img,
    link,
    tool,
    articleId,
    categoryId,
    dealId
  ) =>
  (dispatch) => {
    api
      .put(`/scrollitems/${id}`, {
        week: week,
        scrollweek: scrollweek,
        type: type,
        boxtype: boxtype,
        order: order,
        categorytitle: categorytitle,
        headline: headline,
        text: text,
        img: img,
        link: link,
        tool: tool,
        articleId: articleId,
        categoryId: categoryId,
        dealId: dealId,
      })
      .then((response) => {
        dispatch(getScrolls());
        alert('Scrolls updated!');
      })
      .catch(function (error) {
        if (error.response) {
          if (error.response.status === 401) {
            dispatch(unauthorizedError());
          }
        }
      });
  };

export const deleteScrolls = (id) => (dispatch) => {
  api
    .delete(`/scrollitems/${id}`)
    .then((response) => {
      dispatch(getScrolls());
      alert('Scroll deleted!');
    })
    .catch(function (error) {
      if (error.response) {
        if (error.response.status === 401) {
          dispatch(unauthorizedError());
        }
      }
    });
};

export const getScrollsAnalytics = () => (dispatch, getState) => {
  dispatch({ type: 'SET_LOADING_TRUE' });
  return api
    .get('/analyticsscrolls')
    .then((response) => {
      dispatch({ type: 'GET_SCROLLS_ANALYTICS', data: response.data });
      dispatch({ type: 'SET_LOADING_FALSE' });
    })
    .catch(function (error) {
      dispatch({ type: 'GET_SCROLLS_ANALYTIC_ERROR' });
      dispatch({ type: 'SET_LOADING_FALSE' });
      alert(error);
      if (error.response) {
        if (error.response.status === 401) {
          dispatch(unauthorizedError());
        }
      }
    });
};
