import { subDays } from 'date-fns';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getPregnancyWeekHistory } from '../../../redux/actions/analytics';
import CustomButton from '../../CustomButton';
import DateSelector from '../../DateSelector';
import Spinner from '../../Spinner';
import { formatDate } from '../helper';

const PregnancyWeekHistory = () => {
  const dispatch = useDispatch();
  const historyLoading = useSelector((state) => state.analytics.pregnancyWeekHistory_loading);
  const history = useSelector((state) => state.analytics.pregnancyWeekHistory);

  const [startDate, setStartDate] = useState(subDays(new Date(), 30));
  const [endDate, setEndDate] = useState(new Date());

  const search = () => {
    dispatch(getPregnancyWeekHistory(formatDate(startDate), formatDate(endDate)));
  };

  return (
    <div className="w-full flex flex-col items-center">
      <DateSelector
        startDate={startDate}
        endDate={endDate}
        startDateChanged={(date) => setStartDate(date)}
        endDateChanged={(date) => setEndDate(date)}
      />

      <div className="flex items-center justify-center w-full">
        <CustomButton type="button" className="text-white" onClick={search}>
          Search
        </CustomButton>
      </div>

      <div className="mx-12 mt-4">
        {historyLoading && <Spinner />}
        {!historyLoading &&
          history.length !== 0 &&
          history.days.map((day) => {
            return (
              <div key={day.date}>
                <p className="text-center">{day.date}</p>

                <table>
                  <thead>
                    <tr>
                      <th>Pregnancy week</th>
                      <th>Number of users</th>
                    </tr>
                  </thead>
                  <tbody>
                    {day.weeks.map((numberOfUsers, index) => {
                      return (
                        <tr
                          key={`${day.date}-${index}`}
                          style={{ display: numberOfUsers > 0 ? 'table-row' : 'none' }}
                        >
                          <td>{index}</td>
                          <td>{numberOfUsers}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default PregnancyWeekHistory;
