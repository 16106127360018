import React, { useState, useEffect } from 'react';
import { CSVLink } from 'react-csv';
import { useSelector, useDispatch } from 'react-redux';
import { getScreenArticle, getScreenKnowledge } from '../../../redux/actions/analytics';
import { getAllData } from '../../../redux/actions/api';
import CustomButton from '../../CustomButton';
import { formatDate } from '../helper';
import Dropdown from '../../Dropdown';
import Spinner from '../../Spinner';

const KnowledgeScreen = ({ startDate, endDate }) => {
  const dispatch = useDispatch();
  const knowledgeLoading = useSelector((state) => state.analytics.screenknowledge_loading);
  const knowledge = useSelector((state) => state.analytics.screenknowledge);
  const knowledgeData = useSelector((state) => state.api.data.knowledge);

  const articleLoading = useSelector((state) => state.analytics.screenarticle_loading);
  const article = useSelector((state) => state.analytics.screenarticle);

  const [selectedCategory, setSelectedCategory] = useState('');
  const [selectedCategoryTitle, setSelectedCategoryTitle] = useState('');
  const [isFormChanged, setIsFormChanged] = useState(false);
  const [articleFilteredArray, setArticleFilteredArray] = useState([]);

  const excludedCategories = ['Receptek', 'Videótár'];

  const KnowledgeCategoryOptions = () => {
    return [
      { value: '', label: 'All' },
      ...knowledgeData
        .filter(
          (category) =>
            (category.visible || category.visible === 'true') &&
            !excludedCategories.includes(category.title)
        )
        .map((category) => {
          return {
            value: category.title,
            label: category.title,
          };
        })
        .sort((a, z) => a.label.localeCompare(z.label)),
    ];
  };

  useEffect(() => {
    setIsFormChanged(true);
  }, [selectedCategory]);

  useEffect(() => {
    if (!knowledgeData) {
      dispatch(getAllData());
    }

    if (startDate !== '' && endDate !== '') {
      dispatch(getScreenKnowledge(formatDate(startDate), formatDate(endDate)));
      setIsFormChanged(false);
    }
  }, [dispatch, knowledgeData, startDate, endDate]);

  useEffect(() => {
    if (article?.articles?.length > 0) {
      setArticleFilteredArray(article);
    } else {
      setArticleFilteredArray([]);
    }
  }, [article]);

  const search = () => {
    if (selectedCategory) {
      dispatch(getScreenArticle(formatDate(startDate), formatDate(endDate), selectedCategory));
    } else {
      dispatch(getScreenKnowledge(formatDate(startDate), formatDate(endDate)));
    }

    setIsFormChanged(false);
  };

  const headers = [
    { label: 'Category', key: 'screen' },
    { label: 'Views', key: 'appeared' },
    { label: 'Percentage (views / all views)', key: 'percentage' },
    { label: 'Unique', key: 'unique' },
  ];

  const articleHeaders = [
    { label: 'Article', key: 'article' },
    { label: 'Views', key: 'pressed' },
    { label: 'Percentage (views / all views)', key: 'percentage' },
    { label: 'Unique', key: 'unique' },
  ];

  return (
    <div className="w-full flex flex-col items-center">
      <div className="w-1/2 mt-8">
        <Dropdown
          value={selectedCategory}
          options={KnowledgeCategoryOptions()}
          onChange={(value) => {
            setSelectedCategory(value.value);
            setSelectedCategoryTitle(value.label);
          }}
        />
      </div>
      <div>
        {knowledgeLoading && <Spinner />}
        {!knowledgeLoading && selectedCategory === '' && (
          <div>
            <div className="my-8 flex items-center justify-center w-full">
              <CustomButton type="button" className="text-white" onClick={search}>
                Search
              </CustomButton>

              {knowledge.length !== 0 ? (
                <CSVLink
                  className="bg-orange text-white p-2 rounded"
                  filename={`Knowledge-${formatDate(startDate)}-${formatDate(endDate)}.csv`}
                  data={knowledge.screens}
                  headers={headers}
                >
                  Download this table
                </CSVLink>
              ) : null}
            </div>

            {knowledge.length !== 0 && !isFormChanged && (
              <>
                <p className={'text-center'}>
                  Displayed period:{' '}
                  <strong>
                    {knowledge.from} - {knowledge.to}
                  </strong>
                </p>

                <table>
                  <thead>
                    <tr>
                      <th>Category</th>
                      <th>Views</th>
                      <th>Percentage (views / all views)</th>
                      <th>Unique</th>
                    </tr>
                  </thead>
                  <tbody>
                    {knowledge.screens.map((item, index) => {
                      // filter data out if something selected
                      return (
                        <tr key={index}>
                          <td>{item.screen}</td>
                          <td>{item.appeared}</td>
                          <td>{((item.appeared / knowledge.totalAppeared) * 100).toFixed(2)}%</td>
                          <td>{item.unique}</td>
                        </tr>
                      );
                    })}
                    <tr className="font-bold">
                      <td>Total for knowledge categories</td>
                      <td>{knowledge.totalAppeared}</td>
                      <td />
                      <td>{knowledge.uniq}</td>
                    </tr>
                  </tbody>
                </table>
              </>
            )}
          </div>
        )}
        {articleLoading && <Spinner />}
        {!articleLoading && selectedCategory !== '' && (
          <div>
            <div className="my-8 flex items-center justify-center w-full">
              <CustomButton type="button" className="text-white" onClick={search}>
                Search
              </CustomButton>

              {articleFilteredArray.length !== 0 && !isFormChanged ? (
                <CSVLink
                  className="bg-orange text-white p-2 rounded"
                  filename={`Knowledge-${selectedCategory}-${formatDate(startDate)}-${formatDate(
                    endDate
                  )}.csv`}
                  data={articleFilteredArray.articles}
                  headers={articleHeaders}
                >
                  Download this table
                </CSVLink>
              ) : null}
            </div>

            {articleFilteredArray.length !== 0 && !isFormChanged && (
              <>
                <p className={'text-center'}>
                  Displayed period:{' '}
                  <strong>
                    {articleFilteredArray.from} - {articleFilteredArray.to}
                  </strong>
                </p>

                <table>
                  <thead>
                    <tr>
                      <th>Article</th>
                      <th>Views</th>
                      <th>Percentage (views / all views)</th>
                      <th>Unique</th>
                    </tr>
                  </thead>
                  <tbody>
                    {articleFilteredArray.articles.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td>{item.article}</td>
                          <td>{item.pressed}</td>
                          <td>{((item.pressed / articleFilteredArray.total) * 100).toFixed(2)}%</td>
                          <td>{item.unique}</td>
                        </tr>
                      );
                    })}
                    <tr className="font-bold">
                      <td>Total for {selectedCategoryTitle}</td>
                      <td>{articleFilteredArray.total}</td>
                      <td />
                      <td>{articleFilteredArray.uniq}</td>
                    </tr>
                  </tbody>
                </table>
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default KnowledgeScreen;
