import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getUserWeeks } from '../../../redux/actions/analytics';
import { formatDate } from '../helper';
import Spinner from '../../Spinner';
import { CSVLink } from 'react-csv';

const UserWeeks = () => {
  const dispatch = useDispatch();
  const weeksLoading = useSelector((state) => state.analytics.userweeks_loading);
  const userWeeks = useSelector((state) => state.analytics.userweeks);

  const totalUsers = userWeeks?.reduce((sum, week) => (sum += week.users), 0) || 0;
  const totalFinishedPregnancies =
    userWeeks?.reduce((sum, week) => (sum += week.finishedPregnancies), 0) || 0;

  useEffect(() => {
    dispatch(getUserWeeks());
  }, [dispatch]);

  const sortOrder = [
    '0',
    '1',
    '2',
    '3',
    '4',
    '5',
    '6',
    '7',
    '8',
    '9',
    '10',
    '11',
    '12',
    '13',
    '14',
    '15',
    '16',
    '17',
    '18',
    '19',
    '20',
    '21',
    '22',
    '23',
    '24',
    '25',
    '26',
    '27',
    '28',
    '29',
    '30',
    '31',
    '32',
    '33',
    '34',
    '35',
    '36',
    '37',
    '38',
    '39',
    '40',
    '41',
    '42',
    '43',
    '44',
    '45',
    '45+',
  ];

  const headers = [
    { label: 'Week', key: 'week' },
    { label: 'Number of users', key: 'users' },
    { label: 'Birth', key: 'finishedPregnancies' },
    { label: 'Period', key: 'period' },
  ];

  return (
    <div className="w-full flex flex-col items-center">
      <div className="mx-12">
        {weeksLoading && <Spinner />}
        {!weeksLoading && userWeeks.length !== 0 && (
          <div>
            <div className="my-8 flex items-center justify-center w-full">
              <CSVLink
                className="bg-orange text-white p-2 rounded"
                filename={`UserAnalytics-WEEKS-${formatDate(new Date())}.csv`}
                data={userWeeks}
                headers={headers}
              >
                Download this table
              </CSVLink>
            </div>

            <div className="rounded-md bg-lightorange mb-4 p-4">
              <h3 className="text-sm font-medium text-yellow-800">Legend</h3>
              <div className="mt-2 text-sm text-yellow-700">
                <p>
                  <strong>Number of finished pregnancy:</strong> number of users who have used the
                  "Megszületett a kisbabám" feature and marked their pregnancy as completed.
                </p>
              </div>
            </div>

            <table>
              <thead>
                <tr>
                  <th>Week</th>
                  <th>Number of all users</th>
                  <th>Number of finished pregnancy</th>
                </tr>
              </thead>
              <tbody>
                {userWeeks
                  .sort((a, b) => sortOrder.indexOf(a.week) - sortOrder.indexOf(b.week))
                  .map((item, index) => {
                    return (
                      <tr key={index}>
                        <td>{item.week}</td>
                        <td>{item.users}</td>
                        <td>{item.finishedPregnancies}</td>
                      </tr>
                    );
                  })}
                <tr className="font-bold">
                  <td>SUM</td>
                  <td>{totalUsers}</td>
                  <td>{totalFinishedPregnancies}</td>
                </tr>
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  );
};

export default UserWeeks;
