import React from 'react';
import MarkdownIt from 'markdown-it';
import MdEditor from 'react-markdown-editor-lite';

import 'react-markdown-editor-lite/lib/index.css';

const mdParser = new MarkdownIt();

const MarkdownEditor = (props) => {
  if (process.env.REACT_APP_MARKDOWN_DISABLED === 'true') {
    return null;
  }

  return (
    <div className="flex flex-col">
      {props.title}
      <MdEditor className="h-[600px]" {...props} renderHTML={(text) => mdParser.render(text)} />
    </div>
  );
};

export default MarkdownEditor;
