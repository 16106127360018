import React, { PureComponent } from 'react';
import ImageUploader from 'react-images-upload';
import { ref, getDownloadURL, uploadBytesResumable } from 'firebase/storage';
import { v4 as uuidv4 } from 'uuid';
import { storage } from '../lib/firebase';
import CustomButton from './CustomButton';

export default class ImageUp extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      selectedImageUrl: null,
      modal: '',
      imageFile: null,
      imageName: this.props.value || '',
    };
    if (this.props.value) {
      this.showImage(this.props.value);
    } else {
      this.showImage('');
    }
  }

  componentDidUpdate() {
    if (this.props.value) {
      this.showImage(this.props.value);
    } else {
      this.showImage('');
    }
  }

  showImage(img) {
    if (!img) {
      return;
    }

    const hasExtension = img.match(/(jpg|jpeg|png)$/i);
    const image = hasExtension ? img : `${img}.jpg`;
    const storageRef = ref(storage, image);

    getDownloadURL(storageRef)
      .then((res) => {
        this.setState({ selectedImageUrl: res });
      })
      .catch(() => {
        this.setState({ selectedImageUrl: '' });
      });
  }

  onImgSelect = (event) => {
    if (event.length !== 0) {
      const fileToUpload = event[0];
      const fileName = fileToUpload.name;
      const fileExtension = fileName.match(/(jpg|jpeg|png)$/)[0];

      const uuid = uuidv4();
      const newFileName = `${uuid}.${fileExtension}`;
      const storageRef = ref(storage, newFileName);

      getDownloadURL(storageRef)
        .then((res) => {
          alert(
            `This filename (${newFileName}) already exists in the online storage. If you upload it, it will overwrite the existing file. If you don't wish to overwrite anything, please rename your image to something unique and add it again with the 'Choose file' button before uploading.`
          );
        })
        .finally(() => {
          this.setState({ imageFile: fileToUpload, imageName: newFileName });
        });
    }
  };

  onImgUpload = () => {
    let meta = { contentType: this.state.imageFile.type };
    if (!this.state.imageFile) {
      alert('No file is selected. Please select a file before uploading.');
      return;
    }

    const storageRef = ref(storage, this.state.imageName);

    const uploadTask = uploadBytesResumable(storageRef, this.state.imageFile, meta);

    uploadTask.on(
      'state_changed',
      (snap) => {
        this.setState({
          modal: 'Uploading image...',
        });
      },
      (error) => {
        this.setState({
          modal: '',
          imageFile: null,
        });
        alert(`Upload failed. The database returned the following error: ${error}`);
      },
      () => {
        this.setState({
          modal: 'Upload successful!',
        });
        this.props.getImg(this.state.imageName);
        setTimeout(
          () =>
            this.setState(
              {
                modal: '',
                imageFile: null,
              },
              this.showImage(this.state.imageName)
            ),
          2000
        );
      }
    );
  };

  render() {
    return (
      <div className="w-full flex items-center justify-center">
        <div className="w-2/3 flex flex-col items-center">
          <div className="flex flex-row items-center justify-center my-4">
            <div>
              {this.props.title} {' - '}{' '}
            </div>
            <div>{this.props.value}</div>
          </div>
          {this.props.value && (
            <img alt="img" className="w-1/3 h-auto" src={this.state.selectedImageUrl} />
          )}
          <div className="w-full flex flex-row">
            <ImageUploader
              withIcon={false}
              singleImage
              withPreview={this.state.imageFile ? true : false}
              buttonText="Choose an Image"
              onChange={(e, x) => this.onImgSelect(e, x, '')}
              imgExtension={['.jpg', '.jpeg', '.png']}
              label="Max file size: 1MB, accepted: .jpg, .jpeg, .png"
              maxFileSize={1242880}
            />
            {this.props.value && (
              <div className="flex w-1/2 h-auto items-end justify-start mb-10 ml-2">
                <CustomButton
                  red
                  type="submit"
                  className="w-full"
                  onClick={() => this.props.parentCallback(this.props.title)}
                >
                  Delete Img
                </CustomButton>
              </div>
            )}
          </div>
          <p>{this.state.modal}</p>
          {this.state.imageFile && (
            <CustomButton className="w-1/4" onClick={() => this.onImgUpload()}>
              Upload
            </CustomButton>
          )}
        </div>
      </div>
    );
  }
}
