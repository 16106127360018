import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { format, parseISO } from 'date-fns';
import Dropdown from '../../components/Dropdown';
import { getCompanyList } from '../../redux/actions/analytics';
import { update } from '../../redux/actions/api';
import Checkbox from '../../components/Checkbox';
import FormForm from '../../components/forms/FormForm';
import Layout from '../../components/layout/Layout';
import CustomButton from '../../components/CustomButton';
import MenuButton from '../../components/MenuButton';
import TextField from '../../components/TextField';
import Scrolls from '../../components/Scrolls';
import HTMLEditor from '../../components/HTMLEditor';
import ImageUp from '../../components/ImageUp';
import MarkdownEditor from '../../components/MarkdownEditor';

const Home = () => {
  const dispatch = useDispatch();
  const [tab, setTab] = useState(0);
  const [week, setWeek] = useState(0);
  const [loading, setLoading] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});
  const [sizeTexts, setSizeTexts] = useState([]);
  const [weekTexts, setWeekTexts] = useState([]);
  const [htmlContentBaby, setHtmlContentBaby] = useState('');
  const [htmlContentBabyTips, setHtmlContentBabyTips] = useState('');
  const [htmlContentMum, setHtmlContentMum] = useState('');
  const [htmlContentMumTips, setHtmlContentMumTips] = useState('');
  const [htmlContentDad, setHtmlContentDad] = useState('');
  const [htmlContentDadTips, setHtmlContentDadTips] = useState('');
  const [mdContentBaby, setMdContentBaby] = useState('');
  const [mdContentMum, setMdContentMum] = useState('');
  const [mdContentDad, setMdContentDad] = useState('');
  const apidata = useSelector((state) => state.api.data);
  const { role } = useSelector((state) => state?.auth?.user);
  const companyList = useSelector((state) => state.analytics.companylist);
  const isAdmin = role === 'admin';
  const [isCompanyListFetched, setIsCompanyListFetched] = useState(false);

  useEffect(() => {
    // dispatch(getAllData());
    setSelectedItem({});
    if (Object.keys(apidata).length > 0) {
      setSizeTexts(apidata.homescreen[0].sizeTexts);
      setWeekTexts(apidata.homescreen[0].weekTexts);
    }
  }, [apidata]);

  useEffect(() => {
    setSelectedItem({});
    if (Object.keys(apidata).length > 0) {
      setSizeTexts(apidata.homescreen[0].sizeTexts);
      setWeekTexts(apidata.homescreen[0].weekTexts);
    }
  }, [apidata]);

  useEffect(() => {
    const fetchData = async () => {
      await dispatch(getCompanyList());
    };

    if (companyList.length === 0 && !isCompanyListFetched) {
      fetchData().catch(console.error);
      setIsCompanyListFetched(true);
    }
  }, [companyList.length, dispatch, isCompanyListFetched]);

  useEffect(() => {
    if (role) setTab(isAdmin ? 0 : 1);
  }, [role, isAdmin]);

  const save = () => {
    const selected = selectedItem;
    const url = '/homescreen/updatesize/' + selectedItem.fruit;
    dispatch(update(selected, url));
  };

  const saveCalendar = () => {
    const selected = selectedItem;
    selected.baby_text = mdContentBaby;
    selected.mum_text = mdContentMum;
    selected.dad_text = mdContentDad;
    selected.baby_text_html = htmlContentBaby;
    selected.baby_tips_html = htmlContentBabyTips;
    selected.mum_text_html = htmlContentMum;
    selected.mum_tips_html = htmlContentMumTips;
    selected.dad_text_html = htmlContentDad;
    selected.dad_tips_html = htmlContentDadTips;
    const url = '/homescreen/updateweek';
    dispatch(update(selected, url));
  };

  const renderSizeTexts = () => {
    return (
      <div className="mx-12">
        {Object.keys(selectedItem).length > 0 && tab === 0 && (
          <div>
            <h3>
              {tab === 0 ? 'Size text - ' : 'Calendar Text - '}
              {Number(week) === 41 ? '40+ weeks' : week + '.week'}
            </h3>
            <TextField
              type="text"
              label="Size text"
              name="sizeText"
              value={selectedItem.sizeText}
              onChange={(event) => {
                const selectedItemNew = { ...selectedItem };
                selectedItemNew.sizeText = event.target.value;
                setSelectedItem(selectedItemNew);
              }}
            />
            <TextField
              type="text"
              label="Length"
              name="length"
              value={selectedItem.length}
              disabled
            />
            <TextField
              type="text"
              label="Weight"
              name="weight"
              value={selectedItem.weight}
              disabled
            />
            <TextField
              type="text"
              label="Hand Size"
              name="handSize"
              value={selectedItem.handSize}
              disabled
            />
            {selectedItem.imgUrl !== 'logo' && (
              <ImageUp
                parentCallback={() => {
                  const selectedItemNew = { ...selectedItem };
                  selectedItemNew.imgUrl = '';
                  setSelectedItem(selectedItemNew);
                }}
                value={selectedItem.imgUrl}
                getImg={(value) => {
                  const selectedItemNew = { ...selectedItem };
                  selectedItemNew.imgUrl = value;
                  setSelectedItem(selectedItemNew);
                }}
              />
            )}
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <CustomButton type="submit" className="w-1/4" onClick={() => save()}>
                Save
              </CustomButton>
            </div>
          </div>
        )}
      </div>
    );
  };

  const renderWeekTexts = () => {
    return (
      <div className="mx-12">
        {Object.keys(selectedItem).length > 0 && tab === 1 && (
          <div>
            <h3>
              {tab === 0 ? 'Size text - ' : 'Calendar Text - '}
              {Number(week) === 41 ? '40+ weeks' : week + '.week'}
            </h3>
            <TextField type="text" label="Image" name="img" value={selectedItem.img} disabled />
            <TextField
              type="text"
              label="Week"
              name="week_no"
              value={selectedItem.week_no}
              disabled
            />

            <hr className={'mt-4 mb-4'} />
            <h4 style={{ fontSize: '20px', margin: '16px 0' }}>Baby</h4>
            <TextField
              type="text"
              label="Deeplink"
              name="deeplink_baby"
              value={`preghello://home/baby-mum/0/${week}`}
              disabled
            />
            <TextField label="Baby Ad Company">
              <Dropdown
                value={selectedItem.babyAd_company}
                options={[{ value: '', label: 'None' }, ...companyList]}
                onChange={(value) => {
                  const selectedItemNew = { ...selectedItem };
                  selectedItemNew.babyAd_company = value.value;
                  setSelectedItem(selectedItemNew);
                }}
                className={'w-2/3'}
              />
            </TextField>
            <TextField
              type="text"
              label="Baby Ad Product"
              name="babyAd_product"
              value={selectedItem.babyAd_product}
              onChange={(event) => {
                const selectedItemNew = { ...selectedItem };
                selectedItemNew.babyAd_product = event.target.value;
                setSelectedItem(selectedItemNew);
              }}
            />
            <TextField
              type="text"
              label="Baby Ad Link"
              hint={
                'Markdown: link of the banner. / HTML: any inserted link matching this will have the Company and Product fields associated to it.'
              }
              name="babyAd"
              value={selectedItem.babyAd}
              onChange={(event) => {
                const selectedItemNew = { ...selectedItem };
                selectedItemNew.babyAd = event.target.value;
                setSelectedItem(selectedItemNew);
              }}
            />
            <HTMLEditor
              title="Baby Text (html)"
              defaultValue={htmlContentBaby}
              onChange={(content) => {
                setHtmlContentBaby(content);
              }}
            />
            <TextField
              type="text"
              label="Baby Tips Start Date"
              name="baby_tips_start_date"
              placeholder="2023-01-01"
              value={selectedItem.baby_tips_start_date}
              onChange={(event) => {
                const selectedItemNew = { ...selectedItem };
                selectedItemNew.baby_tips_start_date = event.target.value;
                setSelectedItem(selectedItemNew);
              }}
            />
            <TextField
              type="text"
              label="Baby Tips End Date"
              name="baby_tips_end_date"
              placeholder="2023-01-02"
              required
              value={selectedItem.baby_tips_end_date}
              onChange={(event) => {
                const selectedItemNew = { ...selectedItem };
                selectedItemNew.baby_tips_end_date = event.target.value;
                setSelectedItem(selectedItemNew);
              }}
            />
            <HTMLEditor
              title="Baby Tips (html)"
              defaultValue={htmlContentBabyTips}
              onChange={(content) => {
                setHtmlContentBabyTips(content);
              }}
            />
            <MarkdownEditor
              title="Baby Text (markdown)"
              value={mdContentBaby}
              onChange={({ text }) => {
                setMdContentBaby(text);
              }}
            />
            <ImageUp
              parentCallback={() => {
                const selectedItemNew = { ...selectedItem };
                selectedItemNew.babyAd_img = '';
                setSelectedItem(selectedItemNew);
              }}
              value={selectedItem.babyAd_img}
              getImg={(value) => {
                const selectedItemNew = { ...selectedItem };
                selectedItemNew.babyAd_img = value;
                setSelectedItem(selectedItemNew);
              }}
            />

            <hr className={'mt-4 mb-4'} />
            <h4 style={{ fontSize: '20px', margin: '16px 0' }}>Mum</h4>
            <TextField
              type="text"
              label="Deeplink"
              name="deeplink_mum"
              value={`preghello://home/baby-mum/1/${week}`}
              disabled
            />
            <TextField label="Mum Ad Company">
              <Dropdown
                value={selectedItem.mumAd_company}
                options={[{ value: '', label: 'None' }, ...companyList]}
                onChange={(value) => {
                  const selectedItemNew = { ...selectedItem };
                  selectedItemNew.mumAd_company = value.value;
                  setSelectedItem(selectedItemNew);
                }}
                className={'w-2/3'}
              />
            </TextField>
            <TextField
              type="text"
              label="Mum Ad Product"
              name="mumAd_product"
              value={selectedItem.mumAd_product}
              onChange={(event) => {
                const selectedItemNew = { ...selectedItem };
                selectedItemNew.mumAd_product = event.target.value;
                setSelectedItem(selectedItemNew);
              }}
            />
            <TextField
              type="text"
              label="Mum Ad Link"
              hint={
                'Markdown: link of the banner. / HTML: any inserted link matching this will have the Company and Product fields associated to it.'
              }
              name="mumAd"
              value={selectedItem.mumAd}
              onChange={(event) => {
                const selectedItemNew = { ...selectedItem };
                selectedItemNew.mumAd = event.target.value;
                setSelectedItem(selectedItemNew);
              }}
            />
            <HTMLEditor
              title="Mum Text (html)"
              defaultValue={htmlContentMum}
              onChange={(content) => {
                setHtmlContentMum(content);
              }}
            />
            <TextField
              type="text"
              label="Mum Tips Start Date"
              name="mum_tips_start_date"
              placeholder="2023-01-01"
              value={selectedItem.mum_tips_start_date}
              onChange={(event) => {
                const selectedItemNew = { ...selectedItem };
                selectedItemNew.mum_tips_start_date = event.target.value;
                setSelectedItem(selectedItemNew);
              }}
            />
            <TextField
              type="text"
              label="Mum Tips End Date"
              name="mum_tips_end_date"
              placeholder="2023-01-02"
              required
              value={selectedItem.mum_tips_end_date}
              onChange={(event) => {
                const selectedItemNew = { ...selectedItem };
                selectedItemNew.mum_tips_end_date = event.target.value;
                setSelectedItem(selectedItemNew);
              }}
            />
            <HTMLEditor
              title="Mum Tips (html)"
              defaultValue={htmlContentMumTips}
              onChange={(content) => {
                setHtmlContentMumTips(content);
              }}
            />
            <MarkdownEditor
              title="Mum Text (markdown)"
              value={mdContentMum}
              onChange={({ text }) => {
                setMdContentMum(text);
              }}
            />
            <ImageUp
              parentCallback={() => {
                const selectedItemNew = { ...selectedItem };
                selectedItemNew.mumAd_img = '';
                setSelectedItem(selectedItemNew);
              }}
              value={selectedItem.mumAd_img}
              getImg={(value) => {
                const selectedItemNew = { ...selectedItem };
                selectedItemNew.mumAd_img = value;
                setSelectedItem(selectedItemNew);
              }}
            />

            <hr className={'mt-4 mb-4'} />
            <h4 style={{ fontSize: '20px', margin: '16px 0' }}>Dad</h4>
            <TextField
              type="text"
              label="Deeplink"
              name="deeplink_dad"
              value={`preghello://home/baby-mum/2/${week}`}
              disabled
            />

            <TextField label="Dad Ad Company">
              <Dropdown
                value={selectedItem.dadAd_company}
                options={[{ value: '', label: 'None' }, ...companyList]}
                onChange={(value) => {
                  const selectedItemNew = { ...selectedItem };
                  selectedItemNew.dadAd_company = value.value;
                  setSelectedItem(selectedItemNew);
                }}
                className={'w-2/3'}
              />
            </TextField>
            <TextField
              type="text"
              label="Dad Ad Product"
              name="dadAd_product"
              value={selectedItem.dadAd_product}
              onChange={(event) => {
                const selectedItemNew = { ...selectedItem };
                selectedItemNew.dadAd_product = event.target.value;
                setSelectedItem(selectedItemNew);
              }}
            />
            <TextField
              type="text"
              label="Dad Ad Link"
              hint={
                'Markdown: link of the banner. / HTML: any inserted link matching this will have the Company and Product fields associated to it.'
              }
              name="dadAd"
              value={selectedItem.dadAd}
              onChange={(event) => {
                const selectedItemNew = { ...selectedItem };
                selectedItemNew.dadAd = event.target.value;
                setSelectedItem(selectedItemNew);
              }}
            />
            <HTMLEditor
              title="Dad Text (html)"
              defaultValue={htmlContentDad}
              onChange={(content) => {
                setHtmlContentDad(content);
              }}
            />
            <TextField
              type="text"
              label="Dad Tips Start Date"
              name="dad_tips_start_date"
              placeholder="2023-01-01"
              value={selectedItem.dad_tips_start_date}
              onChange={(event) => {
                const selectedItemNew = { ...selectedItem };
                selectedItemNew.dad_tips_start_date = event.target.value;
                setSelectedItem(selectedItemNew);
              }}
            />
            <TextField
              type="text"
              label="Dad Tips End Date"
              name="dad_tips_end_date"
              placeholder="2023-01-02"
              required
              value={selectedItem.dad_tips_end_date}
              onChange={(event) => {
                const selectedItemNew = { ...selectedItem };
                selectedItemNew.dad_tips_end_date = event.target.value;
                setSelectedItem(selectedItemNew);
              }}
            />
            <HTMLEditor
              title="Dad Tips (html)"
              defaultValue={htmlContentDadTips}
              onChange={(content) => {
                setHtmlContentDadTips(content);
              }}
            />
            <MarkdownEditor
              title="Dad Text (markdown)"
              value={mdContentDad}
              onChange={({ text }) => {
                setMdContentDad(text);
              }}
            />
            <ImageUp
              parentCallback={() => {
                const selectedItemNew = { ...selectedItem };
                selectedItemNew.dadAd_img = '';
                setSelectedItem(selectedItemNew);
              }}
              value={selectedItem.dadAd_img}
              getImg={(value) => {
                const selectedItemNew = { ...selectedItem };
                selectedItemNew.dadAd_img = value;
                setSelectedItem(selectedItemNew);
              }}
            />

            <Checkbox
              name="Form visible"
              selected={selectedItem.form}
              getValue={(data) => {
                const selectedItemNew = { ...selectedItem };
                selectedItemNew.form = data;
                setSelectedItem(selectedItemNew);
              }}
            />
            {selectedItem.form === 'true' && selectedItem.week_no && (
              <FormForm
                formtype="home"
                sourceIdnow={selectedItem.week_no}
                categoryIdnow={null}
                parentCallback={() => setSelectedItem({})}
                selectedItem={selectedItem}
              />
            )}
            {selectedItem.form === 'true' && !selectedItem.week_no && (
              <div>Please first save this item</div>
            )}

            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <CustomButton type="submit" className="w-1/4" onClick={() => saveCalendar()}>
                Save
              </CustomButton>
            </div>
          </div>
        )}
      </div>
    );
  };

  return (
    <Layout active="home">
      <div className="flex flex-row pt-20 w-full justify-around">
        {isAdmin && (
          <MenuButton
            onClick={() => {
              setTab(0);
              setWeek(0);
              setSelectedItem({});
            }}
            active={tab === 0}
            title="Size Text"
            visible="true"
          />
        )}
        <MenuButton
          onClick={() => {
            setTab(1);
            setWeek(0);
            setSelectedItem({});
            setHtmlContentBaby('');
            setHtmlContentMum('');
            setHtmlContentDad('');
            setMdContentBaby('');
            setMdContentMum('');
            setMdContentDad('');
          }}
          active={tab === 1}
          title="Calendar Text"
          visible="true"
        />
        {/* <MenuButton
            onClick={() => this.setState({ tab: 2, week: 0, selectedItem: {} })}
            active={this.state.tab === 2}
            title="Scrolls"
            visible="true"
          />*/}
      </div>
      <div className="flex justify-center my-12 w-full">
        <div className="flex flex-wrap w-full mx-12">
          {tab === 0 &&
            sizeTexts &&
            sizeTexts.map((value, index) => (
              <MenuButton
                key={index}
                onClick={() => {
                  const datatorender = {
                    fruit: value.fruit ? value.fruit : '',
                    fruitName: value.fruitName ? value.fruitName : '',
                    sizeText: value.sizeText ? value.sizeText : '',
                    handSize: value.handSize ? value.handSize : '',
                    img: value.img ? value.img : '',
                    imgUrl: value.imgUrl ? value.imgUrl : '',
                    length: value.length ? value.length : '',
                    weight: value.weight ? value.weight : '',
                  };

                  setSelectedItem(datatorender);
                  setLoading(false);
                  setWeek(index + 1);
                  setTimeout(() => {
                    setLoading(true);
                  }, 500);
                }}
                active={week === index + 1}
                visible="true"
                title={index + 1 === 41 ? '40+ weeks' : Number(index + 1) + '.week'}
              />
            ))}
          {tab === 1 &&
            weekTexts &&
            weekTexts.map((value, index) => (
              <MenuButton
                key={index}
                onClick={() => {
                  const dataToRender = {
                    baby_text: value.baby_text ? value.baby_text : '',
                    mum_text: value.mum_text ? value.mum_text : '',
                    dad_text: value.dad_text ? value.dad_text : '',
                    img: value.img ? value.img : '',
                    week_no: value.week_no ? value.week_no : '',
                    mum_tips_start_date: value.mum_tips_start_date
                      ? format(parseISO(value.mum_tips_start_date), 'yyyy-MM-dd')
                      : '',
                    mum_tips_end_date: value.mum_tips_end_date
                      ? format(parseISO(value.mum_tips_end_date), 'yyyy-MM-dd')
                      : '',
                    mumAd: value.mumAd ? value.mumAd : '',
                    mumAd_img: value.mumAd_img ? value.mumAd_img : '',
                    mumAd_company: value.mumAd_company ? value.mumAd_company : '',
                    mumAd_product: value.mumAd_product ? value.mumAd_product : '',
                    baby_tips_start_date: value.baby_tips_start_date
                      ? format(parseISO(value.baby_tips_start_date), 'yyyy-MM-dd')
                      : '',
                    baby_tips_end_date: value.baby_tips_end_date
                      ? format(parseISO(value.baby_tips_end_date), 'yyyy-MM-dd')
                      : '',
                    babyAd: value.babyAd ? value.babyAd : '',
                    babyAd_img: value.babyAd_img ? value.babyAd_img : '',
                    babyAd_company: value.babyAd_company ? value.babyAd_company : '',
                    babyAd_product: value.babyAd_product ? value.babyAd_product : '',
                    dad_tips_start_date: value.dad_tips_start_date
                      ? format(parseISO(value.dad_tips_start_date), 'yyyy-MM-dd')
                      : '',
                    dad_tips_end_date: value.dad_tips_end_date
                      ? format(parseISO(value.dad_tips_end_date), 'yyyy-MM-dd')
                      : '',
                    dadAd: value.dadAd ? value.dadAd : '',
                    dadAd_img: value.dadAd_img ? value.dadAd_img : '',
                    dadAd_company: value.dadAd_company ? value.dadAd_company : '',
                    dadAd_product: value.dadAd_product ? value.dadAd_product : '',
                    form: value.form ? value.form : 'false',
                    formId: value.formId ? value.formId : '',
                  };
                  setSelectedItem(dataToRender);
                  setHtmlContentBaby(value.baby_text_html ? value.baby_text_html : '');
                  setHtmlContentBabyTips(value.baby_tips_html ? value.baby_tips_html : '');
                  setHtmlContentMum(value.mum_text_html ? value.mum_text_html : '');
                  setHtmlContentMumTips(value.mum_tips_html ? value.mum_tips_html : '');
                  setHtmlContentDad(value.dad_text_html ? value.dad_text_html : '');
                  setHtmlContentDadTips(value.dad_tips_html ? value.dad_tips_html : '');
                  setMdContentBaby(value.baby_text ? value.baby_text : '');
                  setMdContentMum(value.mum_text ? value.mum_text : '');
                  setMdContentDad(value.dad_text ? value.dad_text : '');
                  setLoading(false);
                  setWeek(index + 1);
                  setTimeout(() => {
                    setLoading(true);
                  }, 500);
                }}
                active={week === index + 1}
                visible="true"
                title={index + 1 === 41 ? '40+ weeks' : Number(index + 1) + '.week'}
              />
            ))}
        </div>
      </div>
      {renderSizeTexts()}
      {loading && renderWeekTexts()}
      {tab === 2 && <Scrolls />}
    </Layout>
  );
};

export default Home;
