import React from 'react';
import PropTypes from 'prop-types';

const CustomButton = ({ children, className, type, onClick, loading, red }) => {
  return (
    <button
      type={type}
      className={`rounded px-4 py-2 m-2 text-black ${
        red === true ? 'bg-red ' : 'bg-orange '
      } focus:outline-none ${className ?? ''}`}
      onClick={onClick}
      disabled={loading}
    >
      {children}
    </button>
  );
};

CustomButton.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  onClick: PropTypes.func,
  loading: PropTypes.bool,
  type: PropTypes.oneOf(['button', 'submit']),
  red: PropTypes.bool,
};

CustomButton.defaultProps = {
  children: null,
  className: '',
  onClick: () => {},
  loading: false,
  type: 'button',
  red: false,
};

export default CustomButton;
