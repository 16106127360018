/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { CSVLink } from 'react-csv';
import { useDispatch, useSelector } from 'react-redux';
import { getClickTodos } from '../../../redux/actions/analytics';
import { getAllData } from '../../../redux/actions/api';
import CustomButton from '../../CustomButton';
import Dropdown from '../../Dropdown';
import LinkButton from '../../LinkButton';
import Spinner from '../../Spinner';
import { formatDate } from '../helper';

const TodoClick = ({ startDate, endDate }) => {
  const dispatch = useDispatch();
  const todoLoading = useSelector((state) => state.analytics.clicktodo_loading);
  const todo = useSelector((state) => state.analytics.clicktodo);
  const todoData = useSelector((state) => state.api.data.todo);

  const [selectedCategory, setSelectedCategory] = useState('');

  const TodoCategoryOptions = () => {
    return [
      ...todoData.map((category) => {
        return {
          value: category.title,
          label: category.title,
        };
      }),
    ];
  };

  useEffect(() => {
    if (!todoData) {
      dispatch(getAllData());
    }
  }, []);

  const search = () => {
    if (!selectedCategory) return;

    dispatch(getClickTodos(formatDate(startDate), formatDate(endDate), selectedCategory));
  };

  const headers = [
    { label: 'Company', key: 'companyname' },
    { label: 'Product', key: 'product' },
    { label: 'Category', key: 'category' },
    { label: 'Title', key: 'title' },
    { label: 'URL', key: 'deal' },
    { label: 'Clicks', key: 'pressed' },
    { label: 'Percentage', key: 'percentage' },
    { label: 'Unique', key: 'unique' },
  ];

  return (
    <div className="w-full flex flex-col items-center">
      <div className="w-full flex flex-col items-center">
        <div className="w-1/2 mt-8">
          <Dropdown
            value={selectedCategory}
            options={TodoCategoryOptions()}
            onChange={(value) => setSelectedCategory(value.value)}
          />
        </div>
        {todoLoading && <Spinner />}
        {!todoLoading && (
          <div>
            <div className="my-8 flex items-center justify-center w-full">
              <CustomButton type="button" className="text-white" onClick={search}>
                Search
              </CustomButton>

              {todo.length !== 0 ? (
                <CSVLink
                  className="bg-orange text-white p-2 rounded"
                  filename={`TodoClick-${formatDate(startDate)}-${formatDate(endDate)}.csv`}
                  data={todo.screens}
                  headers={headers}
                >
                  Download this table
                </CSVLink>
              ) : null}
            </div>

            {todo.length !== 0 && (
              <div>
                <div className="rounded-md bg-lightorange mb-4 p-4">
                  <h3 className="text-sm font-medium text-yellow-800">Legend</h3>
                  <div className="mt-2 text-sm text-yellow-700">
                    <ul className="list-disc pl-4">
                      <li>
                        <strong>Clicks:</strong> clicks on the "Mutasd" button
                      </li>
                    </ul>
                  </div>
                </div>

                <table>
                  <thead>
                    <tr>
                      <th>Company</th>
                      <th>Product</th>
                      <th>Todo Category</th>
                      <th>Title</th>
                      <th>URL</th>
                      <th>Clicks</th>
                      <th>Percentage (click / all clicks)</th>
                      <th>Unique</th>
                    </tr>
                  </thead>
                  <tbody>
                    {todo.screens.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td>{item.companyname.join(', ')}</td>
                          <td>{item.product.join(', ')}</td>
                          <td>{item.subcategory ?? item.category}</td>
                          <td>{item.title}</td>
                          <td
                            style={{
                              whiteSpace: 'nowrap',
                              textOverflow: 'ellipsis',
                              overflow: 'hidden',
                            }}
                          >
                            <LinkButton url={item.deal} />
                            {item.deal}
                          </td>
                          <td>{item.pressed}</td>
                          <td>
                            {((Number(item.pressed) / Number(todo.total)) * 100).toFixed(2)} %
                          </td>
                          <td>{item.unique}</td>
                        </tr>
                      );
                    })}
                    <tr className="font-bold">
                      <td>SUM</td>
                      <td />
                      <td />
                      <td />
                      <td />
                      <td>{todo.total}</td>
                      <td />
                      <td>{todo.uniq}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default TodoClick;
