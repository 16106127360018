import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { subDays } from 'date-fns';
import { setClickLoadingFalse } from '../../../redux/actions/analytics';
import CouponClick from './CouponClick';
import HomeClick from './HomeClick';
import KnowledgeClick from './KnowledgeClick';
import RecipeClick from './RecipeClick';
import TodoClick from './TodoClick';

import DateSelector from '../../DateSelector';
import MenuButton from '../../MenuButton';

const ScreenAnalytics = () => {
  const dispatch = useDispatch();
  const [startDate, setStartDate] = useState(subDays(new Date(), 30));
  const [endDate, setEndDate] = useState(new Date());
  const [selectedScreen, setSelectedScreen] = useState('');

  return (
    <div className="w-full flex flex-col items-center">
      <DateSelector
        startDate={startDate}
        endDate={endDate}
        startDateChanged={(date) => setStartDate(date)}
        endDateChanged={(date) => setEndDate(date)}
      />
      <div className="w-full flex flex-row items-center justify-center my-2">
        <MenuButton
          onClick={() => {
            setSelectedScreen('home');
            dispatch(setClickLoadingFalse());
          }}
          active={selectedScreen === 'home'}
          title="Home"
          visible="true"
        />
        <MenuButton
          onClick={() => {
            setSelectedScreen('knowledge');
            dispatch(setClickLoadingFalse());
          }}
          active={selectedScreen === 'knowledge'}
          title="Knowledge"
          visible="true"
        />
        <MenuButton
          onClick={() => {
            setSelectedScreen('recipe');
            dispatch(setClickLoadingFalse());
          }}
          active={selectedScreen === 'recipe'}
          title="Recipe"
          visible="true"
        />
        <MenuButton
          onClick={() => {
            setSelectedScreen('deals');
            dispatch(setClickLoadingFalse());
          }}
          active={selectedScreen === 'deals'}
          title="Deals"
          visible="true"
        />
        <MenuButton
          onClick={() => {
            setSelectedScreen('todo');
            dispatch(setClickLoadingFalse());
          }}
          active={selectedScreen === 'todo'}
          title="Todos"
          visible="true"
        />
      </div>
      {selectedScreen === 'home' && <HomeClick startDate={startDate} endDate={endDate} />}
      {selectedScreen === 'knowledge' && <KnowledgeClick startDate={startDate} endDate={endDate} />}
      {selectedScreen === 'recipe' && <RecipeClick startDate={startDate} endDate={endDate} />}
      {selectedScreen === 'deals' && <CouponClick startDate={startDate} endDate={endDate} />}
      {selectedScreen === 'todo' && <TodoClick startDate={startDate} endDate={endDate} />}
    </div>
  );
};

export default ScreenAnalytics;
