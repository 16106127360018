import React, { useState } from 'react';
import Layout from '../../components/layout/Layout';
import MenuButton from '../../components/MenuButton';
import UserActivity from '../../components/analytics/user/UserActivity';
import UserAll from '../../components/analytics/user/UserAll';
import UserWeeks from '../../components/analytics/user/UserWeeks';
import PregnancyWeekHistory from '../../components/analytics/user/PregnancyWeekHistory';

const Users = () => {
  const [showTab, setShowTab] = useState('');

  return (
    <Layout active="useranalytics">
      <div className="flex flex-col items-center justify-center mx-20">
        <div className="w-full flex flex-row items-center justify-center my-2">User Analytics</div>
        <div className="w-full flex flex-row items-center justify-center my-2">
          <MenuButton
            onClick={() => setShowTab('all')}
            active={showTab === 'all'}
            title="Total number of users"
            visible="true"
          />
          <MenuButton
            onClick={() => setShowTab('activity')}
            active={showTab === 'activity'}
            title="User Activity"
            visible="true"
          />

          <MenuButton
            onClick={() => setShowTab('weeks')}
            active={showTab === 'weeks'}
            title="Users per week"
            visible="true"
          />

          <MenuButton
            onClick={() => setShowTab('history')}
            active={showTab === 'history'}
            title="Pregnancy weeks at registration"
            visible="true"
          />
        </div>
      </div>
      {showTab === 'all' && <UserAll />}
      {showTab === 'activity' && <UserActivity />}
      {showTab === 'weeks' && <UserWeeks />}
      {showTab === 'history' && <PregnancyWeekHistory />}
    </Layout>
  );
};

export default Users;
