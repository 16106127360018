import React, { useState, useEffect } from 'react';
import { CSVLink } from 'react-csv';
import { useSelector, useDispatch } from 'react-redux';
import { subDays } from 'date-fns';
import {
  getPopupAnalytics,
  getCompanyList,
  setCompanyLoadedFalse,
} from '../../redux/actions/analytics';
import CustomButton from '../CustomButton';
import { formatDate } from './helper';
import DateSelector from '../DateSelector';
import Spinner from '../Spinner';
import Dropdown from '../Dropdown';

const PopupAnalytics = () => {
  const dispatch = useDispatch();
  const apiData = useSelector((state) => state.api.data);

  const companyList = useSelector((state) => state.analytics.companylist);
  const popupLoading = useSelector((state) => state.analytics.popup_analytics_loading);
  const popup = useSelector((state) => state.analytics.popup_analytics);
  const [startDate, setStartDate] = useState(subDays(new Date(), 30));
  const [endDate, setEndDate] = useState(new Date());
  const [companyName, setCompanyName] = useState('');
  const [shown, setShown] = useState('');
  const [isCompanyListFetched, setIsCompanyListFetched] = useState(false);

  useEffect(() => {
    if (companyList.length === 0 && !isCompanyListFetched) {
      dispatch(getCompanyList());
      setIsCompanyListFetched(true);
    }
  }, [companyList, isCompanyListFetched, dispatch]);

  const search = () => {
    dispatch(getPopupAnalytics(formatDate(startDate), formatDate(endDate), shown));
  };

  const getKnowledgeFromId = (placement_id) => {
    const articleSelector = [];
    apiData.knowledge.forEach((item) => {
      articleSelector.push({ value: item.id, label: item.title });
    });
    const title = articleSelector.find((item) => item.value === placement_id).label;
    return `Kategória: ${title}`;
  };

  const getArticleFromId = (placement_id) => {
    const articleSelector = [];
    apiData.knowledge.forEach((item) => {
      item.articles.forEach((e) => {
        articleSelector.push({ value: e.id, label: e.title });
      });
    });
    const title = articleSelector.find((item) => item.value === placement_id).label;
    return `Cikk: ${title}`;
  };

  const getPlacementName = (placement_type, placement_id) => {
    switch (placement_type) {
      case 'home':
        return 'Home főoldal';
      case 'home_baby':
        return `Home baba - ${placement_id}. hét`;
      case 'home_mum':
        return `Home mama - ${placement_id}. hét`;
      case 'knowledge':
        return 'Tudástár főoldal';
      case 'knowledge_category':
        return getKnowledgeFromId(placement_id);
      case 'knowledge_article':
        return getArticleFromId(placement_id);
      case 'tools':
        return 'Eszközök főoldal';
      case 'tools_category':
        return `Eszköz: ${placement_id}`;
      case 'todo':
        return 'Teendők főoldal';
      default:
        return '';
    }
  };

  const headers = [
    { label: 'Start date', key: 'start_date' },
    { label: 'End date', key: 'end_date' },
    { label: 'Company', key: 'companyname' },
    { label: 'Name', key: 'title' },
    { label: 'Screen', key: 'screen' },
    { label: 'Impressions', key: 'appeared' },
    { label: 'Clicks', key: 'pressed' },
    { label: 'CTR', key: 'ctr' },
  ];

  return (
    <div className="w-full flex flex-col items-center">
      <DateSelector
        startDate={startDate}
        endDate={endDate}
        startDateChanged={(date) => setStartDate(date)}
        endDateChanged={(date) => setEndDate(date)}
      />
      <div className="w-1/2">
        <Dropdown
          value={shown}
          options={[
            { value: '', label: 'All' },
            { value: 'first', label: 'First time shown' },
            { value: 'second', label: 'Second time shown' },
          ]}
          onChange={(value) => {
            setShown(value.value);
          }}
        />
        <Dropdown
          value={companyName}
          options={[{ label: 'All companies', value: '' }, ...companyList]}
          placeholder={'Select company'}
          onChange={(value) => {
            setCompanyName(value.value);
            dispatch(setCompanyLoadedFalse());
          }}
        />
      </div>

      <div>
        {popupLoading && <Spinner />}
        {!popupLoading && (
          <div>
            <div className="my-8 flex items-center justify-center w-full">
              <CustomButton type="button" className="text-white" onClick={search}>
                Search
              </CustomButton>

              {popup.length !== 0 ? (
                <CSVLink
                  className="bg-orange text-white p-2 rounded"
                  filename={`Popup-${formatDate(startDate)}-${formatDate(endDate)}.csv`}
                  data={popup.screens}
                  headers={headers}
                >
                  Download this table
                </CSVLink>
              ) : null}
            </div>

            {popup.length !== 0 && (
              <div className={'overflow-x-auto'}>
                <table>
                  <thead>
                    <tr>
                      <th>Start date</th>
                      <th>End date</th>
                      <th>Company</th>
                      <th>Product</th>
                      <th>Name</th>
                      <th>Screen</th>
                      <th>Impressions</th>
                      <th>Clicks</th>
                      <th>CTR</th>
                    </tr>
                  </thead>
                  <tbody>
                    {popup.screens
                      .filter((item) =>
                        companyName === '' ? item : item.companyname.includes(companyName)
                      )
                      .map((item, index) => {
                        return (
                          <tr key={index}>
                            <td>{item.start_date}</td>
                            <td>{item.end_date}</td>
                            <td>{item.companyname.join(', ')}</td>
                            <td>{item.product.join(', ')}</td>
                            <td>{item.title}</td>
                            <td>
                              {item.screen
                                .map((screen, index) =>
                                  getPlacementName(screen, item.screenId[index])
                                )
                                .join(', ')}
                            </td>
                            <td>{item.appeared}</td>
                            <td>{item.pressed}</td>
                            <td>{item.ctr}%</td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default PopupAnalytics;
