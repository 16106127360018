export const initialState = {
  users: [],
  data: [],
  homescreen: [],
  forms: [],
  popups: [],
  scrolls: [],
  useremail: {},
  useremail404: false,
};

const api = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_ALL_DATA':
      return {
        ...state,
        data: action.data,
        homescreen: action.data.homescreen,
      };
    case 'GET_FORM':
      return {
        ...state,
        forms: action.data.data,
      };
    case 'GET_FORM_ERROR':
      return {
        ...state,
        forms: [],
      };
    case 'GET_USER_EMAIL':
      return {
        ...state,
        useremail: action.data,
        useremail404: false,
      };
    case 'GET_USER_EMAIL_ERROR':
      return {
        ...state,
        useremail: {},
        useremail404: true,
      };
    case 'GET_USER_EMAIL_ERROR_DELETE':
      return {
        ...state,
        useremail: {},
        useremail404: false,
      };
    case 'GET_USERS':
      return {
        ...state,
        users: action.data,
      };
    case 'DELETE_USER':
      return {
        ...state,
        useremail: {},
      };
    /******************** POPUPS ***********************/
    case 'GET_POPUPS':
      return {
        ...state,
        popups: action.data,
      };
    case 'GET_POPUPS_ERROR':
      return {
        ...state,
        popups: [],
      };
    /******************** SCROLLS ***********************/
    case 'GET_SCROLLS':
      return {
        ...state,
        scrolls: action.data,
      };
    case 'GET_SCROLLS_ERROR':
      return {
        ...state,
        scrolls: [],
      };
    /******************** DEFAULT ***********************/
    default:
      return state;
  }
};

export default api;
