import axios from 'axios';
import { apiUrl, master_key } from '../../config/settings';
import { getAllData } from './api';
import { saveToken, unauthorizedError } from './state';

export const login = (email, password) => (dispatch) => {
  const basicAuth = 'Basic ' + btoa(email + ':' + password);
  return axios
    .post(
      `${apiUrl}/auth?access_token=${master_key}`,
      {},
      {
        headers: { Authorization: basicAuth },
      }
    )
    .then((response) => {
      dispatch(loginAction(response));
      dispatch(saveToken(response.data.token));
      dispatch(getAllData());
      dispatch(getMe());
      return response;
    })
    .catch(function (error) {
      if (error.response) {
        if (error.response.status === 401) {
          dispatch(loginError('Hiba a belépésnél'));
        } else {
          dispatch(loginError('Rossz felhasználónév vagy jelszó'));
        }
      }
      dispatch(loginError('Rossz felhasználónév vagy jelszó'));
    });
};

export const getMe = () => async (dispatch, getState) => {
  return axios
    .get(apiUrl + '/users/me', {
      headers: { Authorization: 'Bearer ' + getState().state.token },
    })
    .then(async (response) => {
      await dispatch({ type: 'GET_ME', data: response });
      dispatch(getAllData());
      return response.data;
    })
    .catch(function (error) {
      dispatch({ type: 'GET_ME_ERROR' });
      if (error.response) {
        if (error.response.status === 401) {
          dispatch(unauthorizedError());
        }
      }
    });
};

export const loginError = (message) => ({
  type: 'LOGIN_ERROR',
  data: message,
});

export const loginAction = (data) => {
  return { type: 'LOGIN', data };
};
