import axios from 'axios';
import { getAllData } from './api';
import { apiUrl, master_key } from '../../config/settings';
import { unauthorizedError } from './state';

export const getForms = () => (dispatch) => {
  return axios
    .get(apiUrl + '/form', {
      headers: { Authorization: 'Bearer ' + master_key },
    })
    .then((response) => {
      dispatch({ type: 'GET_FORMS', data: response.data });
    })
    .catch(function (error) {
      if (error.response.status === 404) {
        return null;
      }
      dispatch({ type: 'GET_FORMS_ERROR' });
      alert(error);
      if (error.response) {
        if (error.response.status === 401) {
          dispatch(unauthorizedError());
        }
      }
      return [];
    });
};

export const sendForm = (form) => (dispatch, getState) => {
  axios
    .put(
      apiUrl + '/form',
      {
        id: form.id,
        sourceId: form.sourceId,
        categoryId: form.categoryId,
        title: form.title,
        description: form.description,
        type: form.type,
        buttontext: form.buttontext,
        othertitle: form.othertitle,
        name: form.name,
        email: form.email,
        phone: form.phone,
        other: form.other,
        expectedDate: form.expectedDate,
        address: form.address,
        company: form.company,
        sendbuttontext: form.sendbuttontext,
        privacypolicylink: form.privacypolicylink,
        privacypolicytext: form.privacypolicytext,
      },
      { headers: { Authorization: 'Bearer ' + master_key } }
    )
    .then((response) => {
      console.log(response);
      dispatch({ type: 'UPDATE_FORM' });
      alert('Updated');
      dispatch(getForms());
      dispatch(getAllData());
    })
    .catch(function (error) {
      console.log(error);
      alert('Update ERROR');
      alert(error);
      dispatch({ type: 'UPDATE_FORM_ERROR' });
      if (error.response) {
        if (error.response.status === 401) {
          dispatch(unauthorizedError());
        }
      }
    });
};
