import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getForms } from '../redux/actions/forms';
import MenuButton from '../components/MenuButton';
import Spinner from '../components/Spinner';
import Dropdown from '../components/Dropdown';
import DateSelector from '../components/DateSelector';
import { CSVLink } from 'react-csv';
import format from 'date-fns/format';
import Layout from '../components/layout/Layout';
import { dynamicSort } from '../utils/sort';

const Forms = () => {
  const dispatch = useDispatch();
  const forms = useSelector((state) => state.forms.list);
  const [currentForm, setCurrentForm] = useState('0');
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [selectedType, setSelectedType] = useState('');

  useEffect(() => {
    dispatch(getForms());
  }, [dispatch]);

  const headers = [
    { label: 'Name', key: 'name' },
    { label: 'Phone', key: 'phone' },
    { label: 'Email', key: 'email' },
    { label: 'Address', key: 'address' },
    { label: 'Expected Date', key: 'expectedDate' },
    { label: 'Other info', key: 'other' },
  ];

  return (
    <Layout active="forms">
      <div className="flex flex-row wrap justify-start pt-12 w-full">
        {(!forms || forms.length === 0) && <Spinner />}
        <div className="mx-12 w-full">
          <div className="w-full flex items-center justify-center mb-8">
            <div className="w-64">
              <Dropdown
                value={selectedType}
                options={[
                  { value: 'deals', label: 'deals' },
                  { value: 'home', label: 'home' },
                  { value: 'article', label: 'article' },
                ]}
                onChange={(value) => setSelectedType(value.value)}
              />
            </div>
          </div>
          <div className="w-full flex flex-row justify-around">
            {forms
              .filter((item) => item.type === selectedType)
              .map((item) => {
                return (
                  <MenuButton
                    onClick={() => setCurrentForm(item.id)}
                    active={currentForm === item.id}
                    title={item.title}
                    visible="true"
                  />
                );
              })}
          </div>
          {forms
            .filter((item) => item.id === currentForm)
            .map((formdata) => {
              return (
                <div
                  style={{
                    flexDirection: 'column',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginTop: 50,
                  }}
                >
                  <DateSelector
                    startDate={startDate}
                    endDate={endDate}
                    startDateChanged={(date) => setStartDate(date)}
                    endDateChanged={(date) => setEndDate(date)}
                  />
                  <CSVLink
                    className="bg-orange text-white p-2 rounded mb-4"
                    filename={`${forms.filter((item) => item.id === currentForm)[0].title}-${format(
                      new Date(startDate),
                      'yyyy-LL-dd'
                    )}--${format(new Date(endDate), 'yyyy-LL-dd')}.csv`}
                    data={forms
                      .filter((item) => item.id === currentForm)[0]
                      .data.filter((item) => {
                        let date = new Date(item.date);
                        return date >= startDate && date <= endDate;
                      })}
                    headers={headers}
                  >
                    Download data
                  </CSVLink>
                  <div
                    style={{
                      background: '#F5F2EE',
                      borderRadius: 5,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}
                  >
                    <table>
                      <thead>
                        <tr>
                          {formdata.name === true && <th>Name</th>}
                          {formdata.email === true && <th>Email</th>}
                          {formdata.phone === true && <th>Phone</th>}
                          {formdata.address === true && <th>Address</th>}
                          {formdata.expectedDate === true && <th>Expected Date</th>}
                          {formdata.other === true && <th>{formdata.othertitle}</th>}
                        </tr>
                      </thead>
                      <tbody>
                        {formdata.data
                          .filter((item) => {
                            let date = new Date(item.date);
                            return date >= startDate && date <= endDate;
                          })
                          .sort(dynamicSort('date'))
                          .map((e) => {
                            return (
                              <tr>
                                {formdata.name === true && <td>{e.name ? e.name : ''}</td>}
                                {formdata.email === true && <td>{e.email ? e.email : ''}</td>}
                                {formdata.phone === true && <td>{e.phone ? e.phone : ''}</td>}
                                {formdata.address === true && <td>{e.address ? e.address : ''}</td>}
                                {formdata.expectedDate === true && (
                                  <td>{e.expectedDate ? e.expectedDate : ''}</td>
                                )}
                                {formdata.other === true && <td>{e.other ? e.other : ''}</td>}
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </Layout>
  );
};

export default Forms;
