/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import React, { useEffect, useState } from 'react';
import { CSVLink } from 'react-csv';
import { useSelector, useDispatch } from 'react-redux';
import { getClickKnowledge } from '../../../redux/actions/analytics';
import { getAllData } from '../../../redux/actions/api';
import CustomButton from '../../CustomButton';
import LinkButton from '../../LinkButton';
import { formatDate } from '../helper';
import Spinner from '../../Spinner';
import Dropdown from '../../Dropdown';

const KnowledgeClick = ({ startDate, endDate }) => {
  const [selectedCategory, setSelectedCategory] = useState('');

  const dispatch = useDispatch();
  const knowledgeLoading = useSelector((state) => state.analytics.clickknowledge_loading);
  const knowledge = useSelector((state) => state.analytics.clickknowledge);
  const knowledgeData = useSelector((state) => state.api.data.knowledge);

  useEffect(() => {
    if (!knowledgeData) {
      dispatch(getAllData());
    }
  }, []);
  const search = () => {
    dispatch(getClickKnowledge(formatDate(startDate), formatDate(endDate), selectedCategory));
  };

  const KnowledgeCategoryOptions = () => {
    if (knowledgeData) {
      const categorySelector = [];
      [...knowledgeData]
        .sort((a, z) => a.title.localeCompare(z.title))
        .forEach((item) => {
          categorySelector.push({ value: item.id, label: item.title });
        });

      return categorySelector;
    }

    return [];
  };

  const headers = [
    { label: 'Company', key: 'companyname' },
    { label: 'Product', key: 'product' },
    { label: 'Screen', key: 'screen' },
    { label: 'URL', key: 'url' },
    { label: 'Link type', key: 'type' },
    { label: 'Clicks', key: 'pressed' },
    { label: 'Percentage (clicks / all clicks)', key: 'percentage' },
    { label: 'Unique', key: 'unique' },
  ];

  return (
    <div className="w-full flex flex-col items-center">
      <div className="w-1/2 mt-8">
        <Dropdown
          value={selectedCategory}
          options={KnowledgeCategoryOptions()}
          onChange={(value) => setSelectedCategory(value.value)}
        />
      </div>
      {knowledgeLoading && <Spinner />}
      {!knowledgeLoading && (
        <div>
          <div className="my-8 flex items-center justify-center w-full">
            <CustomButton type="button" className="text-white" onClick={search}>
              Search
            </CustomButton>

            {knowledge.length !== 0 ? (
              <CSVLink
                className="bg-orange text-white p-2 rounded"
                filename={`KnowledgeClick-${formatDate(startDate)}-${formatDate(endDate)}.csv`}
                data={knowledge.screens}
                headers={headers}
              >
                Download this table
              </CSVLink>
            ) : null}
          </div>

          {knowledge.length !== 0 && (
            <table>
              <thead>
                <tr>
                  <th>Company</th>
                  <th>Product</th>
                  <th>Screen</th>
                  <th>URL</th>
                  <th>Link type</th>
                  <th>Clicks</th>
                  <th>Percentage (click / all clicks)</th>
                  <th>Unique</th>
                </tr>
              </thead>
              <tbody>
                {knowledge.screens.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td>{item.companyname.join(', ')}</td>
                      <td>{item.product.join(', ')}</td>
                      <td>{item.screen}</td>
                      <td
                        style={{
                          whiteSpace: 'nowrap',
                          textOverflow: 'ellipsis',
                          overflow: 'hidden',
                        }}
                      >
                        <LinkButton url={item.url} />
                        {item.url}
                      </td>
                      <td>{item.type}</td>
                      <td>{item.pressed}</td>
                      <td>{((item.pressed / knowledge.total) * 100).toFixed(2)}%</td>
                      <td>{item.unique}</td>
                    </tr>
                  );
                })}
                <tr className="font-bold">
                  <td>SUM</td>
                  <td />
                  <td />
                  <td />
                  <td />
                  <td>{knowledge.total}</td>
                  <td />
                  <td>{knowledge.uniq}</td>
                </tr>
              </tbody>
            </table>
          )}
        </div>
      )}
    </div>
  );
};

export default KnowledgeClick;
