import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getPhotographers, getFoodsafeties } from '../../redux/actions/tools';
import Layout from '../../components/layout/Layout';
import MenuButton from '../../components/MenuButton';
import FoodSafety from '../../components/tools/FoodSafety';
import Photographer from '../../components/tools/Photographer';
import Knowledge from './Knowledge';

const Tools = () => {
  const dispatch = useDispatch();
  const [tab, setTab] = useState('');
  const { role } = useSelector((state) => state?.auth?.user);
  const isAdmin = role === 'admin';

  useEffect(() => {
    setTab('');
    dispatch(getPhotographers());
    dispatch(getFoodsafeties());
  }, [dispatch]);

  return (
    <Layout active="tools">
      <div className="flex flex-row mx-12 mt-8">
        {isAdmin && (
          <>
            <MenuButton
              onClick={() => {
                setTab('foodsafety');
              }}
              active={tab === 'foodsafety'}
              title="Food Safety"
              visible="true"
            />
            <MenuButton
              onClick={() => {
                setTab('recipes');
              }}
              active={tab === 'recipes'}
              title="Recipes"
              visible="true"
            />
            <MenuButton
              onClick={() => {
                setTab('videos');
              }}
              active={tab === 'videos'}
              title="Videos"
              visible="true"
            />
          </>
        )}
        <MenuButton
          onClick={() => {
            setTab('photographer');
          }}
          active={tab === 'photographer'}
          title="Photographers"
          visible="true"
        />
      </div>
      {tab === 'foodsafety' && <FoodSafety />}
      {tab === 'recipes' && <Knowledge onlyContent categoryId={'10'} />}
      {tab === 'videos' && <Knowledge onlyContent categoryId={'14'} />}
      {tab === 'photographer' && <Photographer />}
    </Layout>
  );
};

export default Tools;
