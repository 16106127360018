import React, { useEffect, useState } from 'react';
import { CSVLink } from 'react-csv';
import { useSelector, useDispatch } from 'react-redux';
import {
  getScreenArticle,
  getScreenPhotographers,
  getScreenTools,
} from '../../../redux/actions/analytics';
import CustomButton from '../../CustomButton';
import Dropdown from '../../Dropdown';
import { formatDate } from '../helper';
import Spinner from '../../Spinner';
import ContentViewSummaryTable from './ContentViewSummaryTable';

const ToolScreen = ({ startDate, endDate }) => {
  const dispatch = useDispatch();
  const toolsLoading = useSelector((state) => state.analytics.screentool_loading);
  const tools = useSelector((state) => state.analytics.screentool);

  const photographerLoading = useSelector((state) => state.analytics.screenphotographers_loading);
  const photographer = useSelector((state) => state.analytics.screenphotographers);

  const articleLoading = useSelector((state) => state.analytics.screenarticle_loading);
  const article = useSelector((state) => state.analytics.screenarticle);

  const [selectedCategory, setSelectedCategory] = useState('');
  const [isFormChanged, setIsFormChanged] = useState(false);

  useEffect(() => {
    setIsFormChanged(true);
  }, [selectedCategory]);

  const search = () => {
    if (selectedCategory === 'photographers') {
      dispatch(getScreenPhotographers(formatDate(startDate), formatDate(endDate)));
    } else if (selectedCategory !== '') {
      dispatch(getScreenArticle(formatDate(startDate), formatDate(endDate), selectedCategory));
    } else {
      dispatch(getScreenTools(formatDate(startDate), formatDate(endDate)));
    }

    setIsFormChanged(false);
  };

  const ToolsCategoryOptions = [
    { value: '', label: 'All' },
    { value: 'photographers', label: 'Photographers' },
    { value: 'Receptek', label: 'Recipes' },
    { value: 'Videótár', label: 'Videos' },
  ];

  const screenHeaders = [
    { label: 'Screen', key: 'screen' },
    { label: 'Views', key: 'appeared' },
    { label: 'Percentage', key: 'percentage' },
    { label: 'Unique', key: 'unique' },
  ];

  const articleHeaders = [
    { label: 'Article', key: 'article' },
    { label: 'Views', key: 'pressed' },
    { label: 'Percentage (views / all views)', key: 'percentage' },
    { label: 'Unique', key: 'unique' },
  ];

  let headers = screenHeaders;
  let dataToDownload = tools.screens;
  if (selectedCategory === 'photographers') {
    dataToDownload = photographer.screens;
  } else if (selectedCategory !== '') {
    dataToDownload = article.articles;
    headers = articleHeaders;
  }

  return (
    <div className="w-full flex flex-col items-center">
      <div className="w-1/2 mt-8">
        <Dropdown
          value={selectedCategory}
          options={ToolsCategoryOptions}
          onChange={(value) => {
            setSelectedCategory(value.value);
          }}
        />
      </div>

      <div>
        {(toolsLoading || photographerLoading || articleLoading) && <Spinner />}
        {!toolsLoading && !photographerLoading && !articleLoading && (
          <div>
            <div className="my-8 flex items-center justify-center w-full">
              <CustomButton type="button" className="text-white" onClick={search}>
                Search
              </CustomButton>

              {dataToDownload && dataToDownload.length !== 0 && !isFormChanged ? (
                <CSVLink
                  className="bg-orange text-white p-2 rounded"
                  filename={`ToolsAnalytics-${formatDate(startDate)}--${formatDate(endDate)}.csv`}
                  data={dataToDownload}
                  headers={headers}
                >
                  Download this table
                </CSVLink>
              ) : null}
            </div>

            {selectedCategory === '' && tools.length !== 0 && !isFormChanged && (
              <ContentViewSummaryTable data={tools} title={'Tools'} />
            )}

            {selectedCategory === 'photographers' &&
              photographer.length !== 0 &&
              !isFormChanged && (
                <ContentViewSummaryTable data={photographer} title={'Photographers'} />
              )}

            {selectedCategory !== '' &&
              selectedCategory !== 'photographers' &&
              article.length !== 0 &&
              !isFormChanged && (
                <table>
                  <thead>
                    <tr>
                      <th>Article</th>
                      <th>Views</th>
                      <th>Percentage (views / all views)</th>
                      <th>Unique</th>
                    </tr>
                  </thead>
                  <tbody>
                    {article.articles.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td>{item.article}</td>
                          <td>{item.pressed}</td>
                          <td>{((item.pressed / article.total) * 100).toFixed(2)}%</td>
                          <td>{item.unique}</td>
                        </tr>
                      );
                    })}
                    <tr className="font-bold">
                      <td>Total for {selectedCategory}</td>
                      <td>{article.total}</td>
                      <td />
                      <td>{article.uniq}</td>
                    </tr>
                  </tbody>
                </table>
              )}
          </div>
        )}
      </div>
    </div>
  );
};

export default ToolScreen;
