export function dynamicSort(property) {
  var sortOrder = 1;
  if (property[0] === '-') {
    sortOrder = -1;
    property = property.substr(1);
  }
  return function (a, b) {
    var result =
      a[property] !== '' && b[property] !== ''
        ? a[property] < b[property]
          ? -1
          : a[property] > b[property]
          ? 1
          : 0
        : 0;
    return result * sortOrder;
  };
}

export function dynamicSortMultiple() {
  var props = arguments;
  return function (obj1, obj2) {
    var i = 0,
      result = 0,
      numberOfProperties = props.length;
    while (result === 0 && i < numberOfProperties) {
      result = dynamicSort(props[i])(obj1, obj2);
      i++;
    }
    return result;
  };
}

export function sortByVisibilityAndOrder() {
  return function (a, b) {
    const aSize = a.visible;
    const bSize = b.visible;
    const aLow = a.order === '' ? 999 : a.order;
    const bLow = b.order === '' ? 999 : b.order;
    if (aSize === bSize) {
      return Number(aLow) < Number(bLow) ? -1 : Number(aLow) > Number(bLow) ? 1 : 0;
    } else {
      return aSize < bSize ? 1 : -1;
    }
  };
}

export function sortByOrder() {
  return function (a, z) {
    const aOrder = a.order ? parseInt(a.order, 10) : 999;
    const bOrder = z.order ? parseInt(z.order, 10) : 999;

    return aOrder - bOrder || a.title?.localeCompare(z?.title);
  };
}

export function sortByVisibility() {
  return function (a, b) {
    var aSize = a.visible;
    var bSize = b.visible;

    return aSize < bSize ? 1 : -1;
  };
}
