import { initializeApp } from 'firebase/app';
import { getStorage } from 'firebase/storage';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY || 'AIzaSyD66QeIjlJa-9YmRm2fLCdSH4WGXWUFzWk',
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN || 'preg-app-1.firebaseapp.com',
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL || 'https://preg-app-1.firebaseio.com',
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID || 'preg-app-1',
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET || 'preg-app-1.appspot.com',
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID || '575542549953',
  appId: process.env.REACT_APP_FIREBASE_APP_ID || '1:575542549953:web:777df1829a0326f8682340',
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID || 'G-FC8QS2Z62Y',
};

export const app = initializeApp(firebaseConfig);

export const storage = getStorage(app);
