/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { CSVLink } from 'react-csv';
import { useSelector, useDispatch } from 'react-redux';
import { getScreenTodo, getScreenTodoItem } from '../../../redux/actions/analytics';
import { getAllData } from '../../../redux/actions/api';
import CustomButton from '../../CustomButton';
import { formatDate } from '../helper';
import Dropdown from '../../Dropdown';
import Spinner from '../../Spinner';
import ContentViewSummaryTable from './ContentViewSummaryTable';

const TodoScreen = ({ startDate, endDate }) => {
  const dispatch = useDispatch();
  const todoData = useSelector((state) => state.api.data.todo);

  const todoLoading = useSelector((state) => state.analytics.screentodo_loading);
  const todo = useSelector((state) => state.analytics.screentodo);

  const todoItemLoading = useSelector((state) => state.analytics.screentodoitem_loading);
  const todoItem = useSelector((state) => state.analytics.screentodoitem);

  const [selectedCategory, setSelectedCategory] = useState('');
  const [isFormChanged, setIsFormChanged] = useState(false);
  const [todoItemFilteredArray, setTodoItemFilteredArray] = useState([]);

  const TodoCategoryOptions = () => {
    if (todoData) {
      const articleSelector = [{ value: '', label: 'All' }];
      todoData?.forEach((item) => {
        articleSelector.push({ value: item.id, label: item.title });
      });

      return articleSelector;
    }
    return [];
  };

  useEffect(() => {
    if (!todoData) {
      dispatch(getAllData());
    }
    if (startDate !== '' && endDate !== '') {
      dispatch(getScreenTodo(formatDate(startDate), formatDate(endDate)));
      setIsFormChanged(false);
    }
  }, []);

  useEffect(() => {
    if (todoItem?.screens?.length > 0) {
      setTodoItemFilteredArray(todoItem);
    } else {
      setTodoItemFilteredArray([]);
    }
  }, [todoItem]);

  const search = () => {
    if (selectedCategory) {
      dispatch(getScreenTodoItem(formatDate(startDate), formatDate(endDate), selectedCategory));
    } else {
      dispatch(getScreenTodo(formatDate(startDate), formatDate(endDate)));
    }

    setIsFormChanged(false);
  };

  const headers = [
    { label: 'Category', key: 'screen' },
    { label: 'Views', key: 'appeared' },
    { label: 'Percentage (views / all views)', key: 'percentage' },
    { label: 'Unique', key: 'unique' },
  ];

  const subcategoryHeaders = [
    { label: 'Subcategory', key: 'screen' },
    { label: 'Views', key: 'appeared' },
    { label: 'Percentage (views / all views)', key: 'percentage' },
    { label: 'Unique', key: 'unique' },
  ];

  return (
    <div className="w-full flex flex-col items-center">
      <div className="w-1/2 mt-8">
        <Dropdown
          value={selectedCategory}
          options={TodoCategoryOptions()}
          onChange={(value) => setSelectedCategory(value.value)}
        />
      </div>
      <div>
        {todoLoading && <Spinner />}
        {!todoLoading && selectedCategory === '' && (
          <div>
            <div className="my-8 flex items-center justify-center w-full">
              <CustomButton type="button" className="text-white" onClick={search}>
                Search
              </CustomButton>

              {todo.length !== 0 && !isFormChanged ? (
                <CSVLink
                  className="bg-orange text-white p-2 rounded"
                  filename={`Todo-${formatDate(startDate)}-${formatDate(endDate)}.csv`}
                  data={todo.screens}
                  headers={headers}
                >
                  Download this table
                </CSVLink>
              ) : null}
            </div>

            {todo.length !== 0 && !isFormChanged && (
              <ContentViewSummaryTable data={todo} title={'Todo'} screenColumnName={'Category'} />
            )}
          </div>
        )}
        {todoItemLoading && <Spinner />}
        {!todoItemLoading && selectedCategory !== '' && (
          <div>
            <div className="my-8 flex items-center justify-center w-full">
              <CustomButton type="button" className="text-white" onClick={search}>
                Search
              </CustomButton>

              {todoItemFilteredArray.length !== 0 && !isFormChanged ? (
                <CSVLink
                  className="bg-orange text-white p-2 rounded"
                  filename={`Todo-${selectedCategory}-${formatDate(startDate)}-${formatDate(
                    endDate
                  )}.csv`}
                  data={todoItemFilteredArray.screens}
                  headers={subcategoryHeaders}
                >
                  Download this table
                </CSVLink>
              ) : null}
            </div>

            {todoItemFilteredArray.length !== 0 && !isFormChanged && (
              <ContentViewSummaryTable
                data={todoItemFilteredArray}
                title={'Todo items'}
                screenColumnName={'Subcategory'}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default TodoScreen;
