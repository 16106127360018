import React, { useState } from 'react';
import { subDays } from 'date-fns';
import { CSVLink } from 'react-csv';
import { useSelector, useDispatch } from 'react-redux';
import DateSelector from '../DateSelector';
import CustomButton from '../CustomButton';
import Spinner from '../Spinner';
import Dropdown from '../Dropdown';
import { getUserTodos } from '../../redux/actions/analytics';
import { formatDate } from './helper';

export const UserTodoAnalytics = () => {
  const dispatch = useDispatch();
  const [startDate, setStartDate] = useState(subDays(new Date(), 30));
  const [endDate, setEndDate] = useState(new Date());
  const [selectedCategory, setSelectedCategory] = useState();
  const [subcategories, setSubcategories] = useState([]);
  const [selectedSubcategory, setSelectedSubcategory] = useState();
  const apiData = useSelector((state) => state.api.data);
  const userTodos = useSelector((state) => state.analytics.user_todos);
  const loading = useSelector((state) => state.analytics.user_todos_loading);

  const headers = [{ label: 'Todo title', key: 'title' }];

  const search = () => {
    dispatch(getUserTodos(formatDate(startDate), formatDate(endDate), selectedSubcategory));
  };

  return (
    <>
      <DateSelector
        startDate={startDate}
        endDate={endDate}
        startDateChanged={(date) => setStartDate(date)}
        endDateChanged={(date) => setEndDate(date)}
      />

      <h1 className="my-2">Categories</h1>

      <div className="w-1/2">
        <Dropdown
          value={selectedCategory}
          options={apiData.todo
            .filter((todo) => todo.visible)
            .sort((a, z) => a.title.localeCompare(z.title))
            .map((todo) => ({ value: todo.id, label: todo.title, data: todo.data }))}
          onChange={(todo) => {
            setSelectedCategory(todo.value);
            setSubcategories(todo.data || []);
            setSelectedSubcategory(null);
          }}
        />
      </div>

      {selectedCategory && (
        <>
          <h1 className="my-2">Subcategories</h1>

          <div className="w-1/2">
            <Dropdown
              value={selectedSubcategory}
              options={subcategories
                .filter((subcategory) => subcategory.visible)
                .sort((a, z) => a.title.localeCompare(z.title))
                .map((subcategory) => ({ value: subcategory.id, label: subcategory.title }))}
              onChange={(subcategory) => {
                setSelectedSubcategory(subcategory.value);
              }}
            />
          </div>
        </>
      )}

      {startDate && endDate && selectedCategory && selectedSubcategory && (
        <div className="my-8 flex items-center justify-center w-full">
          <CustomButton type="button" className="text-white" onClick={search}>
            Search
          </CustomButton>

          {userTodos.length !== 0 ? (
            <CSVLink
              className="bg-orange text-white p-2 rounded"
              filename={`UserTodos-${selectedSubcategory}-${formatDate(startDate)}-${formatDate(
                endDate
              )}.csv`}
              data={userTodos}
              headers={headers}
            >
              Download this table
            </CSVLink>
          ) : null}
        </div>
      )}

      {loading && <Spinner />}
      {!loading && !!userTodos.length && (
        <table>
          <thead>
            <tr>
              <th>Todo title</th>
            </tr>
          </thead>
          <tbody>
            {userTodos.map((userTodo) => (
              <tr key={userTodo._id}>
                <td>{userTodo.title}</td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </>
  );
};
