import React from 'react';

const MenuButton = ({ onClick, active, title, visible }) => {
  return (
    <button
      onClick={() => onClick()}
      className={`inline-flex items-center m-2 px-2 rounded h-10 justify-center focus:outline-none bg-extralightorange
      ${active ? 'border-2 border-solid border-orange' : 'border-none'}
      ${
        visible === true || visible === 'true'
          ? 'bg-opacity-100 text-black'
          : 'bg-opacity-20 text-grey'
      }`}
    >
      {title}
    </button>
  );
};

export default MenuButton;
