import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  createScrolls,
  deleteScrolls,
  getAllData,
  getScrolls,
  getScrollsAnalytics,
  updateScrolls,
} from '../redux/actions/api';

class Scrolls extends Component {
  constructor() {
    super();
    this.state = {
      type: 'create',
      tab: 'weeks',
      weektab: 0,
      order: '',
      categorytitle: '',
      headline: '',
      selectedItem: {},
      markdown: '',
      modalIsOpen: false,
      scrolls: true,
      title: '',
      img: '',
      url: null,
      articleId: null,
      categoryId: null,
      dealId: null,
      selectedArticle: '',
      deallabel: '',
      label: '',
      startDate: '',
      endDate: '',
    };
  }

  componentWillMount() {
    this.popupData();
  }

  async popupData() {
    await this.props.getScrolls();
    await this.props.getAllData();
  }

  createNewScrolls() {
    const week = this.props.scrolls[this.state.weektab].week;
    const scrollweek = this.props.scrolls[this.state.weektab].id;
    const type = this.state.selectedItem.type;
    const boxtype = this.state.selectedItem.boxtype;
    const order = this.state.selectedItem.order;
    const categorytitle = this.state.selectedItem.categorytitle;
    const img = this.state.selectedItem.img;
    const headline = this.state.selectedItem.headline;
    const text = this.state.selectedItem.text;
    const link = this.state.selectedItem.link;
    const tool = this.state.selectedItem.tool;
    const articleId = this.state.selectedItem.articleId;
    const categoryId = this.state.selectedItem.categoryId;
    const dealId = this.state.selectedItem.dealId;
    if (this.state.type === 'create') {
      this.props.createScrolls(
        week,
        scrollweek,
        type,
        boxtype,
        order,
        categorytitle,
        headline,
        text,
        img,
        link,
        tool,
        articleId,
        categoryId,
        dealId
      );
    }
    if (this.state.type === 'update') {
      const id = this.state.selectedItem.id;
      this.props.updateScrolls(
        id,
        week,
        scrollweek,
        type,
        boxtype,
        order,
        categorytitle,
        headline,
        text,
        img,
        link,
        tool,
        articleId,
        categoryId,
        dealId
      );
    }
    this.setState({
      type: 'create',
      selectedItem: {
        id: '',
        title: '',
        week: '',
        scrollweek: '',
        type: null,
        boxtype: '',
        order: '',
        categorytitle: '',
        headline: '',
        text: '',
        img: '',
        url: null,
        articleId: null,
        categoryId: null,
        dealId: null,
        selectedArticle: '',
        deallabel: '',
        label: '',
        tool: '',
      },
    });
  }

  setArticleAndCategoryID(event) {
    event.preventDefault();
    var selectedItem = { ...this.state.selectedItem };
    selectedItem.articleId = event.target.value;
    this.props.knowledge.forEach((item) => {
      item.articles.forEach((article) => {
        if (article.id === event.target.value) {
          selectedItem.categoryId = item.id;
          selectedItem.img = article.img;
          this.setState({ selectedItem, label: article.title });
        }
      });
    });
  }

  setDealID(event) {
    event.preventDefault();
    this.props.deals.forEach((item) => {
      if (item.id === event.target.value) {
        var selectedItem = { ...this.state.selectedItem };
        selectedItem.img = item.img;
        selectedItem.dealId = item.id;
        this.setState({ selectedItem, deallabel: item.title });
      }
    });
  }

  choosePopup(index) {
    const popups = this.props.scrolls;
    const { weektab } = this.state;
    const datatorender = {
      id: popups[weektab].items[index].id,
      type: popups[weektab].items[index].type ? popups[weektab].items[index].type : '',
      title: popups[weektab].items[index].title ? popups[weektab].items[index].title : '',
      boxtype: popups[weektab].items[index].boxtype ? popups[weektab].items[index].boxtype : '',
      order: popups[weektab].items[index].order ? popups[weektab].items[index].order : '',
      categorytitle: popups[weektab].items[index].categorytitle
        ? popups[weektab].items[index].categorytitle
        : '',
      headline: popups[weektab].items[index].headline ? popups[weektab].items[index].headline : '',
      text: popups[weektab].items[index].text ? popups[weektab].items[index].text : '',
      img: popups[weektab].items[index].img ? popups[weektab].items[index].img : '',
      url: popups[weektab].items[index].url ? popups[weektab].items[index].url : null,
      tool: popups[weektab].items[index].tool ? popups[weektab].items[index].tool : null,
      articleId: popups[weektab].items[index].articleId
        ? popups[weektab].items[index].articleId
        : null,
      categoryId: popups[weektab].items[index].categoryId
        ? popups[weektab].items[index].categoryId
        : null,
      dealId: popups[weektab].items[index].dealId ? popups[weektab].items[index].dealId : null,
      todo: popups[weektab].items[index].todo ? popups[weektab].items[index].todo : '',
    };
    if (popups[weektab].items[index].articleId) {
      this.props.knowledge.forEach((item) => {
        item.articles.forEach((article) => {
          if (article.id === popups[weektab].items[index].articleId) {
            this.setState({ label: article.title });
          }
        });
      });
    } else {
      this.setState({ label: null });
    }
    if (popups[weektab].items[index].dealId) {
      this.props.deals.forEach((item) => {
        if (item.id === popups[weektab].items[index].dealId) {
          this.setState({ deallabel: item.title });
        }
      });
    } else {
      this.setState({ deallabel: null });
    }
    this.setState({ type: 'update', selectedItem: datatorender });
  }

  render() {
    return (
      <div className="w-full">
        <p className="ml-12 font-bold">
          This is not done, because Ildi said that scrolls should be discussed and done another time
        </p>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  state: state.state,
  popups: state.api.popups,
  scrolls: state.api.scrolls,
  knowledge: Object.keys(state.api.data).length > 0 ? state.api.data.knowledge : [],
  deals: Object.keys(state.api.data).length > 0 ? state.api.data.deals : [],
  todo: Object.keys(state.api.data).length > 0 ? state.api.data.todo : [],
  user: state.api.user,
  analyticsscrolls: state.analytics.analyticsscrolls,
});

const mapDispatchToProps = {
  getScrolls,
  createScrolls,
  updateScrolls,
  deleteScrolls,
  getAllData,
  getScrollsAnalytics,
};

export default connect(mapStateToProps, mapDispatchToProps)(Scrolls);
