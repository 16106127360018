import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getNotes, updateNotes } from '../redux/actions/settings';
import Layout from '../components/layout/Layout';
import CustomButton from '../components/CustomButton';
import HTMLEditor from '../components/HTMLEditor';
import MarkdownEditor from '../components/MarkdownEditor';

const AdminHelp = () => {
  const dispatch = useDispatch();
  const note = useSelector((state) => state.settings.note);
  const [newNoteHtml, setNewNoteHtml] = useState();
  const [newNoteMarkdown, setNewNoteMarkdown] = useState();

  useEffect(() => {
    dispatch(getNotes());
  }, [dispatch]);

  useEffect(() => {
    setNewNoteHtml(note.textHtml);
    setNewNoteMarkdown(note.text);
  }, [note]);

  const save = () => {
    dispatch(updateNotes({ text: newNoteMarkdown, textHtml: newNoteHtml }));
  };

  return (
    <Layout active="help">
      <div className="mt-8 flex flex-col mx-12">
        <HTMLEditor
          title="Note (Html)"
          dontshowImage
          defaultValue={newNoteHtml}
          setContents={newNoteHtml}
          onChange={(content) => setNewNoteHtml(content)}
        />
        <MarkdownEditor
          title="Note (Markdown)"
          value={newNoteMarkdown}
          onChange={({ text }) => {
            setNewNoteMarkdown(text);
          }}
        />
        <CustomButton className="self-center mt-5" type="submit" onClick={save}>
          Save
        </CustomButton>
      </div>
    </Layout>
  );
};

export default AdminHelp;
