/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { CSVLink } from 'react-csv';
import { useSelector, useDispatch } from 'react-redux';
import { getScreenHome } from '../../../redux/actions/analytics';
import CustomButton from '../../CustomButton';
import { formatDate } from '../helper';
import Dropdown from '../../Dropdown';
import Spinner from '../../Spinner';

const HomeScreen = ({ startDate, endDate }) => {
  const dispatch = useDispatch();
  const homeLoading = useSelector((state) => state.analytics.screenhome_loading);
  const home = useSelector((state) => state.analytics.screenhome);
  const [isFormChanged, setIsFormChanged] = useState(false);
  const [selectedType, setSelectedType] = useState('');

  useEffect(() => {
    if (startDate !== '' && endDate !== '') {
      search();
    }
  }, []);

  useEffect(() => {
    setIsFormChanged(true);
  }, [selectedType]);

  const search = async () => {
    await dispatch(
      getScreenHome(formatDate(startDate), formatDate(endDate), selectedType, selectedType !== '')
    );
    setIsFormChanged(false);
  };

  const headers = [
    { label: 'Week', key: 'week' },
    { label: 'Type', key: 'screen' },
    { label: 'Views', key: 'pressed' },
    { label: 'Percentage (views / all views)', key: 'percentage' },
    { label: 'Unique', key: 'unique' },
  ];

  return (
    <div className="w-full flex flex-col items-center">
      <div className="w-1/2 mt-8">
        <Dropdown
          value={selectedType}
          options={[
            { value: '', label: 'All' },
            { value: 'baby', label: 'Baby' },
            { value: 'mum', label: 'Mum' },
            { value: 'dad', label: 'Dad' },
          ]}
          onChange={(value) => setSelectedType(value.value)}
        />
      </div>
      <div>
        {homeLoading && <Spinner />}
        {!homeLoading && (
          <div>
            <div className="my-8 flex items-center justify-center w-full">
              <CustomButton type="button" className="text-white" onClick={search}>
                Search
              </CustomButton>

              {home.length !== 0 ? (
                <CSVLink
                  className="bg-orange text-white p-2 rounded"
                  filename={`Home-${formatDate(startDate)}-${formatDate(endDate)}.csv`}
                  data={home.screens}
                  headers={headers}
                >
                  Download this table
                </CSVLink>
              ) : null}
            </div>

            {home.length !== 0 && !isFormChanged && (
              <>
                <p className={'text-center'}>
                  Displayed period:{' '}
                  <strong>
                    {home.from} - {home.to}
                  </strong>
                </p>
                <table>
                  <thead>
                    <tr>
                      <th>Week</th>
                      <th>Type</th>
                      <th>Views</th>
                      <th>Percentage (views / all views)</th>
                      <th>Unique</th>
                    </tr>
                  </thead>
                  <tbody>
                    {home.screens
                      .filter((item) => item.week !== '40+')
                      .map((item, index) => {
                        return (
                          <tr key={index}>
                            <td>{item.week}</td>
                            <td>{item.screen}</td>
                            <td>{item.pressed}</td>
                            <td>
                              {((Number(item.pressed) / Number(home.total)) * 100).toFixed(2)}%
                            </td>
                            <td>{item.unique}</td>
                          </tr>
                        );
                      })}
                    {home.screens
                      .filter((item) => item.week === '40+')
                      .map((item, index) => {
                        return (
                          <tr key={index}>
                            <td>{item.week}</td>
                            <td>{item.screen}</td>
                            <td>{item.pressed}</td>
                            <td>
                              {((Number(item.pressed) / Number(home.total)) * 100).toFixed(2)}%
                            </td>
                            <td>{item.unique}</td>
                          </tr>
                        );
                      })}

                    <tr className="font-bold">
                      <td>Total {selectedType ? selectedType : 'SUM'}</td>
                      <td />
                      <td>{home.total}</td>
                      <td />
                      <td>{home.uniq}</td>
                    </tr>
                  </tbody>
                </table>
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default HomeScreen;
