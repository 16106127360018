import React from 'react';
import PropTypes from 'prop-types';
import SidebarMenu from '../SidebarMenu';
import MobileNav from '../MobileNav';
import DeleteModal from '../DeleteModal';

const Layout = ({ children, active }) => {
  return (
    <div className="bg-background m-0">
      <div className="flex flex-row min-h-screen">
        <div className="hidden lg:block w-full">
          <SidebarMenu active={active} />
          <div className="flex flex-col flex-1 ml-64">{children}</div>
        </div>
        <div className="block max-w-screen-sm md:max-w-screen-md lg:hidden">
          <MobileNav active={active} />
          {children}
        </div>
        <DeleteModal />
      </div>
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.node,
};

Layout.defaultProps = {
  children: null,
};

export default Layout;
