import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getPhotographers, create, update } from '../../redux/actions/tools';
import { openDeleteModal } from '../../redux/actions/state';
import { sortByOrder } from '../../utils/sort';
import ImageUp from '../ImageUp';
import MenuButton from '../MenuButton';
import NewButton from '../NewButton';
import CustomButton from '../CustomButton';
import TextField from '../TextField';
import TextArea from '../TextArea';

const Photographer = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.tools.photographers);
  const deleteType = useSelector((state) => state.state.deleteType);
  const [week, setWeek] = useState('');
  const [newCreate, setNew] = useState(true);
  const [formOpen, setFormOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});

  const initialPhotographer = useMemo(
    () => ({
      id: '',
      name: '',
      order: '',
      city: '',
      description: '',
      short_description: '',
      website: '',
      offer: '',
      profile_img: '',
      highlighted_img: '',
      photo1: '',
      photo2: '',
      photo3: '',
      photo4: '',
    }),
    []
  );

  useEffect(() => {
    if (data === [] || !data) {
      dispatch(getPhotographers());
    }
  }, [data, dispatch]);

  useEffect(() => {
    if (deleteType === 'photographers') {
      setNew(false);
      setSelectedItem(initialPhotographer);
      setFormOpen(false);
    }
  }, [data, deleteType, initialPhotographer]);

  const createNew = (e) => {
    e.preventDefault();
    const selected = selectedItem;
    if (newCreate) {
      const url = '/photographers';
      selected.id = Date.now().toString();
      dispatch(create(selected, url, 'photographer'));
      setNew(false);
      setSelectedItem(initialPhotographer);
      setFormOpen(false);
    } else {
      const url = '/photographers/' + selectedItem.id;
      dispatch(update(selected, url, 'photographer'));
      setNew(false);
      setSelectedItem(initialPhotographer);
      setFormOpen(false);
    }
  };

  const deleteItem = () => {
    const selected = selectedItem;
    const url = '/photographers/' + selectedItem.id;
    const type = 'photographers';
    const title = selected.name;
    dispatch(openDeleteModal({ selected, url, type, title }));
  };

  const deeplink = newCreate ? '' : 'preghello://tools/photographer';

  return (
    <div>
      <div
        style={{
          width: '100%',
          marginTop: 40,
          marginBottom: 20,
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <div style={{ width: '80%', display: 'flex', flexWrap: 'wrap' }}>
          <NewButton
            onClick={() => {
              setWeek(null);
              setSelectedItem(initialPhotographer);
              setNew(true);
              setFormOpen(true);
            }}
            title="Add Photographer"
          />
          {data.sort(sortByOrder()).map((value, index) => (
            <MenuButton
              key={index}
              onClick={() => {
                const datatorender = {
                  id: value.id,
                  name: value.name ? value.name : '',
                  order: value.order ? value.order : '',
                  city: value.city ? value.city : '',
                  description: value.description ? value.description : '',
                  short_description: value.short_description ? value.short_description : '',
                  website: value.website ? value.website : '',
                  offer: value.offer ? value.offer : '',
                  profile_img: value.profile_img ? value.profile_img : '',
                  highlighted_img: value.highlighted_img ? value.highlighted_img : '',
                  photo1: value.photo1 ? value.photo1 : '',
                  photo2: value.photo2 ? value.photo2 : '',
                  photo3: value.photo3 ? value.photo3 : '',
                  photo4: value.photo4 ? value.photo4 : '',
                };

                setWeek(index);
                setSelectedItem(datatorender);
                setNew(false);
                setFormOpen(true);
              }}
              active={week === index}
              title={value.name + ' - ' + value.order}
              visible="true"
            />
          ))}
        </div>
      </div>
      {formOpen && (
        <div className="mx-12">
          {newCreate && <h1 className="text-xl text-center mb-4 underline">New Photographer</h1>}
          <TextField type="text" label="Deeplink" name="deeplink" value={deeplink} disabled />
          <TextField
            type="text"
            label="Name"
            name="name"
            value={selectedItem.name}
            onChange={(event) => {
              var selectedItemNew = { ...selectedItem };
              selectedItemNew.name = event.target.value;
              setSelectedItem(selectedItemNew);
            }}
          />
          <TextField
            type="text"
            label="Order"
            name="order"
            value={selectedItem.order}
            onChange={(event) => {
              var selectedItemNew = { ...selectedItem };
              selectedItemNew.order = event.target.value;
              setSelectedItem(selectedItemNew);
            }}
          />
          <TextField
            type="text"
            label="City"
            name="city"
            value={selectedItem.city}
            onChange={(event) => {
              var selectedItemNew = { ...selectedItem };
              selectedItemNew.city = event.target.value;
              setSelectedItem(selectedItemNew);
            }}
          />
          <TextArea
            type="text"
            label="Description"
            name="description"
            value={selectedItem.description}
            onChange={(event) => {
              var selectedItemNew = { ...selectedItem };
              selectedItemNew.description = event.target.value;
              setSelectedItem(selectedItemNew);
            }}
          />
          <TextArea
            type="text"
            label="Short description"
            name="short_description"
            value={selectedItem.short_description}
            onChange={(event) => {
              var selectedItemNew = { ...selectedItem };
              selectedItemNew.short_description = event.target.value;
              setSelectedItem(selectedItemNew);
            }}
          />
          <TextField
            type="text"
            label="Website"
            name="website"
            value={selectedItem.website}
            onChange={(event) => {
              var selectedItemNew = { ...selectedItem };
              selectedItemNew.website = event.target.value;
              setSelectedItem(selectedItemNew);
            }}
          />
          <TextArea
            type="text"
            label="Offer"
            name="offer"
            value={selectedItem.offer}
            onChange={(event) => {
              var selectedItemNew = { ...selectedItem };
              selectedItemNew.offer = event.target.value;
              setSelectedItem(selectedItemNew);
            }}
          />
          <ImageUp
            title="Profilkép (ratio: 1 / 1)"
            value={selectedItem.profile_img}
            parentCallback={() => {
              var selectedItemNew = { ...selectedItem };
              selectedItemNew.profile_img = '';
              setSelectedItem(selectedItemNew);
            }}
            getImg={(value) => {
              var selectedItemNew = { ...selectedItem };
              selectedItemNew.profile_img = value;
              setSelectedItem(selectedItemNew);
            }}
          />
          <ImageUp
            title="Kiemelt kép (ratio: 1 / 1"
            value={selectedItem.highlighted_img}
            parentCallback={() => {
              var selectedItemNew = { ...selectedItem };
              selectedItemNew.highlighted_img = '';
              setSelectedItem(selectedItemNew);
            }}
            getImg={(value) => {
              var selectedItemNew = { ...selectedItem };
              selectedItemNew.highlighted_img = value;
              setSelectedItem(selectedItemNew);
            }}
          />
          <ImageUp
            title="Első kép (ratio: 1 / 1"
            value={selectedItem.photo1}
            parentCallback={() => {
              var selectedItemNew = { ...selectedItem };
              selectedItemNew.photo1 = '';
              setSelectedItem(selectedItemNew);
            }}
            getImg={(value) => {
              var selectedItemNew = { ...selectedItem };
              selectedItemNew.photo1 = value;
              setSelectedItem(selectedItemNew);
            }}
          />
          <ImageUp
            title="Második kép (ratio: 1 / 1"
            value={selectedItem.photo2}
            parentCallback={() => {
              var selectedItemNew = { ...selectedItem };
              selectedItemNew.photo2 = '';
              setSelectedItem(selectedItemNew);
            }}
            getImg={(value) => {
              var selectedItemNew = { ...selectedItem };
              selectedItemNew.photo2 = value;
              setSelectedItem(selectedItemNew);
            }}
          />
          <ImageUp
            title="Harmadik kép (ratio: 1 / 1"
            value={selectedItem.photo3}
            parentCallback={() => {
              var selectedItemNew = { ...selectedItem };
              selectedItemNew.photo3 = '';
              setSelectedItem(selectedItemNew);
            }}
            getImg={(value) => {
              var selectedItemNew = { ...selectedItem };
              selectedItemNew.photo3 = value;
              setSelectedItem(selectedItemNew);
            }}
          />
          <ImageUp
            title="Negyedik kép (ratio: 1 / 1"
            value={selectedItem.photo4}
            parentCallback={() => {
              var selectedItemNew = { ...selectedItem };
              selectedItemNew.photo4 = '';
              setSelectedItem(selectedItemNew);
            }}
            getImg={(value) => {
              var selectedItemNew = { ...selectedItem };
              selectedItemNew.photo4 = value;
              setSelectedItem(selectedItemNew);
            }}
          />
          <div className="flex flex-row justify-center">
            <CustomButton type="submit" className="w-1/5" onClick={(e) => createNew(e)}>
              Save
            </CustomButton>
            {!newCreate && (
              <CustomButton red type="submit" className="w-1/5" onClick={() => deleteItem()}>
                Delete
              </CustomButton>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Photographer;
