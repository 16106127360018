/* eslint-disable no-redeclare */
export const formatDate = (date) => {
  var d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) {
    month = '0' + month;
  }
  if (day.length < 2) {
    day = '0' + day;
  }

  return [year, month, day].join('.');
};

export async function newPercentage(filteredarray) {
  const newArray = filteredarray;
  const sum = newArray.map((item) => item.pressed);
  var total = 0;
  for (var i in sum) {
    total += Number(sum[i]);
  }
  const userids = newArray.map((item) => item.userid);
  var uniq = [];
  for (var i in userids) {
    uniq.push(...userids[i]);
  }
  await Promise.all(
    newArray.map(async (element, index) => {
      await Object.assign(newArray[index], {
        percentage: ((Number(element.pressed) / Number(total)) * 100).toFixed(2),
      });
    })
  );
  let uniqueItems = [...new Set(uniq)];
  return { screens: newArray, total: total, uniq: uniqueItems.length };
}

export async function addScreens(screens) {
  const screensFlat = screens.map((item) => item.screens).flat(2);
  const screenAgregated = [];
  await Promise.all(
    screensFlat.map(async (item) => {
      let isScreen = screenAgregated.find((x) => x.screen === item.screen);
      if (isScreen) {
        await Promise.all(
          screenAgregated.map(async (element, index) => {
            if (element === isScreen) {
              await Object.assign(screenAgregated[index], {
                pressed: Number(screenAgregated[index].pressed) + Number(item.pressed),
              });
              const newuserid = item.userid.filter((e) => !element.userid.includes(e));
              await screenAgregated[index].userid.push(...newuserid);
            }
          })
        );
      } else {
        await screenAgregated.push(item);
      }
    })
  );
  const sum = screenAgregated.map((item) => item.pressed);
  var total = 0;
  for (var i in sum) {
    total += Number(sum[i]);
  }
  const userids = screenAgregated.map((item) => item.userid);
  var uniq = [];
  for (var i in userids) {
    uniq.push(...userids[i]);
  }
  await Promise.all(
    screenAgregated.map(async (element, index) => {
      await Object.assign(screenAgregated[index], {
        percentage: ((Number(element.pressed) / Number(total)) * 100).toFixed(2),
      });
    })
  );
  let uniqueItems = [...new Set(uniq)];
  return { screens: screenAgregated, total: total, uniq: uniqueItems.length };
}

export async function addScreensId(screens) {
  const screensFlat = screens.map((item) => item.screens).flat(2);
  const screenAgregated = [];
  await Promise.all(
    screensFlat.map(async (item) => {
      let isScreen = screenAgregated.find((x) => x.screenId === item.screenId);
      if (isScreen) {
        await Promise.all(
          screenAgregated.map(async (element, index) => {
            if (element === isScreen) {
              await Object.assign(screenAgregated[index], {
                pressed: Number(screenAgregated[index].pressed) + Number(item.pressed),
              });
              const newuserid = item.userid.filter((e) => !element.userid.includes(e));
              await screenAgregated[index].userid.push(...newuserid);
            }
          })
        );
      } else {
        await screenAgregated.push(item);
      }
    })
  );
  const sum = screenAgregated.map((item) => item.pressed);
  var total = 0;
  for (var i in sum) {
    total += Number(sum[i]);
  }
  const userids = screenAgregated.map((item) => item.userid);
  var uniq = [];
  for (var i in userids) {
    uniq.push(...userids[i]);
  }
  await Promise.all(
    screenAgregated.map(async (element, index) => {
      await Object.assign(screenAgregated[index], {
        percentage: ((Number(element.pressed) / Number(total)) * 100).toFixed(2),
      });
    })
  );
  let uniqueItems = [...new Set(uniq)];

  return { screens: screenAgregated, total: total, uniq: uniqueItems.length };
}

export async function addBaby(screens) {
  const screensFlat = screens.map((item) => item.screens).flat(2);
  const screenAgregated = [];
  await Promise.all(
    screensFlat.map(async (item) => {
      let isScreen = screenAgregated.find((x) => x.week === item.week);
      if (isScreen) {
        let isType = screenAgregated.find((x) => x.type === item.type && x.week === item.week);
        if (isType) {
          await Promise.all(
            screenAgregated.map(async (element, index) => {
              if (element === isType) {
                await Object.assign(screenAgregated[index], {
                  pressed: Number(screenAgregated[index].pressed) + Number(item.pressed),
                });
                const newuserid = item.userid.filter((e) => !element.userid.includes(e));
                await screenAgregated[index].userid.push(...newuserid);
              }
            })
          );
        } else {
          await screenAgregated.push(item);
        }
      } else {
        await screenAgregated.push(item);
      }
    })
  );
  const sum = screenAgregated.map((item) => item.pressed);
  var total = 0;
  for (var i in sum) {
    total += Number(sum[i]);
  }
  const userids = screenAgregated.map((item) => item.userid);
  var uniq = [];
  for (var i in userids) {
    uniq.push(...userids[i]);
  }
  const sumbaby = screenAgregated
    .filter((item) => item.type === 'baby')
    .map((item) => item.pressed);
  var babytotal = 0;
  for (var j in sumbaby) {
    babytotal += Number(sumbaby[j]);
  }
  const useridsBaby = screenAgregated
    .filter((item) => item.type === 'baby')
    .map((item) => item.userid);
  var uniqBaby = [];
  for (var i in useridsBaby) {
    uniqBaby.push(...useridsBaby[i]);
  }
  const summum = screenAgregated.filter((item) => item.type === 'mum').map((item) => item.pressed);
  var mumtotal = 0;
  for (var k in summum) {
    mumtotal += Number(summum[k]);
  }
  const useridsMum = screenAgregated
    .filter((item) => item.type === 'mum')
    .map((item) => item.userid);
  var uniqMum = [];
  for (var i in useridsMum) {
    uniqMum.push(...useridsMum[i]);
  }

  let uniqueItems = [...new Set(uniq)];
  let uniqueItemsBaby = [...new Set(uniqBaby)];
  let uniqueItemsMum = [...new Set(uniqMum)];

  return {
    screens: screenAgregated,
    total: total,
    uniq: uniqueItems.length,
    babytotal: babytotal,
    mumtotal: mumtotal,
    babyuniq: uniqueItemsBaby.length,
    mumuniq: uniqueItemsMum.length,
  };
}

export async function addClickId(screens) {
  const screensFlat = screens.map((item) => item.clicks).flat(2);
  const screenAgregated = [];
  await Promise.all(
    screensFlat.map(async (item) => {
      let isScreen = screenAgregated.find((x) => x.screenId === item.screenId);
      if (isScreen) {
        await Promise.all(
          screenAgregated.map(async (element, index) => {
            if (element === isScreen) {
              await Object.assign(screenAgregated[index], {
                pressed: Number(screenAgregated[index].pressed) + Number(item.pressed),
              });
              const newuserid = item.userid.filter((e) => !element.userid.includes(e));
              await screenAgregated[index].userid.push(...newuserid);
            }
          })
        );
      } else {
        await screenAgregated.push(item);
      }
    })
  );
  const sum = screenAgregated.map((item) => item.pressed);
  var total = 0;
  for (var i in sum) {
    total += Number(sum[i]);
  }
  const userids = screenAgregated.map((item) => item.userid);
  var uniq = [];
  for (var i in userids) {
    uniq.push(...userids[i]);
  }
  await Promise.all(
    screenAgregated.map(async (element, index) => {
      await Object.assign(screenAgregated[index], {
        percentage: ((Number(element.pressed) / Number(total)) * 100).toFixed(2),
      });
    })
  );
  let uniqueItems = [...new Set(uniq)];

  return { screens: screenAgregated, total: total, uniq: uniqueItems.length };
}

export async function addHomeClickId(screens) {
  const screensFlat = screens.map((item) => item.clicks).flat(2);
  const screenAgregated = [];
  await Promise.all(
    screensFlat.map(async (item) => {
      let isScreen = screenAgregated.find((x) => x.week === item.week);
      if (isScreen) {
        let isType = screenAgregated.find((x) => x.type === item.type && x.week === item.week);
        if (isType) {
          await Promise.all(
            screenAgregated.map(async (element, index) => {
              if (element === isScreen) {
                await Object.assign(screenAgregated[index], {
                  pressed: Number(screenAgregated[index].pressed) + Number(item.pressed),
                });
                const newuserid = item.userid.filter((e) => !element.userid.includes(e));
                await screenAgregated[index].userid.push(...newuserid);
              }
            })
          );
        } else {
          await screenAgregated.push(item);
        }
      } else {
        await screenAgregated.push(item);
      }
    })
  );
  const sum = screenAgregated.map((item) => item.pressed);
  var total = 0;
  for (var i in sum) {
    total += Number(sum[i]);
  }
  const userids = screenAgregated.map((item) => item.userid);
  var uniq = [];
  for (var i in userids) {
    uniq.push(...userids[i]);
  }
  const sumbaby = screenAgregated
    .filter((item) => item.type === 'baby')
    .map((item) => item.pressed);
  var babytotal = 0;
  for (var j in sumbaby) {
    babytotal += Number(sumbaby[j]);
  }
  const useridsBaby = screenAgregated
    .filter((item) => item.type === 'baby')
    .map((item) => item.userid);
  var uniqBaby = [];
  for (var i in useridsBaby) {
    uniqBaby.push(...useridsBaby[i]);
  }
  const summum = screenAgregated.filter((item) => item.type === 'mum').map((item) => item.pressed);
  var mumtotal = 0;
  for (var k in summum) {
    mumtotal += Number(summum[k]);
  }
  const useridsMum = screenAgregated
    .filter((item) => item.type === 'mum')
    .map((item) => item.userid);
  var uniqMum = [];
  for (var i in useridsMum) {
    uniqMum.push(...useridsMum[i]);
  }
  await Promise.all(
    screenAgregated.map(async (element, index) => {
      await Object.assign(screenAgregated[index], {
        percentage: ((Number(element.pressed) / Number(total)) * 100).toFixed(2),
      });
    })
  );
  let uniqueItems = [...new Set(uniq)];
  let uniqueItemsBaby = [...new Set(uniqBaby)];
  let uniqueItemsMum = [...new Set(uniqMum)];

  return {
    screens: screenAgregated,
    total: total,
    uniq: uniqueItems.length,
    babytotal: babytotal,
    mumtotal: mumtotal,
    babyuniq: uniqueItemsBaby.length,
    mumuniq: uniqueItemsMum.length,
  };
}
