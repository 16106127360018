import React, { useEffect } from 'react';
import { CSVLink } from 'react-csv';
import { useSelector, useDispatch } from 'react-redux';
import { getScreenDeals } from '../../../redux/actions/analytics';
import CustomButton from '../../CustomButton';
import { formatDate } from '../helper';
import Spinner from '../../Spinner';

const DealsScreen = ({ startDate, endDate }) => {
  const dispatch = useDispatch();
  const dealsLoading = useSelector((state) => state.analytics.screendeals_loading);
  const deals = useSelector((state) => state.analytics.screendeals);

  useEffect(() => {
    if (startDate !== '' && endDate !== '') {
      dispatch(getScreenDeals(formatDate(startDate), formatDate(endDate)));
    }
  }, [dispatch, startDate, endDate]);

  const search = () => {
    dispatch(getScreenDeals(formatDate(startDate), formatDate(endDate)));
  };

  const headers = [
    { label: 'Screen', key: 'screen' },
    { label: 'Views', key: 'appeared' },
    { label: 'Percentage (views / all views)', key: 'percentage' },
    { label: 'Unique', key: 'unique' },
  ];

  return (
    <div className="w-full flex flex-col items-center">
      <div>
        {dealsLoading && <Spinner />}
        {!dealsLoading && (
          <div>
            <div className="my-8 flex items-center justify-center w-full">
              <CustomButton type="button" className="text-white" onClick={search}>
                Search
              </CustomButton>

              {deals.length !== 0 ? (
                <CSVLink
                  className="bg-orange text-white p-2 rounded"
                  filename={`Deals-${formatDate(startDate)}-${formatDate(endDate)}.csv`}
                  data={deals.screens}
                  headers={headers}
                >
                  Download this table
                </CSVLink>
              ) : null}
            </div>

            {deals.length !== 0 && (
              <table>
                <thead>
                  <tr>
                    <th>Screen</th>
                    <th>Views</th>
                    <th>Percentage (view / all views)</th>
                    <th>Unique</th>
                  </tr>
                </thead>
                <tbody>
                  {deals.screens.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td>{item.screen}</td>
                        <td>{item.appeared}</td>
                        <td>{((item.appeared / deals.total) * 100).toFixed(2)}%</td>
                        <td>{item.unique}</td>
                      </tr>
                    );
                  })}
                  <tr className="font-bold">
                    <td>SUM</td>
                    <td>{deals.total}</td>
                    <td />
                    <td>{deals.uniq}</td>
                  </tr>
                </tbody>
              </table>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default DealsScreen;
